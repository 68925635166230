@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.planCard {
  position: relative;
  background-color: #f1f7ff;
  min-width: 450px;
  margin: 16px;
  padding: 24px;
  border: 1px solid $gray-200;
  border-radius: 10px;

  &.selected {
    background-color: #ffffff;
    border-color: $primary;
  }

  &.premium {
    background: #f1f7ff;
    border: 1px solid $gray-200;
  }

  @include layout-flip {
    width: 100%;
    min-width: 0;
    margin: 0 0 16px;
  }

  .top {
    margin: 0 0 32px;

    @include layout-flip {
      margin: 0 0 32px;
    }

    .description {
      display: flex;
      align-items: center;

      h3 {
        margin: 0;
        font-family: $body-font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: $secondary;

        @include layout-flip {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .stars {
        display: flex;
        align-items: center;
        margin: 0 0 0 10px;

        @include layout-flip {
          margin: 0 auto 0 0;
          transform: translateY(-5px);
        }

        .big {
          width: 34px;
          height: 35.5px;
          margin: 0 16px 0 0;

          @include layout-flip {
            width: 30px;
            height: 35px;
            margin: 0 4px 0 0;
          }
        }

        .small {
          width: 14px;
          height: 14.6px;

          @include layout-flip {
            width: 12px;
            height: 15px;
            transform: translateY(2px);
          }
        }
      }

      .price {
        width: fit-content;
        margin: 0 0 0 auto;

        span:last-child {
          font-size: 19px;
          letter-spacing: 0.4px;
          color: $primary;

          @include layout-flip {
            font-size: 15px;
          }
        }

        span:first-child {
          font-family: $headings-font-family;
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 38px;
          color: $secondary;

          @include layout-flip {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }

    small {
      display: block;
      margin-bottom: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $gray-700;

      @include layout-flip {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.01em;
      }
    }
  }

  footer {
    position: relative;
    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      padding: 11px 16px;
      margin-right: 16px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: $secondary;
      border: 1px solid $primary !important;
      border-radius: 5px;

      @include layout-flip {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .learnMore {
      margin-left: auto;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      border-bottom: 1px solid $accent-color;
      color: $accent-color;
      cursor: pointer;
      transition: all 0.3s ease-in-out 0s;

      &:hover,
      &:focus {
        border-color: transparent;
      }

      @include layout-flip {
        margin: 0 60px 0 auto;
      }
    }

    .purplePlane {
      position: absolute;
      right: 0;
      transform: translateY(-20px);
      width: 66px;
      height: 68.85px;

      @include layout-flip {
        position: absolute;
        transform: translateY(-26px);
        width: 49px;
      }
    }
  }

  .verified {
    position: absolute;
    top: -14px;
    right: 11px;
    width: 33px;
    height: 33px;

    @include layout-flip {
      top: -3px;
      right: -12px;
    }
  }
}
