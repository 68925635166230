@import "../../styles/variables";

.text {
  display: block;
  white-space: normal;
  word-wrap: normal;

  &.breakWord {
    word-wrap: break-word;
  }

  .expandButton {
    color: $accent-color;
    cursor: pointer;
  }
}
