@import "../../../../../../common/styles/mixins";

.editPersonalStatementForm {
  max-width: 806px;
}

.editPersonalStatementButton {
  width: 1000%;
  max-width: 384px;

  @media (max-width: 600px) {
    max-width: 100%;
    padding: 18px 30px;
  }
}

.editPersonalStatementTextarea {
  @media (max-width: 600px) {
    & > textarea {
      height: 160px;
    }
  }
}
