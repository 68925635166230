.modal {
	&-reschedule-appointment-time {
		&__header {
			position: relative;
			background-color: $blue-light-2;
			padding: 12px 50px 12px 16px;
			border-radius: 16px 16px 0 0;

			@include media-breakpoint-up(sm) {
				padding: 20px 50px 20px 28px;
			}
		}

		&__close-btn {
			@include sizeElement(20px);
			@include inlineFlexCenter;

			position: absolute;
			top: 8px;
			right: 8px;
			z-index: 5;
			padding: 4px;
			font-size: 16px;
			border: none;
			background-color: transparent;
			border-radius: 0;
			color: $gray-300;
			cursor: pointer;
			transition: color 0.3s;

			@include media-breakpoint-up(sm) {
				top: 50%;
				right: 20px;
				transform: translate(0, -50%);
			}

			&:hover {
				color: $secondary;
			}
		}

		&__body {
			padding: 20px 16px 24px;

			@include media-breakpoint-up(sm) {
				padding: 20px 28px 28px;
			}
		}
	}
}