.stepsWrapper {
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  background: linear-gradient(181.35deg, #F2F6FB 59.41%, rgba(244, 248, 252, 0) 104.75%);
  width: 100%;
  height: 50px;
  z-index: 2;
}

.stepsList {
  max-height: calc(100vh - 349px);
  transition: transform 250ms ease-in-out;

  &::-webkit-scrollbar {
    width: 0;
  }
}
