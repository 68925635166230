@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

.popup {
  padding: 50px;

  @include layout-flip {
    padding: 40px 15px;
  }
}

.popupCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  background: none;
  border: none;
  cursor: pointer;

  @include layout-flip {
    top: 15px;
    right: 15px;
    margin: 0;
    padding: 0;
  }
}

.popupTitle {
  margin: 0 0 32px;
  padding: 0;
  text-align: center;
  font-family: $headings-font-family;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.3;
  color: $secondary;

  @include media-breakpoint-down(sm) {
    margin: 0 0 20px;
  }
}

.popupSubtitle {
  display: block;
  margin: 0 0 16px;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: $secondary;
}

.popupInput {
  width: 100%;
  margin: 0 0 32px;

  @include media-breakpoint-down(sm) {
    margin: 0 0 17px;
  }
}

.popupButton {
  max-width: 100%;
  width: 100%;
  margin: 0 0 0;

  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

.error {
  margin: 0 0 20px;
  padding: 0;
  color: $red;
  font-size: 14px;
}
