@import "../../styles/mixins";

.singleCard {
  --card-height: 96px;
  --card-width: 180px;
  @include restrict-prop(height, var(--card-height));
  min-width: var(--card-width);
}

.singleCardAge {
  position: relative;
  height: 182px;
  width: 165px;
  margin: 0 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include layout-flip {
    width: 140px;
  }
}

.wrapper p {
  margin-top: 16px;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
  row-gap: 16px;
  flex-wrap: wrap;
  align-content: center;
  justify-items: center;

  &_compact {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0px;

    .singleCard {
      --card-height: 180px;
      --card-width: 150px;
    }
  }

  @include layout-flip {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0;

    .singleCard {
      --card-height: 155px;
      --card-width: 155px;
    }
  }
}

.cardsContainerAge {
  max-width: 580px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.groupLabel {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1f342f;
  margin-bottom: 24px;
  display: block;
}

.singleCard {
}

.wrapper p {
  margin-top: 16px;
}

.inner {
  margin: 0 -8px;
}
