.dropFileArea {
  width: 100%;
  border-radius: 10px;
  padding: 24px;
  text-align: center;
  border: 1.5px dashed #26d5b6;
  pointer-events: none;
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    background: #fafafa;
  }

  & > * {
    pointer-events: all;
  }
}

.acceptHint {
  font-size: 12px;
  line-height: 14px;
  margin: 16px 0 0;
  color: #26d5b6;
}

.hiddenFileInput {
  display: none;
}

.fileUploadImage {
  margin-bottom: 16px;
}

.captionText {
  font-size: 15px;
  line-height: 18px;
  color: #68768b;
  margin: 0 0 8px;
}

.actionsContainer {
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;
  margin-bottom: 16px;
}

.removeBtn {
  margin-top: 16px;
}
