@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.changePassword {
  max-width: 400px;
  width: 100%;

  @include layout-flip {
    max-width: none;
  }
}