@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.cardAnimal {
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: 15px;
  margin-bottom: 30px;

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray-200;
    padding: 20px 15px;
  }

  &__name {
    flex: 0 0 auto;
    width: 50%;
    max-width: 100%;
    padding-right: 10px;
    margin-bottom: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__type {
    flex: 0 0 auto;
    width: 50%;
    max-width: 100%;
    padding-left: 10px;
    text-align: right;
    margin-bottom: 0 !important;
  }

  &__photoBlock {
    padding: 20px 15px 0;
  }

  &__photo {
    @include responsiveRatio(16, 9);

    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }

  &__body {
    position: relative;
    padding: 20px 50px 20px 15px;
  }

  &__btnEdit {
    @include sizeElement(44px);

    position: absolute;
    top: 15px;
    right: 15px;
    background-color: $primary;
    box-shadow: 0 10px 15px rgba($black, 0.15);
    border-radius: 50%;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($primary, 15%);
    }
  }
}

.animalAvatar {
	@include responsiveRatio(1, 1);

	position: relative;
	max-width: 120px;

	@include media-breakpoint-up(sm) {
		max-width: 152px;
	}

	&__img {
		@include absoluteCover;

		object-fit: cover;
		border-radius: 15px;
	}

	&__editIcon {
		@include flexCenter;
		@include sizeElement(36px);

		position: absolute;
		right: 0;
		bottom: 0;
		background-color: $white;
		padding: 0;
		border: none !important;
		border-radius: 100%;
		transform: translate(35%, 35%);
		box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);

		@include media-breakpoint-up(lg) {
			@include sizeElement(48px);
		}

		svg {
			margin: 0;
		}
	}

	&__removeAvatar {
		@include clean-btn;

		position: absolute;
		top: calc(100% + 3px);
		left: 0;
		width: auto;
		font-family: $headings-font-family;
		font-weight: 600;
		font-size: 12px;
		line-height: 1;
		color: $accent-color;
		border-bottom: 1px solid $accent-color;
		transition: border-color 0.3s;

		@include media-breakpoint-up(sm) {
			font-size: 14px;
		}

		&:hover,
		&:focus {
			border-color: transparent;
		}
	}

	&__hiddenInput {
		@include visuallyHidden;
	}
}