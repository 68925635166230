.root {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  background-color: #ffffff;
  background-image: url("../../../assets/images/backgrounds/landing-background.png");
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;

  .info {
    text-align: center;
  }

  .title {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 28px;
    line-height: 1.1;
    letter-spacing: -0.01em;
    color: #000000;
  }
}
