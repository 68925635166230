@import '../../../styles/variables';

.logo {
    display: inline-block;
    cursor: pointer;
    outline: none;
}

@media screen and (max-width: 450px) {
    .logo {
        width: 160px;
    }
  }
  