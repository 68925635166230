@import "../../../../common/styles/mixins";

.modal {
  padding: 50px 60px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include layout-flip {
    padding: 40px 15px;
  }

  .closeButton {
    @include clean-btn;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  h1 {
    margin: 0 0 30px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 58px;
    color: $secondary;

    @include layout-flip {
      margin: 0 0 20px;
      font-size: 30px;
      line-height: 38px;
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    width: 100%;

    @include layout-flip {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }
  }

  .error {
    margin: 16px 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: red;
    text-align: center;
  }
}

.warning {
  max-width: 569px;
  display: flex;
  margin: 0 0 32px;
  padding: 32px;
  border: 1px solid #3ceac1;
  border-radius: 10px;

  @include layout-flip {
    flex-direction: column;
    padding: 16px;
  }
}

.warningIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  margin: 0 32px 0 0;
  padding: 24px;
  background: #defaf6;
  border-radius: 50%;

  @include layout-flip {
    width: 48px;
    height: 48px;
    margin: 0 0 16px;
    padding: 0;
  }

  svg {
    width: 24px;
    height: 24px;

    @include layout-flip {
      width: 16px;
      height: 16px;
    }

    path {
      fill: #000000;
    }
  }
}

.warningInfo {
  margin: 0;
  font-size: 16px;
  line-height: 1.1;
  color: #68768b;

  @include layout-flip {
    font-size: 15px;
  }
}
