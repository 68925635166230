@import "../../../../../../common/styles/mixins";

.credentialsTitle {
  margin: 0 0 48px;
  padding: 0;
  font-family: $headings-font-family;
  font-size: 34px;
  line-height: 1.3;
  color: $secondary;

  @include layout-flip {
    display: none;
  }
}

.credentialsInner {
  padding: 40px;
  background: #ffffff;
  border: 1px solid $gray-200;
  border-radius: 10px;

  @media (min-width: 1366px) {
    display: flex;
  }

  @include layout-flip {
    padding: 3px 0 0;
    border: none;
  }

  @media (min-width: 992px) {
    & + .credentialsInner {
      margin: 30px 0 0;
    }
  }

  &.withOne {
    .credentialsInfo {
      .credentialsBlock {
        &:nth-last-child(-n + 2) {
          flex: 0 0 50%;
        }
      }
    }
  }
}

.credentialsInfo {
  display: flex;
  flex-wrap: wrap;
  max-width: 950px;
  width: 100%;

  @include layout-flip {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 24px 0 0;
    border-bottom: 1px solid #d4e1f2;

    &.lastInfo {
      border: 0;
    }
  }
}

.credentialsBlock {
  flex: 0 0 50%;
  margin: 0 0 40px;
  padding: 0 20px 0 0;

  &:nth-last-child(-n + 2) {
    flex: 0 0 100%;
  }

  &:last-child {
    .credentialsBlockText {
      max-width: 453px;
    }
  }

  @include layout-flip {
    margin: 0 0 24px;
    padding: 0;

    .credentialsBlockTex {
      max-width: 100%;
    }
  }
}

.credentialsBlockTitle {
  margin: 0 0 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: $secondary;

  @include layout-flip {
    margin: 0 0 10px;
  }
}

.credentialsBlockText {
  max-width: 346px;
  margin: 0;
  padding: 0;
  font-size: 17px;
  line-height: 1.2;
  color: $gray-700;

  @include layout-flip {
    max-width: 100%;
  }
}

.credentialsButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 72px;
  max-width: 292px;
  width: 100%;
  margin: auto 10% auto auto;

  svg {
    margin: -2px 10px 0 0;
  }

  @media (max-width: 1366px) {
    margin: 0;
  }

  @include layout-flip {
    display: none;
  }
}

.credentialsMobileButton,
.credentialsMobileBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 122px;
  height: 44px;
  margin: 0 0 24px;
  padding: 0;

  svg {
    flex: 0 0 20px;
    margin: -1px 4px 0 0;
  }

  @media (min-width: 992px) {
    display: none;
  }
}

.document {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  margin: 0 0 16px;
  background: #defaf6;
  border: 1px solid #3ceac1;
  border-radius: 50px;

  @media (min-width: 992px) {
    display: none;
  }
}

.credentialTypeInner {
  .credentialsBlock {
    flex: 0 0 50%;
    margin: 0 0 40px;
    padding: 0 20px 0 0;
  }

  &:not(:last-child) {
    @include layout-flip {
      border-bottom: 1px solid #d4e1f2;
    }
  }
}

.noCredentials {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  color: $gray-700;
}

.credentialsImages {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 20px;

  img {
    display: block;
    max-width: 160px;
    width: 100%;
    min-height: 142px;
    margin: 0 20px 20px 0;
    object-fit: cover;

    &:last-of-type {
      margin: 0 0 20px 0;
    }
  }
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 28px 0;

  @include layout-flip {
    margin: 24px 0;
  }

  &.firstHeader {
    @include layout-flip {
      margin: 0 0 24px;
    }
  }

  h1 {
    margin: 0;
    font-weight: 400;
    font-size: 34px;
    line-height: 1.3;
    color: #1f342f;

    @include layout-flip {
      max-width: 186px;
      font-size: 23px;
      line-height: 1.2;
    }
  }
}

.credentialsMobileBtn {
  margin: 0;
  width: 120px;
}

.addButton {
  @include layout-flip {
    div + div {
      max-width: 100px;
    }
  }
}
