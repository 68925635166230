@import "../../styles/variables";
@import "../../styles/mixins";

.checkbox {
  display: inline-block;
  font-size: 16px;
  line-height: 1.5625;

  &.sm {
    font-size: 14px;
    line-height: 1.42;

    .checkbox__block {
      padding-left: 28px;
    }

    .checkbox__checkmark {
      @include sizeElement(20px);

      &:after {
        font-size: 8px;
      }
    }
  }

  &.error {
    .checkbox__block {
      .checkbox__checkmark {
        border-color: $error;
      }
    }
  }

  &.bordered-block {
    display: flex;
    width: 100%;

    .checkbox__input {
      &:checked {
        + .checkbox__block {
          border-color: $primary;
          background-color: $mint;
        }
      }

      &:checked {
        &:disabled {
          + .checkbox__block {
            background-color: rgba($gray-400, 0.1);
          }
        }
      }

      &:disabled {
        + .checkbox__block {
          border-color: $gray-400;
        }
      }
    }

    .checkbox__block {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border: 1px solid $gray-200;
      border-radius: 6px;
      padding: 12px;
      background-color: $white;
      transition: background-color 0.3s, border-color 0.3s;
      gap: 10px;
    }

    .checkbox__checkmark {
      position: relative;
      margin-bottom: 6px;
    }

    .checkbox__text {
      display: block;
      width: 100%;
    }
  }

  &.time-format-checkbox {
    &:hover {
      .checkbox__text {
        border-color: $gray-500;
      }
    }

    .checkbox__input {
      &:checked {
        + .checkbox__block {
          .checkbox__text {
            &:after {
              transform: translate(calc(100% - 2px), 0);
            }

            > span {
              &:first-child {
                color: $gray-700;
              }

              &:last-child {
                color: $secondary;
              }
            }
          }
        }
      }
    }

    .checkbox__block {
      padding-left: 0;
    }

    .checkbox__checkmark {
      display: none;
    }

    .checkbox__text {
      position: relative;
      z-index: 5;
      display: flex;
      width: 86px;
      height: 36px;
      border: 1px solid $gray-200;
      border-radius: 4px;
      transition: border-color 0.3s;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: calc(50% + 2px);
        height: calc(100% + 2px);
        left: -1px;
        top: -1px;
        bottom: -1px;
        z-index: 2;
        border-radius: 4px;
        background-color: $primary;
        transition: transform 0.3s;
      }

      > span {
        @include flexCenter;

        position: relative;
        z-index: 5;
        flex: 0 0 auto;
        width: 50%;
        font-size: 14px;
        font-weight: 600;
        color: $gray-700;
        transition: color 0.3s;

        &:first-child {
          color: $secondary;
        }
      }
    }
  }

  &__input {
    @include visuallyHidden;

    &:checked {
      + .checkbox__block {
        .checkbox__checkmark {
          background-color: $primary;
          border-color: $primary;

          &:after {
            opacity: 1;
          }
        }
      }
    }

    &:checked {
      &:disabled {
        + .checkbox__block {
          .checkbox__checkmark {
            background-color: $gray-400;
          }
        }
      }
    }

    &:disabled {
      + .checkbox__block {
        cursor: not-allowed;

        .checkbox__checkmark {
          border-color: $gray-400;
        }

        .checkbox__text {
          color: $gray-400;
        }
      }
    }
  }

  &__block {
    position: relative;
    display: inline-block;
    padding-left: 32px;
    cursor: pointer;
  }

  &__checkmark {
    @include sizeElement(25px);

    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    border-radius: 4px;
    border: 1.5px solid $gray-200;
    background-color: $white;
    transition: background-color 0.3s, border-color 0.3s;

    &:after {
      @include absoluteCenter;

      content: "\e900";
      font-family: icomoon;
      display: inline-block;
      font-size: 9px;
      color: $white;
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  &__top-text {
    width: 100%;
    flex-grow: 1;
    text-align: center;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 1.6;
  }

  &__text {
    color: $gray-700;
    transition: color 0.3s;

    a {
      font-size: inherit;
      line-height: inherit;
    }
  }
}
