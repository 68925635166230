@import "../../../../styles/variables";

.loggedOut {
  max-width: 611px;

  .textContainer {
    > span {
      display: block;
    }
  }
}
