@import "../../styles/variables";
@import "../../styles/mixins";

.recordVideoModal {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  @include layout-flip {
    align-items: normal;
    height: 100vh;
  }

  img {
    position: absolute;

    &.frameLeftImage {
      left: 0;
      bottom: 0;
    }

    &.frameImage {
      top: 107px;
      left: 0;
    }

    &.frameRightBlueImage {
      top: 0;
      right: 0;
    }

    &.frameBottomImage {
      bottom: 0;
      left: 50%;
    }
  }


  .content {
    max-width: 1194px;
    width: 100%;
    padding: 50px;
    @include layout-flip {
      padding: 16px;
    }

    h1 {
      position: relative;
      margin: 0 0 38px;
      font-style: normal;
      font-weight: 400;
      font-size: 46px;
      line-height: 58px;
      color: $secondary;
      @include layout-flip {
        font-size: 23px;
        line-height: 1.1;
        margin: 0 0 12px;
      }
    }

    .moveOnBtn {
      @include round-btn(60px);
      background: #3CEAC1;

      &:disabled {
        background: #26d5b6;
      }
    }


    .container {
      position: relative;
      width: 100%;
      height: 100%;
      @include layout-flip {
        height: calc(100% - 37px);
      }

      .videoPlayer {
        width: 100%;
      }

      .cancelButton {
        $size: 70px;
        $image-size: 26px;
        @include round-btn(70px);
        background: white;
        position: absolute;
        top: 32px;
        left: 32px;
        z-index: 5;

        img {
          @include restrict-prop(height, $image-size);
          @include restrict-prop(width, $image-size);
        }

        @include layout-flip {
          @include round-btn(50px);
          background: white;
          $image-size: 22px;
          top: 46px;
          left: 16px;
        }
      }
    }
  }
}