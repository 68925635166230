@import "../../../../../../common/styles/mixins";
@import "../../../../../../common/styles/variables";

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 30px;
  background: #ffffff;
  box-shadow: 0 10px 20px rgba(150, 167, 226, 0.1);
  border-radius: 15px;
  transition: all 0.3s ease-in-out 0s;
  border: 2px solid $primary;

  &:hover,
  &:focus {
    box-shadow: 0px 0px 20px rgba(212, 225, 242, 0.3),
      0px 10px 20px rgba(150, 167, 226, 0.15);
  }
}

.wrapper {
  padding: 32px;
}

.footer {
  padding: 16px 44px 20px;
  text-align: right;
  border-top: 1px solid #f1f7ff;

  @include layout-flip {
    text-align: center;
    padding: 16px 44px 18px;
  }
}

.link {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.1;
  color: #26d5b6;
  border-bottom: 1px solid #26d5b6;

  &:hover,
  &:focus {
    border-bottom: 1px solid transparent;
  }
}

.header {
  display: flex;
  align-items: center;
}

.img {
  margin-right: 16px;
  width: 40px;
  height: 40px;
  padding: 10px;
  background: #3ceac1;
  border-radius: 5px;

  &.education {
    padding: 7px;
  }

  &.dollar {
    padding: 8px;

    path {
      fill: #1f342f;
    }
  }
}

.editIcon {
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #1f342f;
}

.description {
  margin-top: 16px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #68768b;
}
