.ageGrid {
  margin-bottom: 24px;
}

.checkboxGrid {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 8px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 16px;
    margin-bottom: 10px;
  }

  .checkbox {
    display: flex;
    align-items: center;
  }
}
