@import "../../../../../common/styles/mixins";

.root {
  margin: 0;
  padding: 0;
  list-style: none;
}

.title {
  font-family: $headings-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  margin: 0 0 16px;
  color: #1f342f;

  @include layout-flip {
    margin: 0 0 16px;
  }
}

.text {
  margin: 8px 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: #68768b;
}

.item {
  &:not(:last-child) {
    margin: 0 0 32px;
  }
}
