@import "../../common/styles/mixins";
@import "../../common/styles/variables";

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background-image: url("../../assets/images/backgrounds/about-background.png");
  background-repeat: no-repeat;
  background-position: center top;

  @include max-bg {
    background-size: 100%;
  }

  @include layout-flip {
    background-image: url("../../assets/images/backgrounds/about-mobile-background.png");
  }

  @media (min-width: 1536px) {
    background-image: url("../../assets/images/backgrounds/about-background-4k.png");
    background-size: auto;
  }

  .main {
    max-width: 1334px;
    width: 100%;
    margin: 32px auto 100px;
    padding: 0 50px 100px;

    @include layout-flip {
      margin: 47px 0 21px;
      padding: 0 24px 80px;
    }
  }

  .title {
    margin: 0 0 40px;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 50px;
    line-height: 63px;
    color: $secondary;

    @include layout-flip {
      margin: 0 0 16px;
      font-size: 33px;
      line-height: 41px;
      letter-spacing: 0.25px;
    }
  }

  .section {
    max-width: 900px;

    &:not(:last-of-type) {
      margin: 0 0 70px;

      @include layout-flip {
        margin: 0 0 25px;
      }
    }

    h2 {
      margin: 0 0 25px;
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 50px;
      color: $secondary;

      @include layout-flip {
        font-size: 30px;
        line-height: 41px;
        letter-spacing: 0.25px;
      }
    }

    p {
      margin: 0 0 20px;
      padding: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.18;
      color: $gray-700;

      @include layout-flip {
        font-size: 16px;
      }

      span {
        display: block;
      }
    }

    ul,
    ol {
      margin: 0 0 20px;
      padding: 0 0 0 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.18;
      color: $gray-700;

      @include layout-flip {
        font-size: 16px;
      }
    }

    ol {
      padding: 0 0 0 25px;
    }

    li {
      margin: 0 0 10px;
      padding: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.18;
      color: $gray-700;

      @include layout-flip {
        font-size: 16px;
      }
    }

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.18;
      color: $accent-color;
      text-decoration: underline;

      @include layout-flip {
        font-size: 16px;
      }
    }
  }
}
