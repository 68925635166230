@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.patients-therapist-tabs {
  padding: 32px 0 0;
  max-width: 600px;

  @include media-breakpoint-down(lg) {
    padding: 28px 0 0;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid $gray-200;

    @include media-breakpoint-down(lg) {
      overflow: auto;
      margin: 0 -24px;
    }
  }

  &__row-item {
    min-width: 150px;
    margin: 0;
    padding: 0 35px 20px;
    text-align: center;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    color: $secondary;
    border-bottom: 3px solid transparent;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    white-space: nowrap;
    transition: all 0.3s ease-in-out 0s;

    &:hover,
    &:focus {
      border-bottom: 3px solid $primary;
    }

    &.react-tabs__tab--selected {
      border-bottom: 3px solid $primary;
    }

    &:first-child {
      max-width: 150px;
    }

    @include media-breakpoint-down(lg) {
      min-width: min-content;
      margin: 0 20px;
      padding: 0 5px 10px;
      font-size: 17px;

      &:first-child {
        max-width: auto;
      }
    }
  }

  &__info {
    margin: 0 0 32px;
    padding: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8;
    letter-spacing: -0.01em;
    color: $gray-700;
  }

  &__video {
    background-color: transparent;
    margin: 0 0 32px;

    video {
      width: 100%;
      min-height: auto;
      background: $gray-400;
      border: 0;
      border-radius: 20px;

      @include media-breakpoint-down(lg) {
        margin: 0 0 24px;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 0 0 26px;
    padding: 0;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.3;
    color: $secondary;

    svg {
      margin: 0 10px 0 0;

      &.video {
        max-width: 23px;
      }

      &.client path,
      &.video path {
        fill: #68768b;
      }
    }

    @include media-breakpoint-down(lg) {
      margin: 0 0 18px;
      font-size: 17px;
    }
  }

  &__warning {
    margin: 0 0 16px;
    font-size: 13px;
    line-height: 1.1;
    text-align: start;
    color: #68768b;
  }

  &__row-website {
    display: flex;
    align-items: center;

    @include layout-flip {
      flex-direction: column;
      align-items: flex-start;
    }

    button {
      margin: 0 0 0 20px;

      @include layout-flip {
        margin: 15px 0 0 0;
      }
    }
  }

  &__text {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: $gray-700;

    span {
      display: inline-block;
    }
  }

  &__issues {
    margin: 0 0 24px;

    @include media-breakpoint-down(lg) {
      margin: 0 0 16px;
    }
  }

  &__issues-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 485px;
    margin: 0 -8px;
    padding: 0;
    list-style: none;
  }

  &__issues-item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 0 8px 16px;
    padding: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: $gray-700;
    border: 1px solid $gray-200;
    border-radius: 4px;

    &.active {
      color: $secondary;
      background: $mint;
      border: 1px solid $accent-color;
    }

    @include media-breakpoint-down(lg) {
      margin: 0 8px 8px;
      padding: 8px;
      font-size: 15px;
    }
  }

  &__treatment {
    margin: 0 0 42px;
    padding: 32px;
    border: 1px solid $gray-200;
    border-radius: 6px;

    @include media-breakpoint-down(lg) {
      margin: 0 0 24px;
      padding: 20px 0 0;
      border: 0;
      border-top: 1px solid $gray-200;
      border-bottom: 1px solid $gray-200;
    }
  }

  &__treatment-title {
    margin: 0 0 32px;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.3;
    color: $secondary;

    @include media-breakpoint-down(lg) {
      margin: 0 0 10px;
      display: flex;
      align-items: center;
      font-size: 17px;

      svg {
        margin: 0 10px 0 0;
      }
    }
  }

  &__treatment.empty {
    @include media-breakpoint-down(lg) {
      margin: 0 0 24px;
      padding: 24px 0;

      .patients-therapist-tabs__treatment-title {
        margin: 0 0 18px;
      }
    }
  }

  &__treatment-list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__treatment-item {
    flex: 0 0 33%;
    margin: 0 0 24px;

    @include media-breakpoint-down(lg) {
      margin: 0 0 16px;
    }
  }

  &__border {
    margin: 0 0 40px;
    padding: 0 0 40px;
    border-bottom: 1px solid $gray-200;

    @include media-breakpoint-down(lg) {
      margin: 0 0 24px;
      padding: 0 0 24px;
    }
  }

  &__age-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px 8px;
    padding: 0;
    list-style: none;

    @include media-breakpoint-down(lg) {
      margin: 0 -8px 8px;
      overflow: auto;
    }
  }

  &__age-item {
    flex-grow: 0;
    max-width: 150px;
    margin: 0 16px 32px;

    @include media-breakpoint-down(lg) {
      margin: 0 8px 16px;
    }
  }

  &__insurance-row {
    b {
      margin: 0 8px 0 0;
      font-weight: 500;

      @include media-breakpoint-down(lg) {
        font-weight: 400;
      }
    }

    span {
      display: inline-block;
    }

    & + .patients-therapist-tabs__insurance-row {
      margin: 16px 0 0;

      @include media-breakpoint-down(lg) {
        margin: 24px 0 0;
      }
    }
  }

  &__website-link {
    display: block;
    max-width: fit-content;
    margin: 0;
    color: $accent-color;
    cursor: pointer;
    word-break: break-all;
    border-bottom: 1px solid;
    transition: all 0.3s ease-in-out 0s;

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }

  &__fees-row {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: -0.01em;
    color: $gray-700;

    @include media-breakpoint-down(lg) {
      font-size: 15px;
    }

    span {
      display: inline;

      &:first-of-type {
        position: relative;

        &:after {
          content: "";
          display: inline-block;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
          width: 5px;
          height: 5px;
          margin: 0 16px;
          background: $gray-200;
          border-radius: 50%;
        }
      }
    }

    & + .patients-therapist-tabs__fees-row {
      margin: 16px 0 0;
    }
  }

  &__payment-list {
    display: flex;
    margin: 0 -8px;
    padding: 10px 0 0;
    list-style: none;
  }

  &__payment-item {
    display: flex;
    align-items: center;
    margin: 0 8px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: -0.01em;
    color: $gray-700;

    &__cash-icon {
      margin: -2px 10px 0 0;
    }
  }

  &__subject {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: -0.01em;
    color: $gray-700;

    @include media-breakpoint-down(lg) {
      font-size: 17px;
    }

    & + .patients-therapist-tabs__subject {
      margin: 16px 0 0;
    }

    span {
      display: inline-block;
    }

    &__about {
      span {
        display: block;
      }
    }
  }

  &__credentials-images {
    display: flex;
    flex-wrap: wrap;
    margin: 40px 0 0;

    @include layout-flip {
      margin: 20px 0 0;
    }
  }

  &__credentials-img {
    display: block;
    max-width: 75px;
    width: 100%;
    min-height: 50px;
    margin: 0 20px 20px 0;
    object-fit: cover;

    @include layout-flip {
      margin: 0 10px 10px 0;
      max-width: 50px;
      min-height: 25px;
    }
  }

  &__insurance-companies {
    padding-left: 18px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include layout-flip {
      grid-template-columns: 1fr;
    }

    li {
      line-height: 1.5;
    }
  }

  &__accordion {
    margin: 8px 0 24px;
  }

  &__card {
    .no-verified {
      background: transparent;
      border: 1px solid #d4e1f2;

      svg path {
        stroke: #a7b2cd;
      }
    }
  }
}
