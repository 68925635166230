.react-datepicker {
	&.datepicker-inline {
		width: 100%;
		min-width: 250px;
		border: none;
		border-radius: 0;
		background-color: transparent;
		font-family: inherit;
		color: inherit;

		.react-datepicker__month-container {
			float: none;
		}

		.react-datepicker__header {
			background-color: transparent;
			border: none;
			border-radius: 0;
			padding: 0;
		}

		.react-datepicker__current-month {
			font-size: 16px;
			font-weight: 600;
			color: inherit;
			text-align: left;
		}

		.react-datepicker__navigation {
			@include sizeElement(24px);

			font-size: 0;
			text-indent: inherit;
			transition: color 0.3s;

			&:hover {
				color: $primary;
			}

			&--previous {
				left: auto;
				right: 32px;
			}

			&--next {
				right: 0;
			}
		}

		.react-datepicker__navigation-icon {
			@include flexCenter;

			width: 100%;
			height: 100%;
			font-size: 0;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;

			&:before {
				content: none;
			}

			&:after {
				font-family: icomoon;
				display: inline-block;
				font-size: 20px;
			}

			&--previous {
				&:after {
					content: "\e90c";
				}
			}

			&--next {
				&:after {
					content: "\e90d";
				}
			}
		}

		.react-datepicker__month {
			margin: 0;
			border: 1px solid $gray-200;
			border-radius: 12px;
			overflow: hidden;
		}

		.react-datepicker__day-names {
			display: flex;
			flex-wrap: wrap;
			padding-top: 5px;
			margin-bottom: 0;

			@include media-breakpoint-up(md) {
				padding-top: 10px;
			}
		}

		.react-datepicker__day-name {
			@include flexCenter;

			flex: 1 0 0%;
			text-align: center;
			height: 36px;
			color: $gray-700;
			font-size: 14px;
			line-height: 1.2;
			font-weight: 600;
			margin: 0;

			@include media-breakpoint-up(md) {
				height: 50px;
			}
		}

		.react-datepicker__week {
			display: flex;

			&:not(:last-child) {
				border-bottom: 1px solid $gray-200;
			}
		}

		.react-datepicker__day {
			@include flexCenter;

			flex: 1 0 0%;
			margin: 0;
			width: auto;
			height: 36px;
			line-height: 1.2;
			font-size: 14px;
			border-radius: 0;
			transition: background-color 0.3s;

			@include media-breakpoint-up(md) {
				height: 56px;
			}

			&:not(.react-datepicker__day--selected):not(.react-datepicker__day--outside-month) {
				&:hover {
					background-color: rgba($primary, 0.15);
				}
			}

			&:not(:last-child) {
				border-right: 1px solid $gray-200;
			}

			&--selected {
				background-color: $primary;
				color: inherit;
				font-weight: inherit;
			}

			&--outside-month {
				background-color: $gray-90;
				color: $gray-300;
			}

			&--keyboard-selected {
				background-color: transparent;
			}
		}
	}
}