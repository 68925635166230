@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.sign_in {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  color: $gray-700;

  margin-bottom: 32px;

  @include layout-flip {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  a {
    color: #26d4b6;
  }
}
