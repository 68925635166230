@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.therapist-step__form {
  margin: 48px 0 0;

  @include layout-flip {
    margin: 0;
  }
}

.radio-inline {
  background: #f4f8fc;

  &.checked {
    background: #3ceac1;
  }

  &:not(:last-child) {
    margin-right: 16px;

    @media all and (max-width: 1024px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}

.therapist-step-11 {
  &__wrapper {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &__img {
    text-align: center;

    img {
      width: 92px;
      height: 110px;
      object-fit: cover;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 0 14px;
    }
  }

  & .patient-step__row {
    display: block;
    margin: 0 0 36px;

    @media (max-width: $desktop-m) {
      margin: 0;
    }
  }

  & .title {
    margin: 0 0 16px;
    text-align: left;

    @media (max-width: 900px) {
      display: none;
    }
  }

  & .patient-step__select {
    display: block;
    margin: 0 0 48px;
    font-size: 16px;
    line-height: 1.18;

    @include media-breakpoint-down(sm) {
      max-width: 327px;
      margin: 0 0 30px;
    }

    @include media-breakpoint-up(sm) {
      text-transform: uppercase;
    }
  }

  &__link {
    display: block;
    max-width: min-content;
    margin: 0 auto;
    white-space: nowrap;
  }

  & .patient-step__form {
    margin: 0 0 40px;

    @include media-breakpoint-down(sm) {
      margin: 0 0 10px;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 38px;

    @media (max-width: $desktop-m) {
      justify-content: center;
      margin: 0 0 32px;
    }
  }

  & .patient-step__actions {
    justify-content: space-between;
    margin: 0 -5px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex: 0 0 377px;
    margin: 0 0 40px;
    padding: 36px 48px;
    border: 1px solid #d4e1f2;
    border-radius: 6px;

    @media (max-width: $desktop-m) {
      max-width: 600px;
      flex: 0 0 100%;
    }

    @include media-breakpoint-down(sm) {
      padding: 23px 24px;
    }
  }

  &__column-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 66px;
    height: 66px;
    margin: 0 auto 8px;
    background: #defaf6;
    border-radius: 50px;
  }

  &__column-title {
    margin: 0 0 8px;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 26px;
    line-height: 1.26;

    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
  }

  &__column-text {
    max-width: 245px;
    margin: 0 auto 40px;
    padding: 0;
    font-size: 16px;
    line-height: 1.18;
    color: $gray-700;

    @include media-breakpoint-down(sm) {
      font-size: 15px;
      margin: 0 auto 32px;
    }
  }

  &__column-buttons {
    max-width: 190px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
    border: 1px solid #d4e1f2;
    border-radius: 6px;

    @media (max-width: $desktop-m) {
      max-width: 221px;
      width: 100%;
      margin: 0 auto;
    }

    @media (max-width: 374px) {
      padding: 6px;
    }
  }

  &__title {
    margin: 0 0 8px;
    font-family: $headings-font-family;
    font-size: 26px;
    line-height: 1.27;
    color: $secondary;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }

  &__subtitle {
    display: block;
    margin: 0 0 32px;
    font-size: 15px;
    line-height: 1.5;
    color: $gray-700;

    @include media-breakpoint-down(sm) {
      margin: 0 0 24px;
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border: none;

    label {
      margin: 0 0 0 16px;
      font-size: 16px;
      line-height: 1.18;
      color: $secondary;

      @include media-breakpoint-down(sm) {
        font-size: 15px;
      }
    }
  }
}

.therapist-step-11__checkbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0px -20px;
  padding: 0;

  @include media-breakpoint-down(sm) {
    max-width: 150px;
    margin: 0 auto;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
    padding: 10px 16px;
    font-weight: 400;
    line-height: 19px;
    color: $secondary;
    border: 1px solid $gray-200;
    border-radius: 6px;

    @include media-breakpoint-down(sm) {
      margin: 0;
    }

    &:hover,
    &:focus {
      background: #fafafa;
      border: 1px solid #dedede;
    }

    &[data-value="false"] {
      background: #3ceac1;
    }
  }

  input {
    z-index: -1;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:checked {
      &:hover,
      &:focus {
        background: #22c7a0;
        border: 1px solid #dedede;
      }

      & + span[data-value="true"] {
        background: #3ceac1;

        & + span[data-value="false"] {
          background: transparent;
        }
      }
    }
  }
}

.therapist-step-11__status {
  margin: -20px 0 40px;
}

.error_msg {
  color: $error;
}
