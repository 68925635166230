@import "common/styles/variables";
@import "common/styles/mixins";

.question {
  &:not(.intro) {
    position: relative;
    padding: 20px;

    @include media-breakpoint-up(sm) {
      padding: 20px 56px 10px;
    }
  }

  + .question {
    border-top: 1px dashed $gray-200;
  }

  &__title {
    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: baseline;
    }
  }

  &__number {
    @include sizeElement(28px);
    @include inlineFlexCenter;

    z-index: 5;
    border-radius: 50%;
    background-color: $mint;
    border: 1px solid $primary;
    color: $secondary;
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
    flex-shrink: 0;

    @include media-breakpoint-up(sm) {
      position: absolute;
      top: 16px;
      left: 16px;
      margin-right: 0;
    }
  }

  &.intro {
    margin: 0 0 35px;

    @include layout-flip {
      margin: 0 0 30px;
    }
  }

  &.disabled {
    background-color: $gray-100;
  }
}
