@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.wrapper {
  .addCreditCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;

    @include layout-flip {
      max-width: none !important;
      padding: 16px 24px;
      border-radius: 30px 30px 0 0;

      .mobileLine {
        width: 15%;
        height: 2px;
        background: #a7b3ce;
      }
    }

    .closeButton {
      @include clean-btn;
      align-self: flex-end;

      @include layout-flip {
        display: none;
      }
    }

    h1 {
      margin: 0 0 16px;
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 38px;
      color: $secondary;

      @include layout-flip {
        align-self: flex-start;
        margin: 32px 0 16px;
        font-size: 23px;
        line-height: 34px;
        letter-spacing: 0.25px;
      }
    }

    .form {
      .input {
        margin: 0 0 24px;

        @include layout-flip {
          margin: 0 0 12px;
        }

        &:last-of-type {
          margin: 0;
        }
      }

      .twoColumns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        margin: 0 0 24px;

        @include layout-flip {
          margin: 0 0 12px;
        }
      }

      .confirmation {
        margin: 0 0 24px;
      }

      button {
        margin: 0 0 16px;

        @include layout-flip {
          margin: 0 0 8px;
        }

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
}
