@import "../../common/styles/variables";
@import "../../common/styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background-image: url("../../assets/images/backgrounds/about-background.png");
  background-repeat: no-repeat;
  background-position: center top;

  @include max-bg {
    background-size: 100%;
  }

  @include layout-flip {
    background-image: url("../../assets/images/backgrounds/about-mobile-background.png");
  }

  @media (min-width: 1536px) {
    background-image: url("../../assets/images/backgrounds/about-background-4k.png");
    background-size: auto;
  }

  .inner {
    position: relative;
    max-width: 1334px;
    width: 100%;
    margin: 0 auto;
    padding: 54px 50px 22px;

    @include layout-flip {
      padding: 48px 24px 0;
    }
  }

  .title {
    margin: 0 0 48px;
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 63px;
    color: $secondary;

    @include layout-flip {
      font-size: 33px;
      line-height: 41px;
      letter-spacing: 0.25px;
    }
  }

  .accordion {
    margin: 0 0 16px;

    @include layout-flip {
      margin: 0 0 10px;
    }
  }

  .accordionTab {
    margin: 0;
    padding: 20px;
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: $secondary;
    background: #ffffff;
    border: 1px solid $gray-200;
    border-radius: 4px;

    @include layout-flip {
      padding: 10px;
      font-size: 20px;
      line-height: 25px;
    }

    path {
      stroke-width: 1px;
      stroke: $secondary;
    }
  }

  .accordionPanel {
    padding: 16px 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    color: $gray-700;

    @include layout-flip {
      font-size: 16px;
      line-height: 20px;
    }

    ul, p {
      margin: 16px 0;

      @include layout-flip {
        margin: 10px 0;
      }
    }
  }
}
