@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.testsTable {
  .table {
    width: 100%;

    .tableHead {
      flex: 0 0 15%;
      padding: 0 10px 32px;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.04em;
      color: $gray-700;

      &.sortable {
        display: flex;
        align-items: center;

        @media (max-width: 1420px) {
          span {
            flex: 0 1;
          }
        }

        svg {
          margin: auto auto auto 8px;
          flex-shrink: 0;
        }
      }

      &:first-of-type {
        flex: 1 0 20%;
      }

      @media (max-width: 1660px) {
        flex: 0 0 13.75%;

        &:first-of-type {
          flex: 1 0 25%;
        }

        &:last-of-type {
          flex: 1 0 20%;
        }
      }
    }

    .tableLine {
      display: flex;
      align-items: center;
      margin: 0 -10px;
      padding: 0 40px;
    }

    .tableBody {
      display: flex;
      flex-direction: column;
      box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);
      background: #ffffff;
      border-radius: 10px;

      .tableCell {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 15%;
        min-height: 122px;
        padding: 0 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: $gray-700;
        border-bottom: 1px solid $gray-200;

        &:first-of-type {
          flex: 1 0 20%;
        }

        @media (max-width: 1660px) {
          flex: 0 0 13.75%;

          &:first-of-type {
            flex: 1 0 25%;
          }

          &:last-of-type {
            flex: 1 0 20%;
          }
        }

        .centerWrapper {
          display: flex;
          align-items: center;

          .clockIcon {
            margin: 0 10px 0 0;
          }
        }

        &.costs {
          text-align: center;

          .free {
            padding: 10px 27px;
            color: #ffffff;
            background: #7b61ff;
            border-radius: 30px;
          }
        }

        .speedometer {
          font-size: 17px;
        }
      }

      .tableLink {
        @include clean-btn;
        text-align: left;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        color: $gray-700;
      }

      tr {
        &:last-of-type {
          td {
            border-bottom: none;
          }
        }
      }
    }

    .tableHead,
    .tableCell {
      &.tableLeft {
        justify-content: flex-start;
        text-align: left;
      }

      &.tableRight {
        justify-content: flex-end;
        text-align: right;
      }
    }
  }
}
