@import "../../../common/styles/_variables.scss";
@import "../../../common/styles/_mixins.scss";

.therapists-completed {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  &:after {
    content: "";
    width: 420px;
    height: 680px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: url("../../../assets/images/frames/ornament.png") center
      no-repeat;
    background-size: cover;
    z-index: -1;
    transition: all 0.2s ease-out;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &:before {
    content: "";
    width: 123px;
    height: 128px;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
    background: url("../../../assets/images/frames/frame-right-blue.png") center
      no-repeat;
    background-size: contain;
    z-index: -1;
    transition: all 0.2s ease-out;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 24px;
  }

  &__container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    transition: all 0.2s ease-out;

    &:before {
      content: "";
      width: 240px;
      height: 209px;
      position: absolute;
      left: -35px;
      top: 127px;
      background: url("../../../assets/images/frames/frame.png") center
        no-repeat;
      z-index: -1;
      transition: all 0.2s ease-out;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 248px;
      height: 263px;
      left: 0;
      bottom: 0;
      background: url("../../../assets/images/frames/frame-left.png") center
        no-repeat;
      z-index: -1;
      transition: all 0.2s ease-out;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__caption {
    position: relative;
    margin: 0 0 12px;
    text-align: center;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 46px;
    line-height: 1.2;
    transition: all 0.2s ease-out;

    @include media-breakpoint-down(sm) {
      font-size: 26px;
      margin: 0 0 8px;
    }

    &:before {
      content: "";
      position: absolute;
      top: -55px;
      right: -35px;
      display: block;
      width: 52px;
      height: 56px;
      background: url("../../../assets/images/frames/highlight.png") center
        no-repeat;
      background-size: cover;

      @include media-breakpoint-down(sm) {
        top: -35px;
        right: -35px;
      }
    }
  }

  &__subtitle {
    margin: 0 0 53px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $accent-color;

    @include media-breakpoint-down(sm) {
      margin: 0 0 56px;
      font-size: 16px;
      text-transform: capitalize;
    }
  }

  &__button {
    margin: 78px 0 0;
    max-width: 386px;
    width: 100%;
    flex: 0 0 100%;

    @include media-breakpoint-down(sm) {
      margin: 56px 0 0;
      max-width: 327px;
      padding: 18px 47px;
    }
  }
}
