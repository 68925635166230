@import "../../../../../../../../common/styles/mixins";

.title {
  width: 100%;
  margin: 0;
  padding: 10px 16px;
  font-weight: 400;
  font-family: $headings-font-family;
  font-size: 20px;
  line-height: 1.2;
  background: #f4f8fc;
  border-radius: 6px 6px 0px 0px;

  @include layout-flip {
    font-size: 18px;
    margin: 0 0 24px;
    padding: 0;
    background: none;
  }
}

.subtitle {
  display: block;
  margin: 0 0 16px;
  font-size: 16px;
  line-height: 1.1;

  @include layout-flip {
    margin: 0 0 10px;
    font-size: 14px;
  }
}

.section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px 16px;

  @include layout-flip {
    padding: 0;

    & + .title {
      margin: 29px 0 24px;
    }
  }
}

.field {
  max-width: 280px;
  margin: 0;
  padding: 0;
  border: none;

  @media (min-width: 900px) {
    &:not(:nth-last-child(-n + 2)) {
      margin: 0 0 24px;
    }
  }

  @include layout-flip {
    max-width: 100%;
    width: 100%;

    &:not(:last-of-type) {
      margin: 0 0 13px;
    }
  }

  &.name {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0;

    .input {
      max-width: 100%;
    }
  }
}

.input {
  max-width: 116px;

  @include layout-flip {
    max-width: 100%;
  }
}
