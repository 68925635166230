@import "~common/styles/variables";


.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 425px;
  width: 100%;
  padding: 40px 25px;
  background: #ffffff;
  border: 1px solid #d4e1f2;
  border-radius: 15px;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.1));

  @media (max-width: 475px) {
    max-width: 290px;
  }

  .avatar {
    width: 104px;
    height: 104px;
    margin: 0 0 30px;
    border-radius: 50%;
    object-fit: cover;
  }

  .stars {
    display: flex;
    justify-content: center;
    margin: 0 0 40px;

    svg + svg {
      margin: 0 0 0 10px;
    }
  }

  .cardText {
    margin: 0 0 30px;
    padding: 0;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.4;
    text-align: center;
    word-break: break-word;
    letter-spacing: -0.01em;
    color: #68768b;
  }

  .cardTitle {
    margin: auto 0 0;
    padding: 0;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 26px;
    line-height: 1.1;
    text-align: center;
    color: #1f342f;
  }
}
