$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icomoon-video-end-call: "\e913";
$icomoon-microphone-off: "\e90f";
$icomoon-microphone: "\e910";
$icomoon-camera-off: "\e911";
$icomoon-camera: "\e912";
$icomoon-remove: "\e90e";
$icomoon-arrow-small-thin-down: "\e90a";
$icomoon-arrow-small-thin-up: "\e90b";
$icomoon-arrow-small-thin-left: "\e90c";
$icomoon-arrow-small-thin-right: "\e90d";
$icomoon-call: "\e906";
$icomoon-calendar: "\e907";
$icomoon-decline: "\e908";
$icomoon-approve: "\e909";
$icomoon-arrow-small-left: "\e902";
$icomoon-arrow-small-right: "\e903";
$icomoon-arrow-small-top: "\e904";
$icomoon-arrow-small-bottom: "\e905";
$icomoon-lamp: "\e901";
$icomoon-checkmark: "\e900";

