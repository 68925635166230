@import "../../styles/variables";
@import "../../styles/mixins";

.preloader {
  @include flexCenter;

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: $white;
  color: $secondary;
  z-index: 1001;

  @include layout-flip {
    flex-direction: column;
  }
}

.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0;
}

.loaded .preloader {
  display: none;
}