@import "../../styles/variables";
@import "../../styles/mixins";

.search {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc($btn-height - 10px);
  margin-bottom: 8px;
  width: 100%;
  padding: $input-padding;
  background-color: $input-background-color;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  transition: $input-transition;

  @include media-breakpoint-up(md) {
    height: $input-height;
  }

  &:hover,
  &:focus,
  &.active {
    border-color: $primary;
  }

  &__input {
    @include placeholder {
      color: $input-placeholder-color;
      transition: opacity 0.3s;
      font-size: $input-font-size;
    };

    padding: 0;
    width: calc(100% - 40px);
    font-size: 20px;
    line-height: $input-line-height;
    border: none;
    background: transparent;
    outline: none;
    color: $input-color;
    transition: $input-transition;

    &:focus {
      @include placeholder {
        opacity: 0;
      };
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
  }

  &__selected {
    padding: 10px;
    background: #3ceac1;
    border-radius: 50px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
  }
}