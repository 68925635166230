@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.root {
  background-image: url("../../../assets/images/backgrounds/therapist-lead-background.png");
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;

  @include max-bg {
    background-size: 100%;
  }

  @include xl {
    background-position: center top -200px;
  }

  @media (min-width: 900px) {
    background-position: center top -330px;
  }

  @media (min-width: 1150px) {
    background-position: center top -280px;
  }

  @media (min-width: 1150px) {
    background-position: center top -280px;
  }


  @media (min-width: 1600px) {
    background-position: center top;
  }

  @media (min-width: 1536px) {
    background-image: url("../../../assets/images/backgrounds/therapist-lead-background-4k.png");
    background-position: center top -20px;
    background-size: auto;
  }

  @include layout-flip {
    background-position: center top;
    background-image: url("../../../assets/images/backgrounds/mobile-therapist-lead-background.png");
  }

  .slider {
    padding: 64px 0 0;
    background: #f2f6fb;

    :global {
      button[type="button"] {
        margin: -55px 10px 0;
      }
    }

    @include layout-flip {
      margin: 0;
      padding: 31px 0;

      :global {
        .swiper-pagination {
          display: block;
        }
      }
    }
  }
}
