@import "../../../../../common/styles/variables";

.therapyList {
  display: flex;
  margin: 0 0 42px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    flex-wrap: nowrap;
    margin: 0 0 16px;
    overflow: auto;
  }
}

.therapyListItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 40px;
  }

  @media (max-width: 1024px) {
    padding: 10px 10px;
    background: #f1f7ff;
    border: 1px solid $gray-200;
    border-radius: 50px;
    font-weight: 600;
    font-size: 13px;
    line-height: 1;
    letter-spacing: 0.01em;
    color: $gray-700;

    img {
      display: none;
    }

    &:not(:last-child) {
      margin-right: 7px;
    }
  }
}

.therapyListIcon {
  margin-bottom: 16px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  transition: all 0.2s ease-out;

  &:hover {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  }

  &.active {
    box-shadow: 0 0 35px rgba($color: #000, $alpha: 0.3);
  }
}

.therapyListName {
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  color: #68768b;
}
