*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
	margin: 0;
}

img, svg {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-out;
}

textarea {
	font-family: inherit;
}