@import "../../../../../common/styles/variables";

.filterButton {
  display: none;
  align-items: center;
  margin: 0 0 16px;
  padding: 14px 19px 14px 13px;
  font-size: 14px;
  line-height: 1.2;
  color: $gray-700;
  border: 1px solid $gray-200;
  border-radius: 4px;
  background: transparent;

  @media (max-width: 992px) {
    display: flex;
  }

  span {
    position: relative;
    margin: 0 8px;
  }

  &.filterButtonActive {
    color: $accent-color;

    svg {
      path {
        fill: $accent-color;
      }
    }
  }
}

.filterButtonText {
  margin: 0 8px;
}

.filterButtonCount {
  display: flex;
  align-items: center;
  margin-right: 8px;
  &::before {
    content: "";
    margin-right: 8px;
    display: block;
    width: 4px;
    height: 4px;
    vertical-align: center;
    background: $accent-color;
    border-radius: 50%;
  }
}

.mobile_root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 10000;
  background-color: #fff;
  padding: 16px 24px 106px;
  overflow: auto;

  &.padding_bottom {
    padding-bottom: 220px;
  }
}

.mobile_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.mobile_title {
  font-family: $headings-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;

  letter-spacing: 0.25px;

  color: $secondary;
}

.mobile_close {
  background: none;
  border: none;
  cursor: pointer;
}

.mobile_toggle_filter {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid #f1f7ff;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.01em;
    max-width: 240px;
    color: $gray-700;
  }
}

.mobile_checkbox {
  display: flex;
  align-items: center;

  padding-bottom: 24px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    letter-spacing: -0.01em;

    color: $gray-700;
  }
}

.mobile_list_filter {
  padding: 30px 0;
  border-bottom: 1px solid #f1f7ff;
}

.mobile_list_filter_title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: $secondary;
  cursor: pointer;

  svg {
    path {
      fill: #a7b2cd;
      stroke: #a7b2cd;
    }

    transform: rotate(-90deg);
    transition: transform 200ms ease-in-out;
  }

  &.collapsed {
    svg {
      transform: rotate(0);
    }
  }
}

.mobile_list_filter_content {
  max-height: 0;
  display: none;
  transition: all 200ms ease-in-out;
  overflow: hidden;
  padding-top: 24px;

  &.collapsed {
    max-height: none;
    display: block;
  }
}

.mobile_buttom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 42px 24px 26px;
  background: linear-gradient(179.56deg, rgba(255, 255, 255, 0.68) 1.14%, #ffffff 68.69%);
  border-radius: 0px 0px 30px 30px;
}

.mobile_results_button {
  min-width: 100%;
}

.filterRow {
  display: flex;
}
