@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.root {
  z-index: 1;
  position: relative;
  padding: 120px 0 0;

  @include layout-flip {
    margin: 0;
    padding: 74px 0 34px;
  }

  .inner {
    max-width: 1096px;
    margin: 0 auto;
    padding: 0 50px 50px;

    @include layout-flip {
      max-width: 430px;
      padding: 0 24px 24px;
    }

    & + .inner {
      padding: 130px 24px 0;

      @include layout-flip {
        padding: 60px 40px 0;
      }
    }
  }

  .title {
    text-align: center;
    margin: 0 0 80px;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.2;
    color: #000000;

    @include layout-flip {
      margin: 0 0 25px;
      font-size: 33px;
    }
  }

  .row {
    display: flex;
    align-items: center;

    @include layout-flip {
      justify-content: center;
    }
  }

  .list {
    margin: 52px 0 0;
    padding: 0;
    list-style: none;
    border: 3px solid transparent;

    @include layout-flip {
      margin: 27px 0 0;
      max-width: 150px;
      border: 1px solid transparent;
    }

    & + .list {
      margin: 0 0 0 55px;

      @include layout-flip {
        margin: 0 0 0 3px;
      }
    }

    &.listColumn {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 16px 25px;
      background: #ffffff;
      border-radius: 20px;

      @include layout-flip {
        padding: 8px 8px 10px;
        border-radius: 10px;
      }

      .item {
        font-size: 20px;

        @include layout-flip {
          font-size: 14px;
        }

        & + .item {
          margin: 25px 0 0;

          @include layout-flip {
            margin: 20px 0 0;
          }
        }
      }
    }

    &.active {
      background: #f2f6fb;
      border: 3px solid #3ceac1;

      @include layout-flip {
        border: 1px solid #3ceac1;
      }
    }
  }

  .item {
    font-weight: 400;
    font-size: 25px;
    line-height: 1.1;
    color: #68768b;

    @include layout-flip {
      font-size: 14px;
      line-height: 1.4;
    }

    & + .item {
      margin: 40px 0 0;

      @include layout-flip {
        margin: 17px 0 0;
      }
    }

    svg {
      width: 45px;
      height: 45px;

      @include layout-flip {
        width: 30px;
        height: 30px;
      }
    }
  }
}
