@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.block {
	margin-bottom: 50px;

	@include min-layout-flip {
		background-color: $white;
		border: 1px solid $gray-200;
		border-radius: 10px;
		padding: 40px;
	};
}

.table {
	width: 100%;
	border-collapse: collapse;
	font-size: 16px;

	@include media-breakpoint-up(sm) {
		border-collapse: separate;
		border-spacing: 0 16px;
		font-size: 12px;
	}

	@include media-breakpoint-up(xl) {
		font-size: 14px;
	}

	@include media-breakpoint-up(xxl) {
		font-size: 16px;
	}

	tbody,
	tbody tr,
	tbody tr td {
		@include media-breakpoint-down(sm) {
			display: block;
		}
	}

	&__cellWithRoundIcon {
		width: 80px;
	}

	&__sortArrow {
		width: 10px;
		height: auto;
		margin-left: 5px;
	}

	&__withArrow {
		cursor: pointer;
		transition: color 0.3s;

		&:hover {
			color: $primary;
		}
	}

	&__roundIcon {
		@include sizeElement(44px);
		@include inlineFlexCenter;

		border-radius: 100%;
		background-color: $mint;
		border: 1px solid $primary;

		img {
			width: 24px;
		}
	}

	&__downloadCell {
		@include media-breakpoint-down(sm) {
			padding: 0;
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	&__btnWithIcon {
		@include media-breakpoint-down(sm) {
			font-size: 15px;
			min-width: 120px;
			padding: 12px 20px;
			border-radius: 10px;
			font-weight: 400;
		}

		@include media-breakpoint-up(sm) {
			background-color: transparent;
			padding: 0;
			border: none;
			font-size: 0;
		}

		display: inline-flex;
		cursor: pointer;

		@include media-breakpoint-up(sm) {
			transition: opacity 0.3s;
		}

		&:hover {
			@include media-breakpoint-up(sm) {
				opacity: 0.7;
				background-color: transparent !important;
				border: none !important;
			}
		}

		svg {
			@include sizeElement(15px);

			@include media-breakpoint-down(sm) {
				display: none;
			}

			@include media-breakpoint-up(sm) {
				@include sizeElement(20px);
			}
		}
	}

	&__link {
		display: inline-block;
		background-color: transparent;
		padding: 0;
		border: none;
		cursor: pointer;
		color: $primary;
		font-size: inherit;
		transition: color 0.3s;

		&:hover {
			color: lighten($primary, 10%);
		}
	}

	thead {
		@include media-breakpoint-down(sm) {
			display: none;
		}

		th {
			text-transform: uppercase;
			font-size: 12px;
			line-height: 1.1;
			color: $gray-700;
			font-weight: 500;
			white-space: nowrap;

			&.table__sortable {
				.table__sortArrow {
					transform: rotate(180deg);
				}
			}
		}

		th {
			padding-top: 15px;
			padding-bottom: 5px;
		}
	}

	tbody {
		tr {
			position: relative;

			&:not(:last-child) {
				margin-bottom: 60px;

				@include media-breakpoint-up(sm) {
					margin-bottom: 0;
				}
			}

			td {
				&:first-child {
					@include media-breakpoint-up(sm) {
						border-left: 1px solid $gray-200;
						border-radius: 6px 0 0 6px;
					}
				}

				&:last-child {
					@include media-breakpoint-up(sm) {
						border-right: 1px solid $gray-200;
						border-radius: 0 6px 6px 0;
					}
				}
			}
		}

		td {
			padding-top: 5px;
			padding-bottom: 5px;

			@include media-breakpoint-up(sm) {
				background-color: $white;
				border-top: 1px solid $gray-200;
				border-bottom: 1px solid $gray-200;
				padding-top: 15px;
				padding-bottom: 15px;
			}
		}
	}

	th, td {
		text-align: left;

		@include media-breakpoint-up(sm) {
			padding-left: 10px;
			padding-right: 10px;
		}

		@include media-breakpoint-up(xl) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}