.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;

  background: #ffffff;

  border: 1px solid #d4e1f2;
  border-radius: 10px;
}
