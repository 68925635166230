@import "../../styles/variables";
@import "../../styles/mixins";

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $btn-font-family;
  font-size: $btn-font-size;
  font-weight: $btn-font-weight;
  border-radius: $btn-border-radius;
  line-height: $btn-line-height;
  letter-spacing: 0.01em;
  border: $btn-border-width solid transparent;
  outline: none;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  // size
  &:not(.btn-sm):not(.btn-lg) {
    min-height: calc($btn-height - 12px);
    padding: 5px 25px;

    @include media-breakpoint-up(md) {
      min-height: $btn-height;
      padding: 10px 36px;
    }

    svg {
      @include sizeElement(24px);

      object-fit: contain;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .btn__spinner {
      border-radius: $btn-border-radius;
    }

    &.with-left-side-icon {
      white-space: nowrap;
      padding-left: 12px;
      padding-right: 12px;

      @include media-breakpoint-up(sm) {
        padding-left: 24px;
        padding-right: 24px;
      }

      .btn-icon {
        margin-right: 12px;

        @include media-breakpoint-up(sm) {
          margin-right: 16px;
        }
      }
    }
  }

  &-sm {
    font-size: 14px;
    min-height: $btn-sm-height;
    padding: 8px 16px;
    min-width: 110px;

    @include media-breakpoint-up(xxs) {
      font-size: $btn-font-size;
    }

    @include media-breakpoint-up(sm) {
      padding: 8px 28px;
    }

    svg {
      @include sizeElement(20px);

      object-fit: contain;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .btn__spinner {
      border-radius: $btn-border-radius;
    }
  }

  &-lg {
    min-height: calc($btn-lg-height - 10px);
    padding: 5px 25px;

    @include media-breakpoint-up(md) {
      min-height: $btn-lg-height;
      padding: 10px 40px;
    }

    svg {
      @include sizeElement(24px);

      object-fit: contain;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .btn__spinner {
      border-radius: $btn-border-radius;
    }
  }

  // color
  &-primary {
    background-color: $primary;
    border-color: $primary;
    color: $secondary;

    &:hover {
      background-color: darken($primary, 10%);
      border-color: darken($primary, 10%);
      color: $secondary;
    }
  }

  &-outline-primary {
    background-color: transparent;
    border-color: $primary;
    color: $secondary;

    &:hover {
      background-color: rgba($primary, 0.1);
      border-color: $primary;
      color: $secondary;
    }
  }

  &-green-light {
    background-color: $mint;
    border-color: $mint;
    color: $secondary;

    &:hover {
      background-color: darken($mint, 5%);
      border-color: darken($mint, 5%);
      color: $secondary;
    }
  }

  &-outline-green-light {
    background-color: $white;
    border-color: $mint;
    color: $teal-dark;

    &:hover {
      background-color: rgba($mint, 0.2);
      border-color: $mint;
      color: $teal-dark;
    }
  }

  &-outline-gray {
    background-color: $white;
    border-color: $gray-200;
    color: $secondary;

    &:hover {
      background-color: rgba($gray-200, 0.2);
      border-color: darken($gray-200, 10%);
      color: $secondary;
    }
  }

  &-red {
    background-color: $red;
    border-color: $red;
    color: $white;

    &:hover {
      background-color: darken($red, 10%);
      border-color: darken($red, 10%);
      color: $white;
    }
  }

  &-outline-red {
    background-color: $white;
    border-color: $red;
    color: $secondary;

    &:hover {
      background-color: rgba($red, 0.05);
      border-color: $red;
      color: $secondary;
    }
  }

  &-outline-red-muted {
    background-color: $white;
    border-color: $red-muted;
    color: $secondary;

    &:hover {
      background-color: rgba($red-muted, 0.05);
      border-color: $red-muted;
      color: $secondary;
    }
  }

  &-outline-red-light {
    background-color: $white;
    border-color: $pink;
    color: $red;

    &:hover {
      background-color: $white;
      border-color: darken($pink, 12.5%);
      color: $red;
    }
  }

  &-yellow {
    background-color: $yellow;
    border-color: $yellow;
    color: $secondary;

    &:hover {
      background-color: darken($yellow, 10%);
      border-color: darken($yellow, 10%);
      color: $secondary;
    }
  }

  &-turquoise {
    background-color: $turquoise;
    border-color: $turquoise;
    color: $secondary;

    &:hover {
      background-color: darken($turquoise, 10%);
      border-color: darken($turquoise, 10%);
      color: $secondary;
    }
  }

  &-gray {
    background-color: $gray-200;
    border-color: $gray-200;
    color: $secondary;

    &:hover {
      background-color: darken($gray-200, 5%);
      border-color: darken($gray-200, 5%);
      color: $secondary;
    }
  }

  &-white {
    background-color: $white;
    border-color: $white;
    color: $secondary;

    &:hover,
    &:focus {
      background-color: darken($white, 5%);
      border-color: darken($white, 5%);
      color: $secondary;
    }
  }

  &-link-secondary {
    background-color: transparent;
    border-color: transparent;
    color: $secondary;

    &:hover {
      color: $secondary;
      background-color: $gray-200;
      border-color: $gray-100;
    }
  }

  &-link-gray {
    background-color: transparent;
    border-color: transparent;
    color: $gray-400;

    &:hover {
      color: $secondary;
    }
  }

  &-link {
    background-color: transparent;
    border-color: transparent;
    color: $primary;

    &:hover {
      color: darken($primary, 10%);
    }
  }

  &:disabled,
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.6;
  }

  // additional
  .loader {
    @include flexCenter;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    background-color: rgba($white, 0.3);
  }

  &__spinner {
    background-color: rgba($white, 0.5);
    position: absolute;
    top: -$btn-border-width;
    bottom: -$btn-border-width;
    left: -$btn-border-width;
    right: -$btn-border-width;

    &:after {
      @include sizeElement(25px);

      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border: 4px solid transparent;
      border-top-color: $white;
      border-radius: 50%;
      animation: button-loading-spinner 1s ease infinite;
    }
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.google-icon {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  display: inline-block;
  background: url("../../../assets/images/google-icon.svg") center no-repeat;
}