@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.form {
  margin: 0 0 24px;

  @include layout-flip {
    margin: 0 0 16px;
  }

  .input {
    margin: 0 0 24px;

    @include layout-flip {
      &:last-of-type {
        margin: 0;
      }
    }
  }
}

.helpMessage {
  text-align: center;
}
