@import "common/styles/variables";
@import "common/styles/mixins";


.bottomIconDiv {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.bottomIcon {
  width: 64px;
}

@media only screen and (max-width: 400px) {
  .bottomIcon {
      width: 40px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 480px) {
  .bottomIcon {
      width: 48px;
  }
}

.bottomText {
  font-size: 1.25rem;
  font-weight: 500;
  color: #EFF8FE;
}

@media only screen and (max-height: 700px) {

  .bottomText {
    font-size: 1rem;
  }
}