@import "../../../../../common/styles/variables";

.root {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 100%;
  min-width: 200px;
  padding: 10px 0 0;

  .phone {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 16px;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 23px;
    line-height: 1.23;
    color: $secondary;

    &:last-of-type {
      margin: 0 0 40px;
    }

    .phoneIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      margin: 0 16px 0 0;
      border-radius: 50%;
      border: 1px solid $primary;
    }

    .phoneText {
      display: flex;
      flex-direction: column;
    }

    small {
      margin: 5px 0 0;
      font-size: 20px;
      color: #1f342fa6;
      letter-spacing: -1px;
    }

    &.disabled {
      pointer-events: none;

      .phoneIcon {
        filter: grayscale(1);
      }
    }

    @media (max-width: 1366px) {
      font-size: 20px;

      .phoneIcon {
        width: 30px;
        height: 30px;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      small {
        margin: 0 0 0 4px;
        font-size: 18px;
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 23px 47px;
    margin: 0 0 16px;
    font-weight: 600;
    background: #defaf6;
    border-color: #defaf6;

    @media (max-width: 1200px) {
      padding: 23px 37px;
    }

    &.premium {
      background: $primary;
      border-color: $primary;

      &:hover,
      &:focus {
        background: #22c7a0;
        border: 1px solid #22c7a0;
      }
    }

    &.available {
      background: $primary;
      border-color: $primary;

      &:hover,
      &:focus {
        background: #22c7a0;
        border: 1px solid #22c7a0;
      }
    }

    &.wait {
      background: #ffda79;
      border-color: #ffda79;

      &:hover,
      &:focus {
        background: #ffda79;
        border-color: #ffda79;
      }
    }

    &.offline {
      background: $red;
      border-color: $red;

      &:hover,
      &:focus {
        background: $red;
        border-color: $red;
      }
    }

    svg {
      flex-shrink: 0;
      width: 24px;
      margin: -2px 10px 0 0;
    }

    & + .button {
      height: 69px;
      background: $mint;
      border: 1px solid $mint;
    }
  }
}
