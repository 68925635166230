@import "common/styles/variables";
@import "common/styles/mixins";

// .videoWrapper {
//   position: relative;
//   height: 100%;
//   // height: calc(100svh - 74px);
//   overflow: hidden;

//   @include min-layout-flip {
//     height: calc(100vh - 84px);
//     height: calc(100svh - 84px);
//     margin-top: 9px;
//   }

//   @include media-breakpoint-up(lg) {
//     height: calc(100vh - 112px);
//     height: calc(100svh - 112px);
//     margin-top: 0;
//   }

//   &__actionsBlock {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     width: 100%;
//     z-index: 5;
//   }
// }


* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
  margin: 0;
}


body {
  background: linear-gradient(157deg, #EFF8FE 0%, #F5FBFF 100%);
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

// header {
//   padding: 38px 100px 0 100px !important;
//   margin: 0 auto !important;
//   max-width: 1440px !important;
//   width: 100% !important;
//   display: flex !important;
//   justify-content: space-between !important;
//   align-items: center !important;
// }

.main {
  padding: 12px 100px 0 100px;
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;


}

@media only screen and (max-width: 1024px) {
  .main {
      padding: 12px 40px 0 40px;
  }
  header {
      padding: 24px 40px 0 40px !important;
  }
}

@media only screen and (max-width: 480px) {
  .main {
      padding: 12px 20px 0 20px;
  }

  header {
      padding: 5px 20px 0 20px !important;
  }
}

.mainButton {
  display: inline-flex;
  height: 58px;
  cursor: pointer;
  padding: 18px 56px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #FE4949;
  border: none;
  color: #EFF8FE;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 24px;
}

.mainVideo {
  position: relative;
}

.placeholder {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.placeholderTablet {
  display: none;
}

.placeholderMobile {
  display: none;
}

@media only screen and (max-width: 1600px) and (min-width: 1025px) and (max-height: 900px) {
  .placeholder {
      max-height: 520px;
  }
}


@media only screen and (max-width: 1600px) and (min-width: 1025px) and (max-height: 800px) {
  .placeholder {
      max-height: 440px;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1025px) and (max-height: 750px) {
  .placeholder {
      max-height: 400px;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1025px) and (max-height: 700px) {
  .placeholder {
      max-height: 360px;
  }
}

@media only screen and (max-width: 1025px) and (max-height: 1367px) {
  .placeholder {
      display: none;
  }
  .placeholderTablet {
      display: inline;
  }
  .placeholderTablet {
      width: 100%;
      height: 600px;
  }
}

@media only screen and (max-width:480px) and (max-height: 940px) {
  .placeholder {
      display: none;
  }

  .placeholderTablet {
      display: none;
  }
  .placeholderMobile {
      display: inline;
  }
  .placeholderMobile {
      width: auto;
      height: auto;
      object-fit: contain;
  }
}

@media screen and (max-width: 480px) and (max-height: 700px) {
  .placeholderMobile {
      width: auto;
      height: 320px;
  }

}

.selfVideo {
  position: absolute;
  top: 0;
  right: 0;
}

.selfInner {
  position: relative;
}

.selfImage {
  border-radius: 8px;
}

.selfImageTablet, .selfImageMobile {
  display: none;
}

@media only screen and (max-width: 767px) and (min-width: 481px) {
  .selfImage {
      display: none;
  }

  .selfImageTablet {
      display: inline;
  }

}

@media only screen and (max-width: 480px) {
  .selfImage {
      display: none;
  }

  .selfImageMobile {
      display: inline;
  }

  .switch {
      padding: 8px;
  }

}

.switch {
  cursor: pointer;
  background: #62B4F2;
  border: none;
  border-radius: 8px;
  position: absolute;
  right: 0;
  bottom: 4px;
  padding: 12px;
}

@media only screen and (max-width: 480px) {
  .switch {
      padding: 8px;
  }

  .switch-icon {
      width: 24px;
  }

}




.timeCall {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

@media only screen and (max-width: 1025px) {
  .timeCall {
      margin-bottom: 0;
  }
}

.timeCallIcon {
  color: red;
}

.timeCallTime {
  color: #294C65;
  font-size: 1.25rem;
  margin-bottom: 4px
}

.bottomBar {
  width: 100%;
  background: #62B4F2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 96px;
  position: absolute;
  bottom:0;
}

@media screen and (max-width: 1025px) {
  .bottomBar {
      position: fixed;
      bottom: 0;
  }

}

.bottomIconDiv {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.bottomIcon {
  width: 64px;
}

@media only screen and (max-width: 480px) {
  .bottomIcon {
      width: 48px;
  }
}

.bottomText {
  font-size: 1.25rem;
  font-weight: 500;
  color: #EFF8FE;
}


/*Join Meeting CSS*/

@media only screen and (max-width: 481px) {
  .main {
      padding-bottom: 80px;
  }
}

#joinCall {
  background: #01CD9D;
}

.joinMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.joinMessageText {
  color: #294C65;
  font-size: 1.25rem;
  margin-bottom: 4px;
  text-align: center;
  max-width: 320px;
}

@media screen and (max-width: 480px) {
  .joinMessageText {
      font-size: 1.125rem;
  }

}

.waitingPlaceholder {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

@media only screen and (max-width: 1536px) and (max-height: 901px) {
  .waitingPlaceholder {
      height: 460px;
  }
}



@media only screen and (max-width: 1370px) and (max-height: 780px) {
  .waitingPlaceholder {
      height: 350px !important;
  }
}

@media only screen and (max-width: 1300px) and (max-height: 721px) {
  .waitingPlaceholder {
      height: 280px !important;
  }
}

@media only screen and (max-width: 1025px) and (max-height: 1367px) {
  .waitingPlaceholder {
      height: 480px !important;
  }
}

@media only screen and (max-width: 376px) and (max-height: 677px) {
  .waitingPlaceholder {
      height: 240px !important;
  }
}