@import "../../styles/variables";
@import "../../styles/mixins";

.react-tabs {
  &__tab-list {
    display: flex;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $gray-200;
    list-style: none;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include layout-flip {
      justify-content: center;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__tab {
    margin: 0 90px 0 0;
    padding: 10px 16px 21px;
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: $secondary;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
    border-bottom: 3px solid transparent;

    &:hover,
    &:focus {
      border-bottom: 3px solid $primary;
    }

    &:focus-visible {
      outline: none;
    }

    @include layout-flip {
      margin: 0 35px 0 0;
      padding: 0 0 11px;
      font-size: 17px;
      line-height: 34px;
      letter-spacing: 0.25px;
    }

    &:last-of-type {
      margin: 0;
    }

    &--selected {
      border-bottom: 3px solid $primary;
    }
  }

  &__tab-panel {
    &--selected {
      padding: 32px 0;
    }
  }
}
