@import "../../../../../common/styles/mixins";

.root {
  position: relative;
  display: flex;
  margin: 0 0 34px;
  padding: 24px 32px;
  background: #ffffff;
  border: 2px solid #3ceac1;
  border-radius: 20px;

  @media (max-width: 1100px) {
    display: block;
    max-width: 100%;
    margin: 0 0 34px;
  }

  @include layout-flip {
    padding: 32px 16px;
  }

  .title {
    margin: 0 0 24px;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.1;
    letter-spacing: -0.01em;
    color: #1f342f;
    word-break: break-all;

    @include layout-flip {
      text-align: center;
    }

    @media (max-width: 600px) {
      margin: 0 0 32px;
      font-size: 30px;
    }
  }

  .info {
    max-width: 504px;
    width: 100%;

    @include layout-flip {
      max-width: none;
    }
  }

  .inner {
    max-width: 300px;
    width: 100%;
    margin: 0 0 0 32px;

    @include layout-flip {
      max-width: none;
      margin: 0;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 24px;

    @include layout-flip {
      justify-content: center;
    }
  }

  .oldPrice {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 16px 0 0;
    font-family: $headings-font-family;
    font-size: 24px;
    line-height: 1.1;
    color: #a1aab7;
    word-break: break-all;

    .cents {
      align-self: self-end;
      margin: 0;
      line-height: 1.2;
      font-size: 16px;
      font-weight: 600;
    }

    .symbol {
      margin: 0 2px 0 0;
      font-size: 14px;
      color: #68768b;
      line-height: 1.1;
      letter-spacing: -0.01em;
    }

    .line {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: 57px;
      margin: auto;
    }
  }

  .newPrice {
    display: flex;
    align-items: center;
    font-family: $headings-font-family;
    font-size: 40px;
    line-height: 1.2;
    color: #3ceac1;
    word-break: break-all;

    @media (max-width: 600px) {
      font-size: 40px;
    }

    .cents {
      align-self: self-end;
      margin: 0;
      line-height: 1.5;
      font-size: 24px;
      font-weight: 600;
      color: #3ceac1;
    }

    .symbol {
      margin: 0 4px 0 0;
      font-size: 20px;
      color: #68768b;
      line-height: 1.1;
      letter-spacing: -0.01em;
    }
  }

  .text {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 1.425;
    letter-spacing: -0.01em;
    color: #778b87;

    @include layout-flip {
      margin: 0 0 32px;
    }
  }

  .button {
    width: 100%;
    margin: 0;
    padding: 20px 47px;
    transition: all 0.3s ease 0s;

    &:not(:disabled):hover {
      opacity: 0.9;
      box-shadow: 0 0 10px 1px rgba(#3ceac1, 0.5);
    }

    &:disabled {
      color: #6e7890;
      background: #eef3fb;
      border-color: #eef3fb;
    }
  }

  .warning {
    margin: 16px 0 0;
    font-size: 13px;
    line-height: 1.1;
    text-align: center;
    color: #68768b;
  }
}
