@import "../../styles/variables";
@import "../../styles/mixins";

.root {
  position: relative;
  display: inline-block;
  width: 177px;
  height: 177px;
  border-radius: 36px;
  margin-bottom: 10px;
  flex-shrink: 0;
  background: $gray-200;

  .icon {
    @include clean-btn;
    position: absolute;
    right: 6px;
    bottom: 6px;
    z-index: 5;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: #3ceac1;
    border-radius: 4px;

    @media (min-width: 600px) {
      display: none;
    }
  }

  .verified {
    position: absolute;
    top: 0;
    right: 0;
    height: auto;

    :global .verified-circle__icon {
      margin: 0;
    }
  }
}

.image {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 36px;

  @media (max-width: 430px) {
    width: 61px;
    height: 61px;
  }
}

.badge {
  position: absolute;
  bottom: -8px;
  right: -16px;
}
