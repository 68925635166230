@import "../../../../../../common/styles/mixins";

.editSpecialtiesInner {
  max-width: 804px;
}

.editSpecialtiesButton {
  max-width: 386px;
  width: 100%;
}

.editSpecialtiesRow {
  @include layout-flip {
    margin: 0 0 16px;
    padding: 0;

    & + .editSpecialtiesRow {
      margin: 0 0 32px;
    }
  }
}

.changeEmail {
  margin: auto 0 7px;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    .changeEmailText {
      border-bottom: 1px solid transparent;
    }

    .changeEmailIcon {
      background: #22c7a0;
      box-shadow: none;
    }
  }

  @include layout-flip {
    position: static;
    margin: 20px 0 0;
    right: 0;
    top: 0;
  }
}

.changeEmailIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 44px;
  height: 44px;
  background: #3ceac1;
  box-shadow: 0 10px 15px rgba(69, 199, 184, 0.31);
  border-radius: 50px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out 0s;
}

.changeEmailText {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.1;
  border-bottom: 1px solid;
  color: #26d5b6;
  transition: all 0.3s ease-in-out 0s;
}

.search :global .search {
  margin: 0;
}
