@import "../../styles/variables";
@import "../../styles/mixins";

.listingHeader {
  width: 100%;
  background: #f1f7ff;

  @include layout-flip {
    position: fixed;
    top: 0;
    z-index: 1000;
  }

  .landingHeader {
    max-width: 1234px;
    padding: 24px 0;

    @include xl {
      padding: 24px;
    }
  }
}

.hideHeader {

  @include layout-flip {
    z-index: 0;
  }
}
