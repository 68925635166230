@import "../../styles/variables";
@import "../../styles/mixins";

.root {
  @include clean-btn;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-height: 39px;
  margin: 0 0 0 32px;
  padding: 9px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  border-radius: 6px;

  @media (max-width: 1350px) {
    margin: 0 16px 0 0;
  }

  &.free {
    color: $primary;
    background-color: $mint;
    border: 1px solid $primary;

    &:hover,
    &:active {
      color: $primary;
      background: #b5f4eb;
      border: 1px solid $primary;
    }
  }

  &.premium {
    color: #d89d03;
    background: #ffe3aa;
    border: 1px solid #ffe3aa;

    &:hover,
    &:active {
      color: #d89d03;
      background: #ffda79;
      border: 1px solid #ffda79;
    }
  }

  svg {
    margin: -2px 10px 0 0;

    path {
      fill: #d89d03;
    }
  }
}
