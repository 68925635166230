@import "../../../../common/styles/mixins";

.cardBody {
  border: 1px solid #d4e1f2;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  background: white;
  margin-bottom: 24px;

  @include layout-flip {
    background: none;
    border: none;
  }
}

.cardSectionTitle {
  width: 100%;
  margin: 0;
  padding: 10px 16px;
  font-weight: 400;
  font-family: $headings-font-family;
  font-size: 20px;
  line-height: 1.2;
  background: #f4f8fc;

  @include layout-flip {
    margin: 0 0 24px;
    padding: 0;
    background: none;
  }
}

.cardSectionSubtitle,
.cardSectionDescription {
  margin: 0 0 16px;
  font-weight: 400;

  @include layout-flip {
    margin: 0 0 10px;
  }
}

.cardSectionSubtitle {
  font-size: 16px;
  line-height: 19px;
}

.cardSectionDescription {
  font-size: 14px;
  line-height: 16px;
  color: #68768b;
}

.cardSection {
  padding: 24px 16px;

  @include layout-flip {
    padding: 0;

    &:not(:last-of-type) {
      margin: 0 0 21px;
    }
  }
}

.financeRangeInput {
  max-width: 116px;

  @include layout-flip {
    max-width: 100%;
  }
}

.financeTypeLabel {
  margin-top: 34px;
  margin-bottom: 26px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  @include layout-flip {
    margin: 0 0 13px;
  }
}

.financeType {
  flex-wrap: wrap;
  max-width: 100%;

  @include layout-flip {
    flex-direction: column;

    & > label {
      width: 100%;
    }
  }
}
