@import "../../../../../../common/styles/mixins";

.contactTitle {
  margin: 0 0 48px;
  padding: 0;
  font-family: $headings-font-family;
  font-size: 34px;
  line-height: 1.3;
  color: $secondary;

  @include layout-flip {
    display: none;
  }
}

.contactInner {
  padding: 40px;
  background: #ffffff;
  border: 1px solid $gray-200;
  border-radius: 10px;

  @media (min-width: 1366px) {
    display: flex;
  }

  @include layout-flip {
    padding: 3px 0 0;
    border: none;
  }
}

.contactInfo {
  display: flex;
  flex-wrap: wrap;
  max-width: 950px;

  @include layout-flip {
    flex-direction: column;
  }
}

.contactBlock {
  flex: 0 0 50%;
  margin: 0 0 40px;
  padding: 0 20px 0 0;

  &:nth-child(n + 5) {
    flex: 0 0 100%;
  }

  &:last-child {
    .contactBlockText {
      max-width: 453px;
    }
  }

  @include layout-flip {
    margin: 0 0 24px;
    padding: 0;

    .contactBlockTex {
      max-width: 100%;
    }
  }
}

.contactBlockTitle {
  margin: 0 0 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: $secondary;

  @include layout-flip {
    margin: 0 0 10px;
  }
}

.contactBlockText {
  max-width: 346px;
  margin: 0;
  padding: 0;
  font-size: 17px;
  line-height: 1.2;
  color: $gray-700;

  @include layout-flip {
    max-width: 100%;
  }
}

.contactButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 72px;
  max-width: 292px;
  width: 100%;
  margin: auto 12% auto auto;

  svg {
    margin: -2px 10px 0 0;
  }

  @media (max-width: 1366px) {
    margin: 0;
  }

  @include layout-flip {
    display: none;
  }
}
