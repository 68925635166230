@import "../../styles/mixins";

.root {
  position: relative;

  img.pdf {
    cursor: pointer;
    object-fit: fill;
  }

  .icon {
    @include clean-btn;
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 5;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: #3ceac1;
    border-radius: 4px;

    @media (min-width: 600px) {
      display: none;
    }
  }
}
