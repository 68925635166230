.container {
  position: relative;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
}

.input {
  padding: 19px 16px 19px 46px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: block;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d4e1f2;
  box-sizing: border-box;
  border-radius: 4px;
  color: #1f342f;
  outline: none;
  transition: all 0.3s ease-in-out 0s;

  &:hover,
  &:focus {
    border: 1px solid #4adccc;
  }
}

:global {
  body {
    .pac-container {
      margin-top: 8px;
      padding: 6px 0 6px 0;
      background: #ffffff;
      border: 1px solid #d4e1f2;
      box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);
      border-radius: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #6e7891;
      box-sizing: content-box;
      &.pac-logo {
        &:after {
          // content: "Not in this list? ";
          content: "";
          position: absolute;
          bottom: 30px;
          right: 166px;
          font-weight: 500;
          height: auto;
          color: #26d5b6;
          padding: 0;
          background: none;
        }

        &:before {
          // content: "Add your address";
          content: "";
          position: absolute;
          bottom: 30px;
          right: 32px;
          text-decoration: underline;
          font-weight: 500;
          color: #26d5b6;
        }
      }
      .pac-item {
        padding: 26px 20px;
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #6e7891;
        cursor: pointer;
        &:hover {
          background: #f3fefc;
        }
      }
      .pac-icon {
        background: url("../../../assets/images/icons/map-marker.svg") !important;
        width: 24px;
        height: 24px;
        margin: 0 10px 0 0;
        vertical-align: middle;
      }
      .pac-item-query {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #6e7891;
      }
    }
  }
}
