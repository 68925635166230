.layout {
  margin: 0 auto;
}

.root {
  display: flex;
  align-items: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 380px;
    border: 1px solid #D4E1F2;
    padding: 32px 53px;

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 66px;
      height: 66px;
      background: #DEFAF6;
      border-radius: 50px;
    }

    .caption {
      font-size: 16px;
      line-height: 19px;
      color: #68768B;
    }

    h3 {
      margin: 16px 0 0;
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 33px;
      color: #1F342F;
    }

    .changeNumberWrapper {
      display: flex;

      span:first-child {
        margin: 0 14px 16px 0;
      }
    }

    .timerText {
      margin: 10px 0 0 ;
      font-size: 16px;
      line-height: 19px;
      color: #68768B;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 16px 0;

    .formButton {
      padding: 10px 40px;
    }
  }

  .container {
    gap: 15px;
    width: fit-content;
  }

  .character {
    border: 1px solid #D4E1F2;
    border-radius: 6px;
    width: 42px;
    height: 50px;
    font-size: 16px;
    line-height: 45px;
    flex-basis: auto;
    flex-grow: unset;
  }

  .inactive {
    background-color: inherit;
  }

  .selected {
    border: none;
    outline: 2px solid #3ceac1;
  }

  .error {
    outline: 2px solid red;
  }

  .errorText {
    font-size: 16px;
    line-height: 19px;
    color: #FE4949;
  }

  .resendLink {
    margin: 50px 0 0;
  }

  .withError {
    margin: 0;
  }

  span {
    cursor: pointer;
    color: #26d4b6;
    font-weight: 500;
  }

  .disabled {
    pointer-events: none;
    margin: 20px 0 0;

    span {
      color: #68768B;
      opacity: 0.5;
    }
  }
}