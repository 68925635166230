@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.root {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 16px;
  white-space: nowrap;
  margin-bottom: 16px;
  @include clean-btn;

  @include layout-flip {
    flex-direction: column;
    margin-right: 8px;
  }
}

.icon {
  margin-right: 10px;

  @include layout-flip {
    margin-right: 0;
    margin-bottom: 3px;
    width: 20px;
    height: 20px;
  }
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: $accent-color;

  @include layout-flip {
    font-size: 10px;
    line-height: 12px;
  }
}
