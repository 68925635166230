@import "../../../../../../common/styles/mixins";

.editSpecialtiesInner {
  max-width: 804px;
}

.editSpecialtiesButton {
  max-width: 386px;
  width: 100%;
}

.editSpecialtiesRow {
  @include layout-flip {
    margin: 0 0 16px;
    padding: 0;

    & + .editSpecialtiesRow {
      margin: 0 0 32px;
    }
  }
}
