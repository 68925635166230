@import "../../../../../common/styles/mixins";

.root {
  max-width: 1340px;
  margin: 100px auto 0;
  padding: 0 0 150px;

  @include layout-flip {
    padding: 0 24px 60px;
  }

  .title {
    text-align: center;
    margin: 0 0 50px;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.1;
    color: #000000;

    @include layout-flip {
      font-size: 33px;
      margin: 0 0 32px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin: 0;

    @include layout-flip {
      margin: 0 0 25px;
    }
  }

  .column {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 0 0 32px;
    padding: 0;
    list-style: none;

    a {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      max-width: 600px;
      width: 100%;
      margin: 0 32px 32px 0;
    }

    @include layout-flip {
      margin: 0;
    }
  }

  .image {
    margin: 0 0 25px;
    object-fit: cover;
    border-radius: 20px;
  }

  .lead {
    margin: 0 0 15px;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 26px;
    line-height: 1.1;
    color: #000000;
  }

  .date {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.1;
    color: #68768b;

    @include layout-flip {
      font-size: 10px;
    }
  }

  .desc {
    margin: 25px 0 0;
    padding: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1;
    color: #68768b;
  }

  .columnItem {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    max-width: 600px;
    width: 100%;

    a {
      width: 100%;
    }

    @include layout-flip {
      width: 100%;
      align-items: center;
      flex-direction: row;

      a {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: row;
      }
    }
  }

  .categoryLinks {
    display: flex;

    a {
      max-width: unset;
      width: unset;
      margin: 10px 5px 0 0;
      align-items: unset;

      @include layout-flip {
        margin: 0 5px 0 0;
      }
    }
  }

  .categoryLink {
    p {
      display: block;
      margin: 16px 0 0 0;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 1.1px;
      color: #68768b;
      text-decoration: underline;
      text-underline-offset: 2px;

      @include layout-flip {
        font-size: 10px;
        margin: 10px 0 0;
      }
    }
  }

  .columnImage {
    width: 100%;
    max-width: 600px;
    height: 340px;
    margin: 0 0 32px 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }

    @include layout-flip {
      width: 115px;
      height: 115px;
      min-width: 115px;
      margin: 0 15px 0 0;
    }
  }

  .columnTitle {
    margin: 0 0 30px;
    padding: 0;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 26px;
    line-height: 1.1;
    color: #000000;

    @include layout-flip {
      margin: 0 0 10px;
      font-size: 20px;
      word-break: break-word;
    }
  }

  .captions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;

    @include layout-flip {
      flex-direction: column;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 225px;
    width: 100%;
    margin: 0 auto;
    padding: 25px 20px 22px;
    text-align: center;
    border-radius: 10px;
    transition: all 0.3s ease-in-out 0s;

    @include layout-flip {
      max-width: none;
      padding: 18px 47px;
    }
  }

  .author {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.1px;
    color: #68768b;

    @include layout-flip {
      margin: 5px 0 0 0;
    }

    p {
      margin: 0;

      @include layout-flip {
        font-size: 10px;
      }
    }

    svg {
      width: 20px;
      height: 20px;
      margin: 0 10px 0 0;

      @include layout-flip {
        width: 12px;
        height: 12px;
        margin: 0 5px 0 0;
      }

      path {
        fill: #68768b;
      }
    }
  }
}
