@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.header {
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  @include layout-flip {
    margin-bottom: 30px;
  }
}

.title {
  margin: 0;
  font-family: $headings-font-family;
  font-size: 34px;
  line-height: 43px;
  font-weight: normal;
  color: #1d3b34;

  @include layout-flip {
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.25px;
  }
}

.surveyInstruction {
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: 8px;
  padding: 24px 16px;

  @include media-breakpoint-up(sm) {
    padding: 32px 32px 36px;
  }
}

.rowButtons {
  display: flex;
  gap: 24px 16px;

  @include layout-flip {
    flex-direction: column-reverse;
  }
}
