@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.container {
  display: "flex";
  justify-content: "space-between";
  width: "400px";
  min-height: "50px";
  background: "white";
  padding: "1em";
  border-radius: "3px";
  box-shadow: "0 12px 24px 4px rgba(40;42;43;0.2)";
}

.contentContainer {
  display: "flex";
  line-height: 1.8;
}

.iconContainer {
  display: "flex";
  padding: "0 1.3em 0 0.3em";
  transform: "translateY(3px)";
}

.headline {
  font-weight: "bold";
}

.error {
  border-left: "4px solid #D61F1F";
}

warning {
  border-left: "4px solid #E46216";
}

.info {
  border-left: "4px solid #0263e0";
}
