@import "../../styles/mixins";

.cardRadioWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 16px;

  @include layout-flip {
    gap: 8px;
  }
}

.cardRadioOption {
  opacity: 0;
  position: absolute;
  z-index: -2;

  & + label {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 100%;
    max-width: 240px;
    height: 56px;
    max-height: 56px;
    min-height: 56px;
    border-radius: 4px;
    background: white;
    border: 1px solid #d4e1f2;
    color: #68768b;
    font-size: 16px;
    line-height: 19px;
    transition: all ease-in-out 200ms;
    cursor: pointer;
    min-width: max-content;
    transition: all 0.3s ease-in-out 0s;

    @include layout-flip {
      max-width: 100%;
    }

    &:hover,
    &:focus {
      border: 1px solid #4adccc;
    }
  }

  &:checked + label {
    background: #defaf6;
    border-color: #3ceac1;
    color: #26d4b6;
  }

  &:disabled + label {
    cursor: default;
  }
}
