@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.root {
  margin: -32px 0 0;

  @include layout-flip {
    margin: 13px 0 0;
  }

  .addButton {
    margin: 0 0 48px;
    padding: 0;

    @include layout-flip {
      margin: 0 0 30px;
    }
  }

  .item {
    max-width: 818px;
    margin: 0 0 24px;

    &:last-of-type {
      margin: 0 0 48px;
    }

    @include layout-flip {
      margin: 0 0 24px;
    }
  }
}

.hint {
  @include layout-flip {
    display: none;
  }
}

//.root {
//  .row {
//    max-width: 387px;
//  }
//
//  .withoutLabel {
//    margin: auto 0 0;
//  }
//
//  .radio {
//    border: 0;
//    padding: 0;
//
//    &:not(:last-child) {
//      margin-right: 16px;
//
//      @media all and (max-width: 1024px) {
//        margin-right: 0;
//        margin-bottom: 16px;
//      }
//    }
//
//    :global .radiobutton__inner {
//      width: 100%;
//      padding: 16px;
//      background: #feffff;
//      border: 1px solid #d4e1f2;
//      border-radius: 4px;
//    }
//
//    input:checked + div {
//      background: #defaf6;
//      border: 1px solid #3ceac1;
//    }
//  }
//}
