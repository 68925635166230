@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.personalInformationMobile {
  h2 {
    font-size: 24px;
    margin: 0 0 25px;
    font-weight: 600;
  }

  h3 {
    margin: 0 0 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: $secondary;
  }

  p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: $gray-700;
  }
}

.editButton {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 120px;
  width: 100%;
  height: 44px;
  padding: 0;

  svg {
    margin: 0 3px 0 0;
  }
}