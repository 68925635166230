@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.personalInformation {
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0 0;
    padding: 24px 20px;
    background: white;
    border: 1px solid $gray-200;
    border-radius: 10px;
  }

  .backdrop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0 0;
    padding: 24px 20px;
    background: white;
    border: 1px solid $gray-200;
    border-radius: 10px;
  }

  .infoField {
    margin: 0 0 40px;

    &:last-of-type {
      margin: 0;
    }
  }

  h3 {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $secondary;
  }

  small {
    display: block;
    margin: 8px 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: $gray-700;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 10px;
    max-width: 120px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    svg {
      width: 24px;
      height: 24px;
      margin: 0 10px 0 0;
      flex-shrink: 0;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 1 auto;

    .infoField {
      width: 50%;
    }
  }
}