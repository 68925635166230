@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins";

.overview {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: -16px;

  @include layout-flip {
    margin: 0;
  }

  .renewalCards {
    @include layout-flip {
      width: 100%;
    }
  }
}
