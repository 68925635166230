@import "../../../../common/styles/_mixins.scss";

.card {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @include layout-flip {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  .text {
    width: 100%;

    .date {
      display: flex;
      margin: 0 0 60px 0;

      @include layout-flip {
        align-items: center;
      }

      .time {
        display: flex;
        margin: 0 0 0 30px;

        @include layout-flip {
          align-items: center;
          order: -1;
          margin: 0 30px 0 0;
        }
      }

      p {
        font-size: 20px;
        line-height: 32px;
        margin: 0 0 0 16px;
        color: #68768b;

        @include layout-flip {
          font-size: 16px;
          margin: 0 0 0 5px;
        }
      }

      svg {
        @include layout-flip {
          width: 25px;
          height: 25px;
        }
      }
    }

    .link {
      display: flex;

      svg {
        margin: 0 0 0 30px;
      }
    }

    a {
      font-size: 20px;
      line-height: 32px;
      color: #3ceac1;
    }

    h2 {
      margin: 0 0 30px;
      font-size: 40px;
      font-weight: 600;
      line-height: 54px;
      color: #1f342f;

      @include layout-flip {
        font-size: 33px;
        line-height: 40px;
      }
    }
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 600px;
    max-height: 400px;
    margin: 0 0 0 32px;

    @include layout-flip {
      max-height: 218px;
      margin: 0 0 32px 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }
}
