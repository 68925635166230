@import "../../../../../../common/styles/variables.scss";
@import "../../../../../../common/styles/mixins";

.circle {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin: 0 8px 0 0;
  border-radius: 11px;
}

.circle.green {
  background: $primary;
}

.circle.yellow {
  background: #ffda79;
}

.circle.red {
  background: #fe4949;
}

.tooltipContent {
  display: grid;
  grid-template-columns: 2fr 6fr 3fr;
  grid-column-gap: 8px;
  width: min(calc(100vw - 400px), 1000px);

  @include layout-flip {
    position: relative;
    display: block;
    width: 100%;
    padding: 46px 24px;
    background: white;
    border-radius: 30px 30px 0 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 44%;
      width: 12%;
      height: 2px;
      margin: 13px 0 0 0;
      background-color: #a7b2cd;
      border-radius: 20px;
    }
  }

  h1 {
    display: none;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.25px;

    @include layout-flip {
      display: inline-block;
    }
  }

  .tooltipColumn {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: $gray-700;

    @include layout-flip {
      margin: 32px 0 0;
    }

    h3 {
      display: inline-block;
      margin: 0 0 8px;
      font-weight: 400;
    }

    p {
      margin: 0;
    }
  }
}
