@import "../../../common/styles/mixins";

.root {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  padding: 60px 0 30px;
  background-color: #ffffff;
  background-image: url("../../../assets/images/backgrounds/landing-background.png");
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;

  @media (min-width: 900px) {
    background-position: center top -150px;
  }

  @media (min-width: 1150px) {
    background-position: center top -100px;
  }

  @media (min-width: 1300px) {
    background-position: center top;
  }

  @include max-bg {
    background-size: 100%;
  }

  @include layout-flip {
    padding: 20px 0 30px;
    background-image: url("../../../assets/images/frames/frame.png");
    background-position: top left;
    background-size: 50%;
  }

  .main {
    max-width: 948px;
    width: 100%;
    margin: 0 auto;
    padding: 25px 24px 0;

    @include layout-flip {
      padding: 20px 24px 0;
    }
  }

  .wrapper {
    @include min-layout-flip {
      position: relative;
      display: flex;
      padding: 0 0 18px;
    }
  }

  .info {
    @include min-layout-flip {
      max-width: 691px;
      width: 100%;
    }
  }

  .specialties {
    max-width: 500px;
    margin: 0 0 16px;
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.1;
    color: #68768b;

    @include layout-flip {
      max-width: 100%;
    }
  }

  .title {
    display: flex;
    align-items: center;
    margin: 0 0 16px;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.1;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;

    @include layout-flip {
      max-width: none;
      margin: 0 0 16px;
      font-size: 34px;
    }
  }

  .verified {
    margin: 0 0 0 32px;

    @include layout-flip {
      margin: 0;
      font-size: 18px;
    }
  }

  .row {
    display: flex;
    margin: 0 0 30px;

    @include layout-flip {
      display: block;
      margin: 0 0 32px;
    }
  }

  .address {
    display: flex;
    align-items: center;

    @include layout-flip {
      margin: 0 0 16px;
      font-size: 18px;
    }
  }

  .addressIcon {
    margin: 0 11px 0 0;
  }

  .addressText {
    font-size: 18px;
    line-height: 1.1;
    color: #68768b;
  }

  .description {
    margin: 0 0 16px;
    font-size: 18px;
    line-height: 1.67;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 20px;
      font-size: 16px;
      line-height: 1.62;
    }
  }

  .phoneRow {
    display: flex;
    align-items: center;
    padding: 0 0 24px;

    @include layout-flip {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0 32px;
    }
  }

  .phone {
    display: flex;
    align-items: center;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 23px;
    line-height: 1.23;
    color: $secondary;

    @include layout-flip {
      margin: 0 0 32px;
      font-size: 19px;
    }

    & + .button {
      margin: 0 0 0 22px;

      @include layout-flip {
        margin: 0;
        padding: 11px 10px;
      }
    }

    .phoneIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 56px;
      height: 56px;
      margin: 0 16px 0 0;
      border-radius: 50%;
      border: 1px solid $gray-200;

      @include layout-flip {
        width: 35px;
        height: 35px;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    .phoneText {
      display: flex;
      flex-direction: column;
    }

    small {
      margin: 5px 0 0;
      font-size: 20px;
      color: #1f342fa6;
      letter-spacing: -1px;
    }

    &.disabled {
      pointer-events: none;

      .phoneIcon {
        filter: grayscale(1);
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 214px;
    width: 100%;
    padding: 11px 10px;
    background: #defaf6;
    border-color: #defaf6;

    &.premium {
      background: $primary;
      border-color: $primary;

      &:hover,
      &:focus {
        background: #22c7a0;
        border: 1px solid #22c7a0;
      }
    }

    &.available {
      background: $primary;
      border-color: $primary;

      &:hover,
      &:focus {
        background: #22c7a0;
        border: 1px solid #22c7a0;
      }
    }

    &.wait {
      background: #ffda79;
      border-color: #ffda79;

      &:hover,
      &:focus {
        background: #ffda79;
        border-color: #ffda79;
      }
    }

    &.offline {
      background: $red;
      border-color: $red;

      &:hover,
      &:focus {
        background: $red;
        border-color: $red;
      }
    }

    svg {
      margin: 0 11px 0 0;
      flex-shrink: 0;
    }
  }

  .avatar {
    width: 160px;
    height: 160px;
    margin: 0 32px 0 0;
    border-radius: 50%;

    @include layout-flip {
      display: block;
      width: 80px;
      height: 80px;
      margin: 0 auto 16px;
    }

    img {
      border-radius: 50%;
    }
  }

  .footer {
    @media (min-width: 1536px) {
      padding: 64px 0 32px;
      background-image: url("../../../assets/images/backgrounds/footer-background-4k.png");
      background-position: bottom;
      background-size: auto;
    }
  }

  .showButton {
    @include clean-btn;
    margin: 0 0 24px;
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.1;
    color: #3ceac1;

    @include layout-flip {
      margin: 0 0 32px;
    }
  }
}
