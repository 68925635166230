@import "~common/styles/variables";

.circleScaleWrapper {
  position: relative;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: $headings-font-family;
  color: #1F342F;
}

.scale {
  fill: none;
  stroke: #D4E1F2;
  stroke-linecap: round;
}

.stroke {
  fill: none;
  stroke: #3CEAC1;
}
