@import "../../../../../../../common/styles/variables";
@import "../../../../../../../common/styles/mixins";

.multi {
  .row {
    display: flex;
    justify-content: space-between;
    width: fit-content;

    @include layout-flip {
      width: 100%;
      padding: 16px;
      background: white;
      border: 1px solid $gray-200;
      border-radius: 10px;
    }

    .item {
      position: relative;
      margin: 0 60px 0 0;
      font-size: 16px;
      line-height: 19px;
      color: $gray-700;

      @include layout-flip {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0 10px 0 0;
        font-size: 13px;
        line-height: 15px;
      }

      &:last-of-type {
        margin: 0;
      }

      .label {
        position: absolute;

        @include layout-flip {
          position: static;
        }
      }

      .checkbox {
        padding: 29px 0 0;

        @include layout-flip {
          padding: 15px 0 0;
        }
      }
    }
  }

  .error {
    margin: 4px 0 0;
    font-size: 13px;
    color: $error;
  }
}
