@import "../../styles/variables";

.root {
  position: relative;
}

.label {
  display: block;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 19px;
  color: $secondary;
}

.textarea {
  font-family: $headings-font-family;
  padding: 16px;
  resize: none;
  width: 100%;
  height: 130px;
  font-size: 16px;
  line-height: 19px;
  outline: none;
  border: 1px solid $gray-200;
  border-radius: 4px;
  color: $gray-700;
  transition: all 0.3s ease-in-out 0s;

  &:hover,
  &:focus {
    border: 1px solid #4adccc;
  }

  &::placeholder {
    color: $gray-700;
  }

  &.--error {
    border-color: $error;
  }
}

.length {
  color: $gray-700;
  margin: 8px 0 0;
  font-size: 13px;
  text-align: end;
}

.error {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 4px 0 0;
  font-size: 13px;
  color: $error;
}

@media all and (max-width: $layout-flip-var) {
  .label {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
  }
}
