@import "../../../../../../common/styles/mixins";

.photosAndVideos {

  .desktopHeader {
    display: flex;
    align-items: flex-start;

    .goBackButton {
      @include clean-btn;
      padding: 15px;
      border-radius: 6px;
      background: $gray-200;
      margin: 0 20px 0 0;

      @include layout-flip {
        background: none;
      }

      .angle {
        width: 10px;
        height: 10px;
        transform: translateX(3px) rotate(45deg);
        border: 2px solid $secondary;
        border-color: transparent transparent $secondary $secondary;

        @include layout-flip {
          border-color: transparent transparent $gray-700 $gray-700;
        }
      }
    }

    h1 {
      margin: 0 0 48px;
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      line-height: 43px;
      color: $secondary;

      @include layout-flip {
        display: none;
      }
    }
  }

  .noVideoPhoto {
    font-size: 16px;
    line-height: 24px;
    color: #1F342F;
  }

  .videoPart {
    width: 50%;

    &:first-of-type {
      padding: 0 90px 0 0;

      @include layout-flip {
        padding: 0;
      }
    }

    @include layout-flip {
      width: 100%;
    }

    .dropFileArea button {
      width: 100%;
    }
  }

  .backdrop {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 48px;
    padding: 40px;
    background: white;
    border: 1px solid $gray-200;
    border-radius: 10px;

    .caption {
      font-size: 16px;
      line-height: 24px;
      color: #68768B;
    }

    .part {
      width: 50%;

      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 34px;
        letter-spacing: 0.25px;
        color: $secondary;
        margin: 0 0 40px 0;
      }

      @include layout-flip {
        width: 100%;
      }
    }

    @media (max-width: 1250px) {
      padding: 20px;
    }

    @include layout-flip {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 24px;
      padding: 0;
      background: none;
      border: none;
    }

    &:last-of-type {
      margin: 0;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0 0 24px;

      h2 {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 34px;
        letter-spacing: 0.25px;
        color: $secondary;
      }
    }

    .photos {
      display: flex;
      flex-wrap: wrap;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.2;
      color: $gray-700;
      margin: 0 60px 0 0;


      @media (max-width: 1250px) {
        margin: -8px;
        width: 300px;
      }

      @media (max-width: 1050px) {
        width: 220px;
      }

      @include layout-flip {
        width: 100%;
        margin: 0;
      }

      img {
        width: 134px;
        height: 134px;
        object-fit: cover;
        border-radius: 20px;

        @media (max-width: 1250px) {
          width: 90px;
          height: 90px;
          margin: 8px;
        }

        @include layout-flip {
          width: 80px;
          height: 80px;
          margin: 0;
        }
      }

      .photo {
        cursor: pointer;
        position: relative;
        margin: 0 32px 32px 0;

        @include layout-flip {
          margin: 0 28px 28px 0;
        }

        .photoIcon {
          position: absolute;
          top: -3px;
          right: 10px;
        }

        & .mainPhoto {
          pointer-events: none !important;
        }
      }
    }

    .videos {
      display: flex;
      align-items: flex-start;
      flex-grow: 1;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.2;
      color: $gray-700;

      .dropContainer {
        margin: 0 0 32px;
        width: 100%;

        @include layout-flip {
          height: 50%;
          margin: 32px 0 16px;

          input + div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 67vh;

            p {
              display: none;
            }
          }
        }
      }

      .fileUploadActions {
        @include layout-flip {
          flex-direction: column-reverse;
          margin-top: 8px;

          button {
            flex: 0 0 100%;
            width: 100%;

            & + button {
              margin: 0 0 16px;
            }
          }
        }
      }

      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 34px;
        letter-spacing: 0.25px;
        color: #1f342f;
        margin: 0 0 40px 0;
      }

      .player {
        width: 100%;
        border-radius: 20px;
        overflow: hidden;

        @media (max-width: 1550px) {
          max-width: 500px;
          width: 100%;
        }

        @media (max-width: 1250px) {
          width: 300px;
          height: 300px;
        }

        @media (max-width: 1050px) {
          width: 200px;
        }

        @include layout-flip {
          width: calc(100vw - 44px);
          height: auto;
        }
      }
    }

    .startRecordingBtn {
      display: flex;
      align-items: center;
      padding: 0 16px;
      @include restrict-prop(height, 48px);
    }

    .btn {
      display: flex;
      align-items: center;
      margin: auto 10% auto auto;

      @media (max-width: 1366px) {
        margin: 0;
      }

      svg {
        width: 24px;
        height: 24px;
        margin: 0 10px 0 0;

        @include layout-flip {
          margin: 0;
        }
      }

      @include layout-flip {
        margin: 0;
        padding: 13px 34px;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.01em;
      }
    }
  }
}