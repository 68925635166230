@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";


.title {
    margin: 0 0 22px;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: $secondary;
  }