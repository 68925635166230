@import "../../../../../../common/styles/mixins";

.videosEdit {
  max-width: 798px;
  width: 100%;

  .moveOnBtn {
    @include round-btn(60px);
    background: #3ceac1;

    &:disabled {
      background: #26d5b6;
    }
  }

  .startRecordingBtn {
    display: flex;
    align-items: center;
    padding: 0 16px;
    @include restrict-prop(height, 51px);
  }

  .dropContainer {
    margin: 0 0 32px;
    width: 100%;

    @include layout-flip {
      height: 50%;
      margin: 32px 0 16px;

      input + div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 67vh;

        p {
          display: none;
        }
      }
    }
  }

  .fileUploadActions {
    @include layout-flip {
      flex-direction: column-reverse;
      margin-top: 8px;
      width: 100%;

      button {
        display: flex;
        justify-content: center;
        flex: 0 0 100%;
        width: 100%;

        & + button {
          margin: 0 0 16px;
        }
      }
    }
  }

  .saveButton {
    max-width: 386px;
    width: 100%;

    @include layout-flip {
      max-width: none;
    }
  }
}
