@import "../../common/styles/variables";
@import "../../common/styles/mixins";

.root {
  background-image: url("../../assets/images/backgrounds/about-background.png");
  background-repeat: no-repeat;
  background-position: center top;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include max-bg {
    background-size: 100%;
  }

  @include layout-flip {
    background-image: url("../../assets/images/backgrounds/about-mobile-background.png");
  }

  @media (min-width: 1536px) {
    background-image: url("../../assets/images/backgrounds/about-background-4k.png");
    background-size: auto;
  }

  .image {
    width: 600px;
    max-width: 50%;

    @include layout-flip {
      width: 100%;
    }
  }

  .inner {
    position: relative;
    max-width: 1334px;
    width: 100%;
    margin: 0 auto;
    padding: 54px 50px 0;

    @include layout-flip {
      padding: 32px 24px 0;
    }
  }

  .logo {
    display: block;
    width: fit-content;
    margin: 0 auto 68px;

    @include layout-flip {
      margin: 0 auto 30px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 140px;

    @include layout-flip {
      align-items: center;
      flex-direction: column-reverse;
      margin: 0 0 80px;
    }
  }

  .column {
    max-width: 530px;

    @include layout-flip {
      max-width: none;
    }
  }

  .title {
    margin: 0 0 40px;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.1;
    color: #000000;

    @include layout-flip {
      margin: 0 0 25px;
      font-size: 35px;
    }
  }

  .text {
    max-width: 530px;
    margin: 0 0 60px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: $gray-700;

    @include layout-flip {
      margin: 0 0 25px;
      font-size: 16px;
      line-height: 1.25;
    }
  }

  .button {
    max-width: 250px;
    width: 100%;

    @include layout-flip {
      max-width: 100%;
    }
  }

  .benefitsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 896px;
    margin: 0 auto 136px;
    padding: 0 50px;

    @include layout-flip {
      flex-direction: column;
      margin: 0 0 33px;
    }
  }

  .benefitsColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include layout-flip {
      &:not(:last-child) {
        margin: 0 0 70px;
      }
    }
  }

  .benefitsIcon {
    margin: 0 0 20px;
  }

  .benefitsTitle {
    margin: 0 0 5px;
    padding: 0;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.1;
    color: #000000;

    @include layout-flip {
      margin: 0 0 10px;
      font-size: 35px;
    }
  }

  .benefitsText {
    margin: 0;
    padding: 0;
    max-width: 125px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1;
    color: #68768b;

    @include layout-flip {
      font-size: 16px;
    }
  }
}
