@import "../../../../../common/styles/mixins";

.root {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -140px;
    width: 3000px;
    height: 1025px;
    transform: translateX(-50%);
    background-image: url("../../../../../assets/images/backgrounds/therapist-submit-background.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100%;

    @include layout-flip {
      bottom: -120px;
      width: 901px;
      height: 560px;
      background-image: url("../../../../../assets/images/backgrounds/mobile-submit-background.png");
    }

    @media (min-width: 1536px) {
      background-image: url("../../../../../assets/images/backgrounds/therapist-submit-background-4k.png");
      width: 5120px;
      height: 1213px;
      height: 1213px;
      bottom: -335px;
      transform: translateX(-50%);
    }
  }



  .inner {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 1604px;
    margin: 0 auto;
    padding: 258px 50px 227px;

    @include layout-flip {
      flex-direction: column-reverse;
      padding: 25px 24px 80px;
    }
  }

  .info {
    flex-shrink: 0;
    max-width: 641px;

    @media (max-width: 1100px) {
      flex-shrink: 1;
    }
  }

  .title {
    margin: 0 0 8px;
    padding: 0;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    color: #1f342f;

    @include layout-flip {
      margin: 0 0 5px;
      font-size: 33px;
    }
  }

  .text {
    margin: 0 0 35px;
    padding: 0;
    font-weight: 400;
    font-size: 25px;
    line-height: 1.36;
    letter-spacing: 0.01em;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 25px;
      font-size: 16px;
    }
  }

  .button {
    max-width: 230px;
    width: 100%;

    @include layout-flip {
      max-width: none;
      padding: 18px 47px;
    }
  }

  .image {
    position: absolute;
    top: 0;
    right: 20px;
    width: 787px;

    @media (max-width: 1440px) {
      position: static;
      top: auto;
      right: auto;
      width: 35%;
    }

    @include layout-flip {
      width: 100%;
      margin: 0 0 25px;
    }
  }
}
