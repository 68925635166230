@import "../../styles/variables";

.root {
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.16px;
  margin: 16px 0;
  color: $gray-700;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & + & {
    margin-top: 8px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.licenseIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 8px;
}
