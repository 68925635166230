@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.editPersonalBilling {
  max-width: 806px;
  width: 100%;

  @include layout-flip {
    max-width: none;
  }

  &__fullWidth {
    grid-column: 1 / -1;
  }

  .editHeader {
    display: flex;
    align-items: center;
    margin: 0 0 48px;

    button {
      margin: 0 16px 0 0;
    }

    h1 {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      line-height: 43px;
      color: $secondary;
    }
  }

  .mainForm {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 32px;

    @include layout-flip {
      grid-template-columns: repeat(1, auto);
      grid-gap: 20px;
    }

    button {
      margin: 0;
      line-height: 19px;
      grid-column: 1;
    }
  }

  .phone {
    margin: 0;
  }

  .row {
    position: relative;

    .changeEmail {
      display: flex;
      align-items: center;
      margin: 13px 0 0 0;
      padding: 0;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;

      &:hover,
      &:focus {
        .changeEmailText {
          border-bottom: 1px solid transparent;
        }

        .changeEmailIcon {
          background: #22c7a0;
          box-shadow: none;
        }
      }

      @include layout-flip {
        position: static;
        margin: 20px 0 0;
        right: 0;
        top: 0;
      }
    }

    .changeEmailIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      width: 44px;
      height: 44px;
      background: #3ceac1;
      box-shadow: 0 10px 15px rgba(69, 199, 184, 0.31);
      border-radius: 50px;
      margin-right: 10px;
      transition: all 0.3s ease-in-out 0s;
    }

    .changeEmailText {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.1;
      border-bottom: 1px solid;
      color: #26d5b6;
      transition: all 0.3s ease-in-out 0s;
    }
  }
}