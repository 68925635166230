@import "../../../../../../common/styles/mixins";

.editCredentialsTypeHeader {
  justify-content: space-between;
  max-width: 780px;

  h1 {
    margin: 0 auto 0 16px;
  }

  @include layout-flip {
    display: none;
  }
}

.editCredentialsTypeInner {
  max-width: 387px;
}

.editCredentialsTypeButton {
  margin: 16px 0 0;
  width: 100%;
}
