@import "common/styles/mixins";

.table {
  width: 100%;
}

.tableHeader {
  @include layout-flip {
    display: none;
  }
}

.tableHead {
  flex: 0 0 20%;
  padding: 0 10px 32px;
  text-align-last: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  letter-spacing: 0.04em;
  color: $gray-700;

  &.sortable {
    display: flex;
    align-items: center;

    svg {
      margin: auto auto auto 8px;
      flex-shrink: 0;
    }
  }
}

.tableLine {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 -10px;
  padding: 0 40px;

  @include layout-flip {
    display: grid;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ccdaee;

    &:not(:last-child) {
      margin: 0 0 24px;
    }
  }
}

.tableBody {
  display: flex;
  flex-direction: column;
  box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);
  background: #ffffff;
  border-radius: 10px;

  @include layout-flip {
    background: none;
    box-shadow: none;
  }

  tr {
    &:last-of-type {
      td {
        border-bottom: none;
      }
    }
  }
}

.tableCell {
  display: flex;
  align-items: center;
  flex: 0 0 20%;
  min-height: 122px;
  padding: 0 10px;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #6e7891;
  border-bottom: 1px solid $gray-200;

  @include layout-flip {
    width: 100%;
    min-height: auto;
    margin: 0 0 16px;
    padding: 0;
    border: none;
  }

  a {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }
}

.tableButton {
  max-width: 176px;
  width: 100%;
  margin: 0 0 0 auto;
  padding: 15px 5px;

  @include layout-flip {
    max-width: 100%;
    margin: 0 0 8px;
  }
}

.tableImage {
  width: 52px;
  height: 52px;
  margin: 0 16px 0 0;
  border-radius: 15px;
  overflow: hidden;
  object-fit: cover;
}

.tableCellImage {
  font-family: $headings-font-family;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.1;
  color: #1f342f;

  @include layout-flip {
    margin: 0 0 16px;
  }
}

.tableCellCost {
  @include layout-flip {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
  }
}

.spinner {
  text-align: center;
}

.empty {
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: -0.01em;
  color: $gray-700;
}

.deletedUser {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #6e7891;
}
