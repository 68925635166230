@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

$blueish-white: #f4f8fc;

@media all and (max-width: 1000px) {
  .messageTherapist {
    .leftSide {
      background-size: 115%;
    }
  }
}

.messageTherapist {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // height: 860px;
  overflow: hidden;

  @include layout-flip {
    display: block;
    height: auto;
    border-radius: 30px 30px 0 0;
  }
}

.popupCloseButton {
  position: absolute;
  right: 0;
  margin: 22px;
  background: none;
  border: none;
  cursor: pointer;

  @include layout-flip {
    display: none;
  }
}

.leftSide {
  background-color: $blueish-white;
  // background-image: url("../../../../../assets/images/email-to-therapist.png");
  background-position: 0 100%;
  background-repeat: no-repeat;

  @include layout-flip {
    background-image: none;
  }

  .tabs {
    padding: 20px 36px;

    @include layout-flip {
      display: none;
    }

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        height: 38px;
        margin: 0 50px 0 0;
        font-family: $headings-font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: $secondary;
        cursor: pointer;

        &:last-of-type {
          margin: 0;
        }

        &.selectedTab::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 3px;
          background: $green-lighter;
          box-shadow: 0 30px 30px rgba(69, 199, 184, 0.19);
          border-radius: 20px;
        }
      }
    }
  }

  .messageTo {
    display: flex;
    align-items: center;
    padding: 30px 36px;
    background-color: $blueish-white;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;

    @include layout-flip {
      padding: 32px 24px 15px 24px;
      background-color: #f1f7ff;
      background-image: none;
      border-bottom: none;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 44%;
        width: 12%;
        height: 2px;
        margin: 13px 0 0 0;
        background-color: #a7b2cd;
        border-radius: 20px;
      }
    }

    img {
      width: 78px;
      height: 78px;
      border-radius: 15px;
      object-fit: cover;

      @include layout-flip {
        width: 50px;
        height: 50px;
      }
    }

    .name {
      flex-grow: 1;
      margin: 0 16px;

      @include layout-flip {
        margin: 0 13px;
      }

      h4 {
        margin: 0 0 8px 0;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: $gray-700;

        @include layout-flip {
          margin-bottom: 0;
        }
      }

      p {
        margin: 0;
        font-family: $headings-font-family;
        color: #1f342f;
        font-size: 24px;
        line-height: 30px;

        @include layout-flip {
          font-size: 17px;
          line-height: 34px;
          letter-spacing: 0.25px;
        }
      }
    }

    .switchButton {
      display: none;

      @include layout-flip {
        display: block;
        padding: 12px;
        font-style: normal;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.01em;
      }
    }
  }

  .description {
    height: 20%;
    padding: 36px;

    @include layout-flip {
      display: none;
    }

    h1 {
      margin: 0 0 16px 0;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color: $secondary;
    }

    p {
      max-width: 350px;
      margin: 0;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: $gray-700;
    }
  }
}

.rightSide {
  display: flex;
  flex-direction: column;
  padding: 64px;
  background: #ffffff;

  @include layout-flip {
    padding: 20px 24px;
  }

  .offlineWarning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 16px 12px;
    background-color: #f1f7ff;
    border: 1px solid #d4e1f2;
    border-radius: 4px;

    img {
      size: 24px;
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    p {
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      color: $gray-700;
    }
  }

  .phone {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;

    &.offline {
      justify-content: start;

      @include layout-flip {
        justify-content: center;
      }
    }

    @include layout-flip {
      margin-bottom: 16px;
    }

    p {
      margin: 0 10px 0 0;
      font-family: $headings-font-family;
      font-size: 20px;
      line-height: 25px;
    }

    span {
      font-size: 16px;
      line-height: 19px;
      color: $accent-color;
      background: none;
      border: none;
      cursor: pointer;
      text-decoration: $accent-color underline;
    }
  }

  .divider {
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: $gray-700;
  }

  form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .input,
    .captchaContainer {
      margin-bottom: 16px;

      @include layout-flip {
        margin-bottom: 13px;
      }
    }

    .message {
      input {
        height: 89px;
      }
    }

    .captchaContainer {
      .captcha {
        width: 307px;
        height: 81px;
        padding: 3px 0 0 3px;

        &.error {
          width: fit-content;
          outline: 1px solid red;
          border-radius: 4px;
        }
      }

      .errorMessage {
        margin: 8px 0 0;
        font-size: 13px;
        color: red;
      }
    }
  }

  button {
    display: block;
    width: 100%;
    margin-top: auto;
  }
}

@media all and (max-height: 800px) and (min-width: $layout-flip-var) {
  .messageTherapist {
    height: 100%;
    border-radius: 0;
    // overflow-y: scroll;
  }
}

@media all and (max-height: 950px) and (min-width: $layout-flip-var) {
  .messageTherapist.offline {
    height: 100%;
    border-radius: 0;
    // overflow-y: scroll;
  }
}

@media all and (max-width: 1200px) and (min-width: $layout-flip-var) {
  .messageTherapist {
    width: 85%;
  }
}

@media all and (max-height: 800px) {
  .leftSide {
    background-size: 90%;
  }
}

@media all and (max-height: 600px) {
  .messageTherapist {
    border-radius: 20px;

    .leftSide {
      .messageTo {
        padding: 24px 16px 16px;

        p {
          line-height: normal;
        }
      }
    }

    .rightSide {
      padding: 16px;

      .offlineWarning {
        margin: 0 0 12px;
        padding: 8px;

        img {
          margin: 0 8px 0 0;
        }
      }

      .input {
        margin: 0 0 8px;
      }
    }
  }
}
