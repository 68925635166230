.toggle-check {
  position: relative;
  width: 49px;
  height: 28px;
  margin: 0;
  vertical-align: top;
  background: #ffffff;
  border: 1.5px solid #d4e1f2;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  appearance: none;
  transition: all 0.3s ease-in-out 0s;
  &:hover,
  &:focus {
    border: 1.5px solid #b2c3d9;
  }

  &::after {
    content: "";
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translate(0, -50%);
    width: 20px;
    height: 20px;
    background-color: #d4e1f2;
    border-radius: 50%;
  }

  &:checked::after {
    transform: translate(100%, -50%);
    background-color: #fff;
  }

  &:checked {
    background-color: #3ceac1;
    border-color: #3ceac1;
  }

  &:disabled {
    background-color: #fff;
    border-color: #d4e1f2;
  }

  &:disabled::after {
    background-color: #d4e1f2;
  }
}
