.scale {
  width: 206px;

  &__line {
    width: 100%;
    height: 2px;
    border-radius: 10px;
    background: #fff;
  }

  &__depth {
    height: 2px;
    border-radius: 10px;
    background: #3CEAC1;
  }
}
