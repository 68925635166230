@import "../../../styles/variables";
@import "../../../styles/mixins";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 74px 0 16px;

  .mobileNotification {
    margin: 0 auto 0 15px;

    & > button {
      margin: 0;
    }
  }
}

.tabName {
  margin: 0 auto 0 0;
  font-family: $headings-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 1.1;
  letter-spacing: 0.25px;
  color: $secondary;

  &.mainTab {
    margin: 0;
  }
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 19.5px;
    height: 19.5px;
  }
}

.select {
  display: inline-block;
  max-width: 176px;
  width: 100%;

  & > div {
    z-index: 5;
  }
}

.backButton {
  margin: 0 15px -3px 0;
  padding: 0;
  background: none;
  border: 0;

  svg {
    width: 11px;
    height: 17px;

    path {
      fill: $gray-700;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
}

.editButton {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 120px;
  width: 100%;
  height: 44px;
  padding: 0;

  svg {
    margin: 0 3px 0 0;
  }
}
