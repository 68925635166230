.btn {
	&-action-video {
		@include inlineFlexCenter;

		width: 60px;
		height: 40px;
		padding: 5px 10px;
		border-radius: 24px;
		border: 1px solid transparent;
		font-size: 24px;
		cursor: pointer;
		outline: none !important;
		transition: background-color 0.3s, border-color 0.3s, color 0.3s;

		@include media-breakpoint-up(lg) {
			width: 80px;
			height: 48px;
		}

		&.faded {
			background-color: rgba($white, 0.2);
			box-shadow: 0px 0px 20px 0px rgba($black, 0.12);
			color: $white;
			backdrop-filter: blur(15px);
			border-color: rgba($white, 0.2);

			&:not(.audio-off),
			&:not(.video-off) {
				&:hover {
					background-color: rgba($white, 0.5);
				}
			}

			&.audio-off,
			&.video-off {
				background-color: $white;
				border-color: $white;
				color: $red;

				&:hover {
					background-color: $red-faded;
					border-color: $red-faded;
				}
			}
		}

		&.red {
			background-color: $red;
			border-color: $red;
			color: $white;

			&:hover {
				background-color:$red-dark;
				border-color:$red-dark;
			}
		}
	}
}