.root {
  position: relative;
}

.field {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
  padding: 19px 16px;
  background: #ffffff;
  border: 1px solid #d4e1f2;
  box-sizing: border-box;
  border-radius: 4px;

  &.active {
    border: 1px solid #3ceac1;
  }

  &.disabled {
    background: #efefef4d;
  }
}

.input {
  padding: 0;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  border: none;
  background: transparent;
  outline: none;
  color: #1f342f;
  transition: all 0.2s ease-out;

  &::placeholder {
    font-size: 16px;
  }
}

.options {
  position: absolute;
  z-index: 2000;
  right: 0;
  left: 0;
  max-height: 250px;
  margin: 0;
  padding: 6px 0 6px 0;
  background: #ffffff;
  border: 1px solid #d4e1f2;
  box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #6e7891;
  box-sizing: content-box;
  overflow-y: auto;
}

.item {
  background-color: transparent;
  color: inherit;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;

  &:hover {
    color: #ffffff;
    background-color: #2684ff;
  }

  &.active {
    color: #ffffff;
    background-color: #2684ff;
  }
}
