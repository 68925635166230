.no-scroll {
  overflow: hidden !important;
}

.of-contain {
	@include absoluteCover;

	object-fit: contain;
}

.of-cover {
	@include absoluteCover;

	object-fit: cover;
}

.remove-xxl-last-2-child-margin {
	> * {
		&:nth-last-child(-n + 2) {
			@include media-breakpoint-up(xxl) {
				margin-bottom: 0 !important;
			}
		}
	}

	&.row {
		> * {
			&.col,
			&[class^="col-"] {
				&:nth-last-child(-n + 2) {
					> * {
						@include media-breakpoint-up(xxl) {
							margin-bottom: 0 !important;
						}
					}
				}
			}
		}
	}
}

.max-width-150 {
  max-width: 150px !important;
}

.max-width-380 {
  max-width: 380px !important;
}

.w-md-auto {
	@include media-breakpoint-up(md) {
		width: auto !important;
	}
}

.row {
	@include addGridGutter(8);
	@include addGridGutter(12);
	@include addGridGutter(16);
	@include addGridGutter(18);
	@include addGridGutter(20);
	@include addGridGutter(50);

	@include media-breakpoint-up(lg) {
		@include addGridGutter(12, lg);
		@include addGridGutter(40, lg);
	}
}

.link-secondary {
	&:hover {
		color: $primary !important;
	}
}

#bugherd_embed_communication_frame {
  width: 83px !important;
  height: 70px !important;
}

.g-underline {
  cursor: pointer;
  color: #26d4b6;
  font-weight: 500;
  border-bottom: 1px solid;
  transition: all 0.3s ease-in-out 0s;

  &:hover,
  &:focus {
    border-color: transparent;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}