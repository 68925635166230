@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.personalInformation {
  .profileWrapper {
    margin: 0 0 32px;
    padding: 40px;
    background: white;
    border: 1px solid $gray-200;
    border-radius: 10px;

    @include media-breakpoint-down(lg) {
      padding: 0;
      margin-bottom: 16px;
      background: none;
      border: none;
      border-radius: 0;
    }
  }

  .wrapper {
    margin: 40px 0 0;
    padding: 48px 0 0;
    border-top: 1px solid #d4e1f2;

    @include media-breakpoint-down(lg) {
      margin: 16px 0 0;
      padding: 24px 20px;
      background: white;
      border: 1px solid $gray-200;
      border-radius: 10px;
    }
  }

  .infoField {
    margin: 0 0 40px;

    &:last-of-type {
      margin: 0;
    }
  }

  h3 {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: $secondary;

    @include media-breakpoint-down(lg) {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
  }

  small {
    display: block;
    margin: 8px 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: $gray-700;
  }
}

.iconButton {
  display: flex;

  @include media-breakpoint-up(lg) {
    min-width: 292px !important;
  }
}

.fieldsBlock {
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 32px;

  @include media-breakpoint-up(lg) {
    padding: 40px;
  }

  &__innerContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__fieldsCol {
    flex: 1 0 0%;
    width: 100%;
    max-width: 100%;
    padding-right: 30px;
  }

  &__btnCol {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 auto;
    width: 300px;
    max-width: 100%;
  }
}