@import "common/styles/variables.scss";
@import "common/styles/mixins.scss";

.dateAndTimePickerBlock {
  &__totalDateAndTimeInput {
    @include media-breakpoint-up(sm) {
      max-width: 250px;
    }
  }

  &__calendarCol {
    @include media-breakpoint-up(md) {
      border-right: 1px solid $gray-200;
    }

    > div {
      display: flex;
      width: 100%;
    }
  }

  &__timepickerBlock {
    margin-top: 16px;
    border-top: 1px solid $gray-200;
    padding-top: 4px;

    @include media-breakpoint-up(md) {
      margin-top: -8px;
      padding-top: 0;
      border-top: none;
    }
  }

  &__img {
    max-width: 185px;
    display: none;

    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}