@import "common/styles/variables";
@import "common/styles/mixins";

// .videoWrapper {
//   position: relative;
//   height: calc(100vh - 74px);
//   height: calc(100svh - 74px);
//   background-color: $black;
//   color: $white;
//   overflow: hidden;

//   @include min-layout-flip {
//     height: calc(100vh - 84px);
//     height: calc(100svh - 84px);
//     margin-top: 9px;
//   }

//   @include media-breakpoint-up(lg) {
//     height: calc(100vh - 112px);
//     height: calc(100svh - 112px);
//     margin-top: 0;
//   }

//   &__videoBlock {
//     @include flexCenter;

//     position: absolute;
//     top: 50%;
//     left: 0;
//     right: 0;
//     transform: translate(0, -50%);
//     background-color: $olive;
//     padding-bottom: 67%;

//     @include media-breakpoint-up(md) {
//       top: 0;
//       bottom: 0;
//       left: 60px;
//       right: 60px;
//       transform: none;
//       padding-bottom: 0;
//     }

//     @include media-breakpoint-up(lg) {
//       left: 160px;
//       right: 160px;
//     }

//     > video {
//       @include absoluteCover;
//     }

//     > svg {
//       @include absoluteCenter;
//       @include sizeElement(120px);

//       @include media-breakpoint-up(sm) {
//         @include sizeElement(180px);
//       }
//     }
//   }

//   &__actionsBlock {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     width: 100%;
//     z-index: 5;
//   }

//   &__joinBtn {
//     @include media-breakpoint-down(lg) {
//       min-height: 40px;
//       width: 68px;
//       min-width: auto;
//       padding-left: 10px;
//       padding-right: 10px;
//     }
//   }
// }

// .menuBar {
//   padding: 25px 15px;

//   @include media-breakpoint-up(lg) {
//     padding: 30px 40px;
//   }

//   &__leftContent {
//     @include media-breakpoint-down(lg) {
//       min-width: 30px;
//     }
//   }

//   &__rightContent {
//     @include media-breakpoint-down(lg) {
//       min-width: 30px;
//     }
//   }

//   &__nameAndAudio {
//     display: flex;
//     align-items: center;

//     @include media-breakpoint-down(lg) {
//       position: fixed;
//       top: calc(84px + 15px);
//       left: 15px;
//       z-index: 5;
//     }

//     @include layout-flip {
//       top: calc(74px + 15px);
//     }
//   }

//   &__name {
//     display: inline-block;
//     color: $white;
//     border-radius: 24px;
//     background: rgba($white, 0.20);
//     box-shadow: 0px 0px 20px 0px rgba($black, 0.12);
//     backdrop-filter: blur(15px);
//     border: 1px solid rgba($white, 0.2);
//     padding: 2px 12px;
//     font-size: 16px;
//     line-height: 22px;
//     font-weight: 600;
//     margin-bottom: 0 !important;
//     max-width: 120px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;

//     @include media-breakpoint-up(sm) {
//       max-width: 180px;
//     }

//     @include media-breakpoint-up(lg) {
//       line-height: 24px;
//       padding: 4px 16px;
//     }
//   }
// }

// .title {
//   text-align: center;
//   z-index: 1;
// }
// @media screen and (orientation: landscape) and (max-width: 1071px) { // TODO: fix bad screen rotation on mobile UIs.
//   html {
//     /* Rotate the content container */
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     /* Set content width to viewport height */
//     width: 100vh;
//     /* Set content height to viewport width */
//     height: 100vw;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
// }
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
  margin: 0;
}


body {
  background: linear-gradient(157deg, #EFF8FE 0%, #F5FBFF 100%);
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

// header {
//   padding: 38px 100px 0 100px !important;
//   margin: 0 auto !important;
//   max-width: 1440px !important;
//   width: 100% !important;
//   display: flex !important;
//   justify-content: space-between !important;
//   align-items: center !important;
// }

.main {
  padding: 12px 100px 0 100px;
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

@media only screen and (max-width: 1024px) {
  .main {
      padding: 12px 40px 0 40px;
  }
  header {
      padding: 24px 40px 0 40px !important;
  }
}

@media only screen and (max-width: 480px) {
  .main {
      padding: 12px 20px 0 20px;
  }

  header {
      padding: 5px 20px 0 20px !important;
  }
}

.mainButton {
  display: inline-flex;
  height: 58px;
  cursor: pointer;
  padding: 18px 56px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #FE4949;
  border: none;
  color: #EFF8FE;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 24px;
}

.mainVideo {
  position: relative;
  min-height: 50vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.placeholderTablet {
  display: none;
}

.placeholderMobile {
  display: none;
}

@media only screen and (max-width: 1600px) and (min-width: 1025px) and (max-height: 900px) {
  .placeholder {
      max-height: 520px;
  }
}


@media only screen and (max-width: 1600px) and (min-width: 1025px) and (max-height: 800px) {
  .placeholder {
      max-height: 440px;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1025px) and (max-height: 750px) {
  .placeholder {
      max-height: 400px;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1025px) and (max-height: 700px) {
  .placeholder {
      max-height: 360px;
  }
}

@media only screen and (max-width: 1025px) and (max-height: 1367px) {
  .placeholder {
      display: none;
  }
  .placeholderTablet {
      display: inline;
  }
  .placeholderTablet {
      width: 100%;
      height: 600px;
  }
}

@media only screen and (max-width:480px) and (max-height: 940px) {
  .placeholder {
      display: none;
  }

  .placeholderTablet {
      display: none;
  }
  .placeholderMobile {
      display: inline;
  }
  .placeholderMobile {
      width: auto;
      height: auto;
      object-fit: contain;
  }
}

@media screen and (max-width: 480px) and (max-height: 700px) {
  .placeholderMobile {
      width: auto;
      height: 320px;
  }

}

.selfVideo {
  position: absolute;
  top: 0;
  right: 0;
}

.selfInner {
  position: relative;
}

.selfImage {
  border-radius: 8px;
}

.selfImageTablet, .selfImageMobile {
  display: none;
}

@media only screen and (max-width: 767px) and (min-width: 481px) {
  .selfImage {
      display: none;
  }

  .selfImageTablet {
      display: inline;
  }

}

@media only screen and (max-width: 480px) {
  .selfImage {
      display: none;
  }

  .selfImageMobile {
      display: inline;
  }

  .switch {
      padding: 8px;
  }

}

.switch {
  cursor: pointer;
  background: #62B4F2;
  border: none;
  border-radius: 8px;
  position: absolute;
  right: 0;
  bottom: 4px;
  padding: 12px;
}

@media only screen and (max-width: 480px) {
  .switch {
      padding: 8px;
  }

  .switch-icon {
      width: 24px;
  }

}




.timeCall {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

@media only screen and (max-width: 1025px) {
  .timeCall {
      margin-bottom: 0;
  }
}

.timeCallIcon {
  color: red;
}

.timeCallTime {
  color: #294C65;
  font-size: 1.25rem;
  margin-bottom: 4px
}

.bottomBar {
  width: 100%;
  background: #62B4F2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 96px;
  position: absolute;
  bottom:0;
}

@media screen and (max-height: 700px) {
  .bottomBar {
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 1025px) {
  .bottomBar {
      position: fixed;
      bottom: 0;
  }

}

.bottomIconDiv {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.bottomIcon {
  width: 64px;
}

@media only screen and (max-width: 480px) {
  .bottomIcon {
      width: 48px;
  }
}

.bottomText {
  font-size: 1.25rem;
  font-weight: 500;
  color: #EFF8FE;
}


/*Join Meeting CSS*/

@media only screen and (max-width: 481px) {
  .main {
      padding-bottom: 80px;
  }
}

#joinCall {
  background: #01CD9D;
}

.joinMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.joinMessageText {
  color: #294C65;
  font-size: 1.25rem;
  margin-bottom: 4px;
  text-align: center;
  max-width: 320px;
}

@media screen and (max-width: 480px) {
  .joinMessageText {
      font-size: 1.125rem;
  }

}

.waitingPlaceholder {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

@media only screen and (max-width: 1536px) and (max-height: 901px) {
  .waitingPlaceholder {
      height: 460px;
  }
}



@media only screen and (max-width: 1370px) and (max-height: 780px) {
  .waitingPlaceholder {
      height: 350px !important;
  }
}

@media only screen and (max-width: 1300px) and (max-height: 721px) {
  .waitingPlaceholder {
      height: 280px !important;
  }
}

@media only screen and (max-width: 1025px) and (max-height: 1367px) {
  .waitingPlaceholder {
      height: 480px !important;
  }
}

@media only screen and (max-width: 376px) and (max-height: 677px) {
  .waitingPlaceholder {
      height: 240px !important;
  }
}