@import "../../styles/variables";
@import "../../styles/mixins";

.testScore {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 255px;

  .result {
    position: absolute;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;

    .backdrop {
      position: absolute;
      width: 100%;
      height: 100%;
      background: white;
      border-radius: 100vmax;
      z-index: 1;
    }

    .chartScorePointer {
      position: absolute;
      margin: 0;
      z-index: 0;
    }
    .ratingName {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      color: #919BB5;
      border: 1px solid #E4EBF5;
      border-radius: 100vmax;
      z-index: 2;
    }
  }
}