@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.favorites {
  .header {
    margin: 0;
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 43px;
    display: flex;
    align-items: center;
    color: $secondary;
  }

  .cards {
    display: grid;
    gap: 28px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 40px 0 0;

    @include xl {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: minmax(0, 1fr);
    }

    @include layout-flip {
      gap: 16px;
      margin: 24px 0 0;
    }

    .noFavorites {
      color: $gray-700;
    }
  }
}
