@import "common/styles/variables";
@import "common/styles/mixins";

.participantInfo {
  @include flexCenter;

  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 5;
  width: 55px;
  height: 75px;
  border-radius: 12px;
  border: 2px solid $white;
  background-color: $olive;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    top: 20px;
    right: 20px;
    width: 100px;
    height: 70PX;
  }

  &__audioLevelIndicator {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    border-radius: 0 13px 0 10px;
    width: 30px;
    height: 25px;

    > svg {
      margin-left: -4px;
    }
  }

  &__videoIndicator {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top-right-radius: 13px;
    width: 60px;
    height: 25px;
    padding: 5px 10px;
    color: $red;
    background-color: $white;
  }

  &__name {
    @include inlineFlexCenter;

    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    height: 25px;
    border-radius: 13px 0px 10px 0px;
    background-color: rgba($white, 0.20);
    box-shadow: 0px 0px 20px 0px rgba($black, 0.12);
    backdrop-filter: blur(15px);
    border: 1px solid rgba($white, 0.2);
    font-size: 14px;
    line-height: 1.2;
    color: $white;
    padding: 4px 12px;
    margin: 0;
  }
}