@import "../../../../../../common/styles/mixins";

.photosEdit {
  max-width: 798px;
  width: 100%;

  .dropContainer {
    margin: 0 0 32px;
    width: 100%;

    @include layout-flip {
      margin: 32px 0 16px;
    }
  }

  .saveButton {
    max-width: 386px;
    width: 100%;
  }
}