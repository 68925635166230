.arrow {
  width: 14px;
  height: 12px;
  margin: 0 0 0 2px;

  &.down {
    transform: rotate(180deg);
  }

  &.disabled {
    transform: rotate(180deg);
    opacity: 0.5;
  }
}
