@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.patient-step {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 40px 0;
  background-color: $blue-light;
  background-image:
    url('../../../assets/images/bg-survey/bg-top.svg'),
    url('../../../assets/images/bg-survey/bg-bottom-right.svg'),
    url('../../../assets/images/bg-survey/bg-left.svg'),
  ;
  background-repeat: no-repeat;
  background-size: 150px, 190px, 100px;
  background-position: top 3% right 25%, bottom right, top 45% left;
  margin-top: -2px;

  @include media-breakpoint-up(sm) {
    padding: 60px 0;
  }

  @include media-breakpoint-up(md) {
    background-size: 200px, 240px, 130px;
  }

  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 112px);
    padding: 80px 0;
    margin-top: 0;
  }

  @include media-breakpoint-up(xl) {
    background-size: 255px, 300px, 180px;
  }

  &__title {
    position: relative;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 46px;
    line-height: 58px;
    transition: all 0.2s ease-out;
    text-align: center;

    & + .patient-step__select {
      margin: 16px 0 36px;
    }

    & + .patient-step__form {
      margin-top: 48px;
    }

    @include layout-flip {
      & + .patient-step__form {
        margin-top: 24px;
      }

      & + .patient-step__select {
        margin: 0 0 40px;
      }
    }
  }

  &__select {
    color: #26d4b6;
    font-size: 20px;
    line-height: 24px;
  }

  &__form {
    width: 100%;
  }

  &__row {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto 40px;
    flex-wrap: wrap;
  }

  &__checkbox {
    position: relative;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;

    .checkbox__input + label {
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__two-columns {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 56px;
  }

  &__radio.radiobutton {
    position: relative;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    flex-shrink: 0;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 386px;
    height: 66px;
    margin: 0 5px;

    @include layout-flip {
      max-width: 155px;
      height: 60px;
      padding: 0;

      &.full-width {
        max-width: 100%;
      }
    }
  }

  &__row {
    &-layout {
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;
    }

    &-title {
      font-size: 18px;
      line-height: 21px;
      color: #1D3B34;
      font-weight: 600;
      margin-bottom: 16px;
      font-family: $headings-font-family;

      @include layout-flip {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: 0.25px;
      }
    }

    &-subtitle {
      display: none;
      @include layout-flip {
        margin-bottom: 24px;
        display: block;
        font-size: 17px;
        line-height: 20px;
        color: #26D5B6;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .patient-step {
    &__title {
      font-size: 26px;
      line-height: 34px;
      letter-spacing: 0.25px;
    }

    &__select {
      align-self: flex-start;
      font-size: 17px;
      line-height: 20px;
    }

    &__wrapper {
      min-height: calc(100vh - 83px);
      margin: 0;
      border: none;
      align-items: flex-start;
    }

    &__form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
    }

    &__two-columns {
      .patient-step__checkbox {
        width: 100%;
      }

      .patient-step__radio {
        width: 100%;
      }
    }

    &__actions {
      z-index: 10;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 24px 24px 34px;
      width: 100%;
      background: #fff;
      border-top: 1px solid #d4e1f2;
    }
  }
}

.patient-step-11__title {
  @media (min-width: 992px) {
    display: block;
  }
}

.patient-step-11__form {
  label.radiobutton {
    padding: 0;

    .radiobutton__inner {
      width: 100%;
      padding: 15px;
      border-radius: 4px;
    }

    input:checked + .radiobutton__inner {
      color: $secondary;
      background: #defaf6;
      border: 1px solid $primary;
    }
  }

  @include layout-flip {
    max-width: 300px;
    margin: 0;

    label.radiobutton {
      margin: 0 0 16px;
    }
  }
}
