@import "../../../common/styles/_variables.scss";
@import "../../../common/styles/mixins";

.root {
  max-width: calc(1693px + 396px);
  margin: 0 auto;
  padding: 60px 168px;

  @media (max-width: $desktop-xl) {
    padding: 60px 30px;
  }

  @include layout-flip {
    padding: 34px 24px;
  }

  .loadingMessage {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;

    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .spinner {
      margin: 16px;
    }

    p {
      margin: 0;
      font-size: 40px;
      white-space: nowrap;

      @media (max-width: 1000px) {
        font-size: 30px;
      }

      @media (max-width: 700px) {
        font-size: 16px;
        white-space: nowrap;
      }
    }
  }

  .title {
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 50px;
    line-height: 1.21;

    @include layout-flip {
      margin: 0 0 31px;
      text-align: left;
      font-size: 28px;
    }
  }

  .therapists {
    display: grid;
    margin-top: 60px;
    gap: 28px;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: minmax(0, 1fr);
    }

    @include layout-flip {
      margin: 0;
    }

    .card {
      @include layout-flip {
        img {
          height: 163px;
        }

        a + div > div div span {
          display: none;
        }

        a + div > div > a > p {
          margin: 0;
          font-weight: 600;
          font-size: 23px;
        }

        a + div > a {
          display: none;
        }

        a + div > p + div {
          margin: 0;

          & > div {
            margin: 0 8px 0 0;
            width: 35px;
            height: 35px;

            svg {
              transform: scale(0.8);
            }
          }
        }
      }
    }
  }
}

.load_more_row {
  display: flex;
  justify-content: center;
  margin-top: 70px;

  button {
    width: 428px;

    @include layout-flip {
      width: 100%;
    }
  }
}
