@import "../../../../../../common/styles/mixins";

.root {
  max-width: 806px;
  width: 100%;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 40px;

    @include layout-flip {
      margin: 0 0 24px;
    }
  }

  @include min-layout-flip {
    .startCell {
      flex: 0 0 36%;
    }

    .centerCell {
      flex: 0 0 15%;
    }

    .endCell {
      flex: 0 0 15%;
    }
  }

  .warning {
    margin: 0 0 32px;
    font-size: 1.1;
    line-height: 19px;
    color: #1f342f;

    @include layout-flip {
      margin: 0 0 24px;
    }

    .link {
      color: $primary;
      border-bottom: 1px solid $accent-color;
      transition: all 0.3s ease-in-out 0s;

      &:hover,
      &:focus {
        border-color: transparent;
      }
    }
  }
}
