@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.root {
  display: flex;
  margin-bottom: 40px;

  @include layout-flip {
    margin-bottom: 32px;
  }
}

.star {
  cursor: pointer;

  & + & {
    padding-left: 22px;
  }

  @include layout-flip {
    & + & {
      padding-left: 0;
      margin-left: 16px;
    }

    svg {
      width: 40px;
    }
  }
}

.hovered {
  fill: rgba($color: #3ceac1, $alpha: 0.4);
}

.selected {
  fill: #3ceac1;
}
