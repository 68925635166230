@import "../../styles/variables";
@import "../../styles/mixins";

.changePasswordForm {
  .input {
    margin: 0 0 32px;

    @include layout-flip {
      margin: 0 0 20px;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  button {
    margin: 48px 0 0;
    line-height: 19px;
    grid-column: 1;
  }
}