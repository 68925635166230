@import "../../../../common/styles/mixins";

.root {
  .form {
    max-width: 803px;
  }

  .row {
    max-width: 387px;
    padding: 0 0 32px;

    @include layout-flip {
      padding: 0 0 21px;
    }
  }

  .longRow {
    padding: 0 0 32px;

    @include layout-flip {
      padding: 0 0 21px;
    }
  }

  .button {
    max-width: 387px;
    width: 100%;
  }

  .addButton {
    margin: 48px 0 0;
    padding: 0;

    @include layout-flip {
      margin: 24px 0;
    }
  }

  .textarea textarea {
    height: 113px;
  }

  .item {
    max-width: 818px;
    margin: 0 0 24px;

    &:last-of-type {
      margin: 0 0 48px;

      @include layout-flip {
        margin: 13px 0 0;
      }
    }
  }

  .cardButton {
    svg {
      path {
        &:not(:last-child) {
          stroke: #000000;
        }
      }
    }
  }
}

.hint {
  @include layout-flip {
    display: none;
  }
}
