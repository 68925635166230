@import "../../../../common/styles/_variables.scss";
@import "../../../../common/styles/_mixins.scss";

.root {
  display: flex;
  align-items: center;
  padding: 36px 24px;
  background: $gray-100;
  border-radius: 15px;

  & + .root {
    margin: 16px 0 0;
  }

  .title {
    margin: 0 0 10px;
    font-size: 20px;
    line-height: 1.2;
    color: $secondary;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  .time {
    display: flex;
    align-items: center;
    margin: 0 0 26px;
    font-size: 14px;
    line-height: 1;
    color: $gray-700;
  }

  .icon {
    margin: 0 6px 1px 0;
    width: 19px;
    height: 19px;
  }

  .progress {
    max-width: 206px;

    :global .scale__line {
      background: $gray-200;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin: 0 0 0 auto;
    padding: 5px;
  }
}
