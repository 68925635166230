// COLORS
$white:                      #FFFFFF !default;
$gray-90:                    #F2F6FB !default;
$gray-100:                   #F4F8FC !default;
$gray-200:                   #D4E1F2 !default;
$gray-300:                   #A7B2CD !default;
$gray-400:                   #C4C4C4 !default;
$gray-500:                   #A1AAB7 !default;
$gray-700:                   #68768B !default;
$black:                      #000000 !default;
$green-dark:                 #1F342F !default;
$green-lighter:              #4FE5D4 !default;
$purple:                     #854BFF !default;
$teal:                       #3DEAC1 !default;
$teal-dark:                  #01CD9D !default;
$accent-color:               #26D5B6 !default;
$mint:                       #DEFAF6 !default;
$pink:                       #FFDDE2 !default;
$red:                        #FE4949 !default;
$red-dark:                   #C82525 !default;
$red-muted:                  #F06262 !default;
$red-faded:                  #FFD9D9 !default;
$turquoise:                  #B5E8E0 !default;
$yellow:                     #FFDA79 !default;
$yellow-dark:                #B58B00 !default;
$blue-light:                 #F9FBFD !default;
$blue-light-2:               #E5F0FF !default;
$olive:                      #778B87 !default;

$primary:                    $teal;
$secondary:                  $green-dark;

$success:                    $primary !default;
$error:                      $red !default;
$warning:                    $yellow;

// BASE
$spacers: (
  0:   0,
  2:   2px,
  4:   4px,
  5:   5px,
  6:   6px,
  8:   8px,
  10:  10px,
  12:  12px,
  15:  15px,
  20:  20px,
  25:  25px,
  30:  30px,
  35:  35px,
  40:  40px,
  45:  45px,
  50:  50px,
  55:  55px,
  60:  60px,
  65:  65px,
  70:  70px,
  75:  75px,
  80:  80px,
  85:  85px,
  90:  90px,
  95:  95px,
  100: 100px
) !default;

$body-bg:                    $white !default;
$body-font-size:             16px !default;
$body-line-height:           1.5 !default;
$body-letter-spacing:        0 !default;
$body-font-family:           "Public Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$body-font-weight:           400;
$body-color:                 $secondary !default;

// TYPOGRAPHY
$font-weight-regular:        400 !default;
$font-weight-medium:         500 !default;
$font-weight-semibold:       600 !default;
$font-weight-bold:           700 !default;
$font-weight-black:          900 !default;

$headings-font-family:      "Mulish", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-color:             null !default;
$headings-font-weight:       $font-weight-semibold !default;
$headings-line-height:       1.2 !default;
$font-size-xs:               0.75rem !default;    // 12px
$font-size-sm:               0.875rem !default;   // 14px
$font-size-lg:               1.125rem !default;   // 18px
$font-size-xl:               1.25rem !default;    // 20px

// BUTTONS
$btn-font-family:            $body-font-family !default;
$btn-font-size:              16px !default;
$btn-line-height:            1.25 !default;
$btn-font-weight:            600 !default;
$btn-border-width:           1px !default;
$btn-border-radius:          10px !default;
$btn-height:                 56px !default;

$btn-sm-height:              48px !default;
$btn-lg-height:              60px !default;

// FORMS
$input-height:               $btn-height !default;
$input-border-width:         1px !default;
$input-border-color:         $gray-200 !default;
$input-background-color:     $white !default;
$input-font-size:            16px !default;
$input-line-height:          1.6 !default;
$input-color:                $secondary !default;
$input-placeholder-color:    $gray-700 !default;
$input-border-radius:        4px !default;
$input-padding-x:            16px !default;
$input-padding-y:            8px !default;
$input-padding:              $input-padding-y $input-padding-x !default;
$input-hover-border-color:   $gray-500 !default;
$input-transition:           border-color 0.3s, background-color 0.3s !default;
$input-disabled-background-color: $gray-90 !default;

$input-sm-height:            42px !default;
$input-sm-padding-x:         16px !default;
$input-sm-padding-y:         6px !default;
$input-sm-padding:           $input-sm-padding-y $input-sm-padding-x !default;

// BREAKPOINTS
$layout-flip-var: 900px;
$desktop-m: 1366px;
$desktop-l: 1440px;
$desktop-xl: 1600px;

$grid-breakpoints: (
  xxs: 0,
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1240px,
  xxl: 1400px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;

$grid-columns:                12 !default;
$grid-gutter-width:           40px !default;
$grid-gutter:                 $grid-gutter-width * 0.5 !default;;
$grid-row-columns:            6 !default;

$container-normal-width:      1160px + $grid-gutter-width;

// MODAL
$modal-backdrop-bg:                 $secondary !default;
$modal-backdrop-opacity:            0.2 !default;
$modal-content-bg:                  $white !default;
$modal-content-border-radius:       16px !default;
$modal-dialog-margin:               20px !default;

// Z-INDEX MASTER LIST
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-offcanvas-backdrop:         1040 !default;
$zindex-offcanvas:                  1045 !default;
$zindex-modal-backdrop:             1050 !default;
$zindex-modal:                      1055 !default;
$zindex-popover:                    1070 !default;
$zindex-tooltip:                    1080 !default;
$zindex-toast:                      1090 !default;