@import "../../../../../../common/styles/mixins";

.root {
  .header {
    margin: 0 0 40px;
  }

  .title {
    margin: 0 0 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: -0.01em;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 32px;
    }
  }

  .info {
    display: flex;
    align-items: center;
    max-width: 655px;
    margin: 0 0 40px;
    padding: 32px;
    background: #ffffff;
    border: 1px solid #d4e1f2;
    border-radius: 6px;

    @include layout-flip {
      flex-direction: column;
      margin: 0 0 32px;
      padding: 24px 26px;
    }
  }

  .status {
    display: flex;
    align-items: center;
    margin: 0 0 0 34px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: -0.01em;
    color: #c00000;

    &.warning {
      color: #d89d03;

      .icon {
        border-color: #d89d03;

        .cross::before,
        .cross::after {
          background: #d89d03;
        }
      }
    }

    @include layout-flip {
      margin: 16px 0 0;
    }

    &.active {
      color: #04c000;

      .icon {
        border-color: #04c000;
      }
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    margin: 0 12px 0 0;
    border: 2px solid #c00000;
    border-radius: 50%;

    svg {
      width: 16px;
      height: 10px;

      path {
        fill: #04c000;
      }
    }
  }

  .cross {
    position: relative;
    width: 12px;
    height: 12px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 2px;
      height: 100%;
      background: #c00000;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 2px;
      height: 100%;
      background: #c00000;
    }
  }

  .row {
    @include min-layout-flip {
      display: flex;
      align-items: center;
    }
  }

  .button {
    max-width: 386px;
    width: 100%;

    & + .button {
      margin: 0 0 0 32px;

      @include layout-flip {
        margin: 16px 0 0;
      }
    }
  }
}
