@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

.popup {
  height: 298px;
  padding: 80px 54px 20px;

  @include layout-flip {
    border-radius: 30px 30px 0 0;
    height: auto;
    max-width: 100% !important;
  }
}

.popupCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 54px;
  background: none;
  border: none;
  cursor: pointer;

  @include layout-flip {
    display: none;
  }
}

.popupTitle {
  margin: 0 0 32px;
  padding: 0;
  text-align: center;
  font-family: $headings-font-family;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.3;
  color: $secondary;
}

.popupSubtitle {
  display: block;
  margin: 0 0 16px;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: $secondary;
}

.popupField {
  display: flex;
  margin: 0;
  padding: 0;
  border: 0;
}

.popupInput {
  width: 100%;
  color: $gray-700;
}

.popupButton {
  max-width: 143px;
  width: 100%;
  margin: 0 0 0 24px;
  padding: 0;
}
