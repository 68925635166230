@import "~common/styles/variables";

.title {
  font-family: $headings-font-family;
  font-weight: 400;
  font-size: 20px;
  color: #1F342F;
  background: #F4F8FC;
  border: 1px solid #D4E1F2;
  border-radius: 6px;
  padding: 9px;
}
