@import "../../styles/variables";

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 100px auto;

  @media (max-width: 480px) {
    margin: 50px auto;
  }

  &__arrow {
    width: 40px;
    height: 40px;
    background-image: url("../../../assets/images/icons/chevron-left-Regular.svg");
    background-color: #f1f7ff;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #d4e1f2;
    border-radius: 50px;
    cursor: pointer;

    &.right {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    @media (max-width: 480px) {
      flex-shrink: 0;
      width: 28px;
      height: 28px;

      svg {
        -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
        transform: scale(0.8);
      }
    }
  }

  &__double {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #f1f7ff;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #d4e1f2;
    border-radius: 50px;
    cursor: pointer;

    span {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 20px;
      height: 20px;
    }

    &.right {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    @media (max-width: 480px) {
      width: 28px;
      height: 28px;

      svg {
        -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
        transform: scale(0.7);
      }
    }
  }

  &__pages {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 16px;
  }

  &__page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 60px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    min-width: 40px;
    margin: 0 4px;
    padding: 11px 15px 10px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    letter-spacing: -0.01em;
    background: none;
    color: #68768b;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;

    &:nth-child(2) {
      margin-left: 8px;
    }

    &:nth-last-child(2) {
      margin-right: 8px;
    }

    &:hover {
      background: #fafafa;
    }

    &.current {
      pointer-events: none;
      background: #3ceac1;
      color: #1f342f;
    }

    @media (max-width: 480px) {
      min-width: 28px;
      padding: 7px 8px 7px;
      font-size: 14px;
    }
  }
}
