@import "../../../../common/styles/mixins";

.root {
  .button {
    max-width: 386px;
    width: 100%;
  }

  .row {
    @include layout-flip {
      margin: 0 0 16px;
      padding: 0;

      & + .row {
        margin: 0 0 32px;
      }
    }
  }

  .select {
    max-width: 387px;
    margin: 0 0 32px;

    @include layout-flip {
      margin: 0;
    }
  }

  .file {
    margin: 0 0 48px;
  }

  .radio {
    border: 0;
    padding: 0;

    &:not(:last-child) {
      margin-right: 16px;

      @media all and (max-width: 1024px) {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }

    :global .radiobutton__inner {
      width: 100%;
      padding: 16px;
      background: #feffff;
      border: 1px solid #d4e1f2;
      border-radius: 4px;
      transition: all 0.3s ease-in-out 0s;

      &:hover,
      &:focus {
        border: 1px solid #4adccc;
      }
    }

    input:checked + div {
      background: #defaf6;
      border: 1px solid #3ceac1;
    }
  }

  .upload {
    margin: 0 0 24px;
  }

  .row {
    max-width: 387px;
  }
}
