@import "../../../../../common/styles/mixins";

.root {
  padding: 60px 0;
  background: #dce8fa;

  @include layout-flip {
    padding: 32px 24px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1094px;
    margin: 0 auto;
    padding: 0 50px;

    @include layout-flip {
      display: block;
      text-align: center;
      padding: 0;
    }
  }

  .title {
    margin: 0 0 23px;
    padding: 0;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.1;
    color: #000000;

    @include layout-flip {
      margin: 0 0 12px;
      font-size: 25px;
    }
  }

  .subtitle {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 16px;
      font-size: 14px;
      line-height: 1.4;
    }
  }

  .button {
    max-width: 250px;
    width: 100%;

    @include layout-flip {
      margin: 0 auto;
      max-width: 500px;
    }

    @media (max-width: 568px) {
      max-width: none;
    }
  }
}
