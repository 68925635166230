@import "../../styles/variables";
@import "../../styles/mixins";

.root {
  position: relative;
  margin: 0 0 0 32px;

  @media (max-width: 1024px) {
    margin: 0 0 0 10px;
  }

  .inner {
    display: flex;
    align-items: center;
    flex-basis: fit-content;
  }

  .header {
    display: flex;
    align-items: center;
  }

  img {
    width: 52px;
    height: 52px;
    margin-right: 16px;
    border-radius: 15px;
    object-fit: cover;

    @include layout-flip {
      width: 42px;
      height: 42px;
      border-radius: 6px;
      margin-right: 4px;
    }
  }

  .row {
    display: flex;
    flex-direction: column;

    h3 {
      margin: 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.2;
      color: $secondary;

      & + small {
        margin: 8px 0 0;

        @include layout-flip {
          margin: 4px 0 0;
        }
      }

      @include layout-flip {
        font-size: 14px;
      }

      .modalTrigger {
        position: relative;
        display: inline-block;
        margin: 0 0 0 10px;

        button {
          @include clean-btn;
          width: 20px;
          height: 20px;
        }

        svg {
          width: 14px;
          height: 10px;
        }
      }
    }
  }

  .modalList {
    z-index: 15;
    position: absolute;
    top: 65px;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 282px;
    margin: 0;
    padding: 35px;
    background: #ffffff;
    box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);
    border-radius: 4px;
    list-style: none;

    li {
      flex: 0 0 100%;
      margin: 0 0 20px;
      padding: 0;

      &:last-of-type {
        margin: 0;
      }
    }

    .modalButton {
      @include clean-btn;
      width: 100%;
      margin: 0;
      justify-content: flex-start;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      color: #6e7891;
      transition: all 0.3s ease-in-out 0s;

      &:hover,
      &:focus {
        color: #22c7a0;

        svg path {
          fill: #22c7a0;
        }
      }

      svg {
        width: 18px;
        height: 20px;
        margin: 0 10px 0 0;

        path {
          fill: $gray-700;
          transition: all 0.3s ease-in-out 0s;
        }
      }
    }
  }

  small {
    font-weight: 400;
    font-size: 17px;
    line-height: 1.1;
    letter-spacing: -0.01em;
    color: $gray-700;

    @include layout-flip {
      font-size: 12px;
    }
  }

  .layout {
    z-index: 14;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
