@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.container {
  max-width: 570px;
  width: 100%;
  padding: 24px;

  @include layout-flip {
    padding: 45px 16px;
  }
}

.h2 {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: $secondary;
}

.p {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.01em;
  color: $gray-700;
}

.payment {
  max-width: 570px;
  width: 100%;
  padding: 24px;

  @include layout-flip {
    padding: 45px 16px;
  }

  .get {
    display: block;
  }
}

.header {
  margin: 0 0 80px;
  font-family: $headings-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 58px;
  color: $secondary;

  @include layout-flip {
    margin: 0 0 25px;
    font-size: 26px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.25px;
  }
}

.billingContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin: 36px 0 36px;

  @include layout-flip {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 33px;
  }

  .title {
    @include layout-flip {
      margin: 0 0 33px;
    }

    h2 {
      margin: 0 0 12px;
    }
  }

  .description {
    p {
      margin: 0 0 12px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: $secondary;
    }
  }

  .paymentRadio {
    @include layout-flip {
      width: 100%;
    }
  }
}

.totalContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 0 32px;

  .totalPrice {
    h2 {
      margin: 0 0 12px;
    }

    p {
      text-align: end;
    }
  }
}

.dontHaveCards {
  @include layout-flip {
    padding: 54px 0 0;
    border-top: 1px solid $gray-200;
  }

  p {
    margin: 0 0 32px;
    font-size: 17px;
    line-height: 20px;
    color: $secondary;

    @include layout-flip {
      margin: 0 0 54px;
      letter-spacing: -0.01em;
    }
  }

  .addNewCard {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:focus {
      svg {
        filter: none;

        rect {
          fill: #22c7a0;
        }
      }

      span {
        border-color: transparent;
      }
    }

    svg {
      width: 50px;
      height: 50px;
      margin: 0 8px 0 0;
      filter: drop-shadow(0 10px 15px rgba(69, 199, 184, 0.31));

      rect {
        transition: all 0.3s ease-in-out 0s;
      }
    }

    span {
      display: inline-block;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $accent-color;
      border-bottom: 1px solid;
      transition: all 0.3s ease-in-out 0s;
    }
  }
}

.proceed {
  margin: 62px auto 0;
  max-width: 383px;
  width: 100%;

  @include layout-flip {
    max-width: none;
    margin: 20px 0 0;
  }
}

.toastMessage {
  background: #3ceac1;
  color: #1f342f;
}
