@import "../../../../../common/styles/mixins";

.root {
  z-index: 1;
  position: relative;
  max-width: 1338px;
  margin: 0 auto;
  padding: 120px 50px 0;

  @include layout-flip {
    padding: 46px 24px 0;
  }

  .lead {
    text-align: center;
    margin: 0 0 64px;
    font-weight: 400;
    font-size: 25px;
    line-height: 1.18;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 24px;
      font-size: 16px;
      text-align: center;
    }

    @media (max-width: 568px) {
      text-align: left;
    }
  }

  .title {
    text-align: center;
    margin: 0 0 16px;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.1;
    color: #000000;

    @include layout-flip {
      max-width: 300px;
      margin: 0 auto 16px;
      font-size: 33px;
    }
  }

  .inner {
    display: flex;
    align-items: center;

    @media (max-width: 1190px) {
      flex-direction: column-reverse;
      margin: 0 auto;
      max-width: 850px;
    }

    @include layout-flip {
      margin: 0 auto;
      width: 500px;
    }

    @media (max-width: 630px) {
      width: auto;
    }
  }

  .image {
    max-width: 618px;
    height: 500px;
    margin: 0 80px 0 0;
    object-fit: cover;

    @media (max-width: 1190px) {
      margin: 40px auto 0;
      max-width: none;
      width: auto;
      height: 500px;
    }

    @include layout-flip {
      margin: 0 0 47px;
      width: auto;
      height: 350px;
    }

    @media (max-width: 568px) {
      height: 264px;
    }
  }

  .columnTitle {
    margin: 0 0 16px;
    padding: 0;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.35;
    color: #000000;

    @include layout-flip {
      margin: 0 0 16px;
      text-align: center;
      font-size: 20px;
    }
  }

  .columnText {
    margin: 0 0 32px;
    padding: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 24px;
      text-align: left;
      font-size: 16px;
      line-height: 1.4;
    }

    span {
      display: block;

      @include layout-flip {
        display: inline;
      }
    }
  }

  .button {
    max-width: 250px;
    width: 100%;
    margin: 32px 0 0;

    @media (max-width: 1190px) {
      margin: 0 auto;
    }

    @include layout-flip {
      max-width: 500px;
      margin: 24px auto;
    }

    @media (max-width: 568px) {
      max-width: none;
    }
  }
}
