@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.testStart {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  @include layout-flip {
    margin-bottom: 30px;
  }
}

.title {
  margin: 0;
  font-family: $headings-font-family;
  font-size: 34px;
  line-height: 43px;
  font-weight: normal;
  color: #1d3b34;

  @include layout-flip {
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.25px;
  }
}

.rowButtons {
  text-align: center;
  button {
    margin-right: 15px;
  }

  @include layout-flip {
    display: flex;
    gap: 24px 16px;
    flex-direction: column-reverse;
  }
}
