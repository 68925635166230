@import "../../styles/variables";

.root {
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 45px;
  min-height: auto !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  background: #defaf6;
  border-color: #defaf6;
  transition: all 0.3s ease-in-out 0s;

  &.premium {
    background-color: $primary;
    border-color: $primary;

    &:hover,
    &:focus {
      background-color: #22c7a0;
      border-color: #22c7a0;
    }
  }

  &.available {
    background: $primary;
    border-color: $primary;

    &:hover,
    &:focus {
      background: #22c7a0;
      border-color: #22c7a0;
    }
  }

  &.wait {
    background: $warning;
    border-color: $warning;

    &:hover,
    &:focus {
      background: $warning;
      border-color: $warning;
    }
  }

  &.offline {
    background: $error;
    border-color: $error;

    &:hover,
    &:focus {
      background: $error;
      border-color: $error;
    }
  }
}

.icon {
  margin-right: 5px;
}
