@import "../../../common/styles/mixins";

.cta {
  z-index: 1;
  position: relative;
  min-height: 100vh;
  height: 100%;
  background-color: #ffffff;
  background-image: url("../../../assets/images/backgrounds/landing-background.png");
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;

  @media (min-width: 900px) {
    background-position: center top -150px;
  }

  @media (min-width: 1150px) {
    background-position: center top -100px;
  }

  @media (min-width: 1300px) {
    background-position: center -100px;
  }

  @media (min-width: 1536px) {
    background-image: url("../../../assets/images/backgrounds/landing-background-4k.png");
  }

  @include max-bg {
    background-size: auto;
  }

  @include layout-flip {
    background-image: url("../../../assets/images/backgrounds/mobile-landing-background.png");
    background-size: 200% 30%;
  }

  .btn {
    min-width: 200px;
    padding: 20px 38px;
  }

  .btnRow {
    margin: 0 30px 0 0;

    @include layout-flip {
      margin: 25px 0;
    }
  }

  .main {
    z-index: 4;
    position: relative;

    @include layout-flip {
      margin: 0 0 38px;
    }
  }

  .why {
    background-image: url("../../../assets/images/backgrounds/why-background.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;

    @include layout-flip {
      background-image: url("../../../assets/images/backgrounds/why-background-mobile.png");
    }
  }
}
