@import "../../../styles/mixins";

.container {
  position: relative;
  width: 100%;

  video {
    border-radius: 20px;
  }
}

.fullscreen {
  display: flex;
  align-items: center;
}

.roundButton {
  @include round-btn(98px);
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
}

.startRecordingButton {
  background: #fe4949;
  border: 1px solid white;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.inProgressButton {
  background: white;
  border: none;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #fe4949;
    border-radius: 50%;
    @include restrict-prop(height, 32px);
    @include restrict-prop(width, 32px);
  }

  svg {
    width: 100%;
    height: 100%;

    circle {
      transform: rotate(-90deg);
      transform-origin: center;
      stroke-linecap: round;
      stroke-width: 5px;
      stroke: #fe4949;
      fill: none;
      stroke-dasharray: 306;
      stroke-dashoffset: 306;
      animation: dash linear forwards;
    }
  }
}

.passedTimePanel {
  --dot-size: 15px;
  position: absolute;
  top: 44.5px;
  right: 32px;
  display: flex;
  column-gap: 10px;
  padding: 15px 12px;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  background: white;
  align-items: center;

  div {
    background: #fe4949;
    border-radius: 50%;
    @include restrict-prop(height, var(--dot-size));
    @include restrict-prop(width, var(--dot-size));
  }

  span {
    font-size: 18px;
    line-height: 21px;
    color: #68768b;
  }

  @include layout-flip {
    top: 48.5px;
    right: 16px;
    --dot-size: 12px;

    span {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

@keyframes flash-number {
  0% {
    opacity: 1;
  }

  32% {
    opacity: 0.1;
  }

  33% {
    opacity: 1;
  }

  65% {
    opacity: 0.1;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

.countDown {
  position: absolute;
  font-size: 240px;
  color: white;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  animation: flash-number 3s linear forwards;
}

.error {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
