@import "../../../../../../../common/styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 32px 60px;

  @include layout-flip {
    padding: 24px;
  }

  .closeButton {
    @include clean-btn;
    align-self: flex-end;
    margin: 0 0 9px;

    svg path {
      stroke: #1f342f;
    }

    @include layout-flip {
      margin: 0 0 17px;
    }
  }

  .title {
    margin: 0 0 40px;
    text-align: center;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 46px;
    line-height: 1.1;
    color: #1f342f;

    @include layout-flip {
      margin: 0 0 16px;
      font-size: 32px;
      line-height: 1.2;
    }
  }

  .subtitle {
    margin: 0 0 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1;
    text-align: center;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 32px;
      font-size: 16px;
    }
  }

  .form {
    width: 100%;

    .input {
      margin: 0 0 24px;

      @include layout-flip {
        margin: 0 0 12px;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .radio {
    margin: 0 7px;
    padding: 0;

    @include layout-flip {
      width: 100%;
      margin: 0;

      &:not(:last-of-type) {
        margin: 0 0 16px;
      }
    }

    :global .radiobutton__inner {
      width: 100%;
      padding: 16px;
      background: #f4f8fc;
      border: 1px solid #d4e1f2;
      border-radius: 4px;
      transition: all 0.3s ease-in-out 0s;

      &:hover,
      &:focus {
        border: 1px solid #4adccc;
      }
    }

    input:checked + div {
      background: #defaf6;
      border: 1px solid #3ceac1;
    }
  }

  .formRow {
    display: flex;
    max-width: 550px;
    margin: 0 auto 40px;

    @include layout-flip {
      flex-direction: column;
      max-width: none;
      width: 100%;
      margin: 0 auto 32px;
    }
  }

  .button {
    max-width: 386px;
    width: 100%;
    margin: 0 auto;

    @include layout-flip {
      padding: 18px 47px;
    }
  }
}
