@import "common/styles/variables";
@import "common/styles/mixins";

// .mainParticipantInfo {
//   @include flexCenter;

//   position: absolute;
//   top: 50%;
//   left: 0;
//   right: 0;
//   transform: translate(0, -50%);
//   padding-bottom: 67%;

//   @include media-breakpoint-up(md) {
//     top: 0;
//     bottom: 0;
//     left: 60px;
//     right: 60px;
//     transform: none;
//     padding-bottom: 0;
//   }

//   @include media-breakpoint-up(lg) {
//     left: 160px;
//     right: 160px;
//   }

//   > video {
//     @include absoluteCover;
//   }

//   > svg {
//     @include absoluteCenter;
//     @include sizeElement(120px);

//     @include media-breakpoint-up(sm) {
//       @include sizeElement(180px);
//     }
//   }
// }

.mainParticipantInfo {
    position: relative;
    min-height: 50vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }