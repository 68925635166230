@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.tests {
  .title {
    margin: 0 0 22px;
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 43px;
    display: flex;
    color: $secondary;

    @include layout-flip {
      display: none;
    }
  }

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 60px;
    background: white;
    border: 1px solid $gray-200;
    border-radius: 10px;
    overflow: hidden;

    @include layout-flip {
      display: none;
    }

    .text {
      max-width: 582px;
      width: 100%;
      margin: 40px;

      .description {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $gray-700;
      }
    }

    .testImage {
      align-self: flex-end;
      width: 326px;
      height: 194px;
      margin: 24px auto 0;

      @media (max-width: 1200px) {
        display: none;
      }
    }
  }

  .tabs {
    :global {
      .react-tabs__tab-list {
        justify-content: flex-start;
        padding: 0 24px;
      }
    }
  }
}
