@import "../../styles/variables";
@import "../../styles/mixins";

.field {
  &.size-sm {
    .inputHolder {
      height: $input-sm-height;
      padding: $input-sm-padding;
    }
  }

  &.inputOptional > div {
    &:before {
      content: attr(data-optional);
      position: absolute;
      padding-left: 8px;
      right: 16px;
      bottom: 18px;
      font-weight: 500;
      font-size: 11px;
      line-height: 24px;
      color: #a7b2cd;
      background: #fff;
    }
  }

  .description, .aboveText {
    color: $gray-700;
    margin: 8px 0 0;
    font-size: 13px;

    &.right {
      text-align: end;
    }

    &.left {
      text-align: start;
    }
  }

  .aboveText {
    margin: 0 0 5px 0;
  }

  .errorMessage {
    color: $error;
    margin: 4px 0 0;
    font-size: 13px;
  }

  &.disabled {
    .inputHolder {
      background-color: $input-disabled-background-color;
    }

    .input {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.label {
  display: block;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.1;
  color: #1f342f;
}

.inputHolder {
  position: relative;
  display: flex;
  align-items: center;
  height: calc($btn-height - 10px);
  padding: $input-padding;
  background-color: $input-background-color;
  border: $input-border-width solid $input-border-color;
  width: 100%;
  border-radius: $input-border-radius;
  transition: $input-transition;

  @include media-breakpoint-up(md) {
    height: $input-height;
  }

  &:hover,
  &:focus,
  &.active {
    border-color: $input-hover-border-color;
  }

  &.error {
    border-color: $error;

    .inputAdornment,
    .inputAdornmentLeft,
    .hashtag {
      border-color: $error;
    }
  }
}

.input {
  @include placeholder {
  	color: $input-placeholder-color;
    transition: opacity 0.3s;
  };

  display: block;
  width: 100%;
  background-color: $input-background-color;
  font-size: $input-font-size;
  line-height: $input-line-height;
  color: $input-color;
  border: none;
  border-radius: 0;
  padding: 0;
  outline: none;

  &.error {
    border-color: $error;
  }

  &:disabled {
    color: rgba($secondary, 0.65);
  }

  &:focus {
    @include placeholder {
      opacity: 0;
    };
  }
}

.inputAdornment {
  margin: 0 0 0 2px;
  font-size: 14px;
  line-height: 1.05;
  color: #a7b2cd;
}

.inputAdornmentLeft {
  margin: 0 2px 0 0;
  font-size: 14px;
  line-height: 1.01;
  color: #a7b2cd;

  &.gray {
    color: #a7b2cd;
  }

  &.black {
    color: $secondary;
  }
}

@media all and (max-width: $layout-flip-var) {
  .label {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16px;
  }
}

.field .input[readonly] {
  color: $gray-700;
}

.hashtag {
  @include flexCenter;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  font-size: 25px;
  font-weight: 700;
  padding: 0;
  background-color: #f5f5f5;
  border-right: 0;
  border-radius: $input-border-radius 0 0 $input-border-radius;
  width: 38px;
}

.hashtagInput {
  margin-left: 28px;
}
