@import "../../styles/mixins";

.modalityList {
  display: flex;
  flex-wrap: wrap;
  margin: -20px -16px 0;
  padding: 0;
  list-style: none;

  @include layout-flip {
    margin: 0 -14px;
    overflow: auto;
  }
}

.modalityListItem {
  margin: 20px 16px 0;

  @include layout-flip {
    margin: 20px 14px 0;
  }
}
