@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

.emailSuccess {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 497px;
  padding: 54px;
  background-image: url("../../../../../assets/images/Paper airplane.png");
  background-repeat: no-repeat;
  background-position: 50% 54px;
  overflow: hidden;

  @include layout-flip {
    max-width: none !important;
    height: 512px;
    padding: 45px 30px;
    border-radius: 30px 30px 0 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 44%;
      width: 12%;
      height: 2px;
      margin: 16px 0 0 0;
      background-color: #a7b3ce;
      border-radius: 20px;
    }
  }

  img {
    width: 88px;
    height: 88px;
    margin-bottom: 16px;

    @include layout-flip {
      width: 68px;
      height: 68px;
    }
  }

  h1 {
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: #1f342f;

    @include layout-flip {
      font-size: 23px;
      line-height: 34px;
      letter-spacing: 0.25px;
    }
  }

  small {
    margin-bottom: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: $gray-700;

    @include layout-flip {
      margin: 0 30px 47px;
      letter-spacing: -0.01em;
    }
  }

  button {
    width: 385px;

    @include layout-flip {
      width: 87%;
    }
  }
}
