@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.List {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  & > * {
    flex-grow: 1;
  }
}

.testsList {
  @include listReset;

  &__item {
    background-color: $white;
    border: 1px solid $gray-200;
    padding: 24px 16px;

    @include media-breakpoint-up(md) {
      padding: 28px 32px;
    }

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    + .testsList__item {
      margin-top: -1px;
    }
  }
}