@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins";

.availability {
  .chatStatus {
    display: flex;
    align-items: center;
    margin: 0 0 44px;

    @include layout-flip {
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 28px;
    }

    .description {
      margin: 0 50px 0 0;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.01em;

      @include layout-flip {
        margin: 0 0 24px;
      }

      .name {
        display: flex;
        align-items: center;

        .chatStatusText {
          margin: 0 10px 0 0;
          color: $secondary;
        }
      }

      small {
        color: $gray-700;
      }
    }
  }

  .disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}
