@import "../../../../../common/styles/_variables.scss";

.age-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 150px;
  min-height: 180px;
  padding: 15px;
  text-align: center;
  border: 1px solid $gray-200;
  border-radius: 6px;

  &__icon {
    margin: 0 0 10px;
  }

  &__title {
    margin: 0 0 5px;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.2;
    color: $secondary;
  }

  &__age {
    margin: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.2;
    color: $gray-700;
  }
}
