@import "../../styles/variables";

.h2 {
  color: $secondary;
  font-family: $headings-font-family;
  font-size: 50px;
  line-height: 63px;
  margin: 0;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-justify {
  text-align: justify;
}
