@import "../../styles/mixins";

.root {
  position: relative;
  display: flex;
  align-items: center;

  .button {
    @include clean-btn;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 75px;
    height: 75px;
    margin: -35px 10px 0;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 50%;

    @include layout-flip {
      display: none;
    }
  }

  .slider {
    max-width: 1195px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 60px;

    .swiper-slide-duplicate {
      display: none !important;
    }

    .slide {
      height: auto;
      display: flex;
      justify-content: center;

      @include layout-flip {
        justify-content: unset;
      }
    }

    :global .swiper-wrapper {
      align-items: stretch;
    }

    :global .swiper-pagination {
      z-index: 10;
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      text-align: center;
    }

    :global .swiper-pagination-bullet {
      cursor: pointer;
      display: inline-block;
      width: 25px;
      height: 25px;
      margin: 0 10px !important;
      background: #FFFFFF;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      opacity: 0.8;

      @include layout-flip {
        width: 15px;
        height: 15px;
      }

      & + .swiper-pagination-bullet {


        @include layout-flip {
          //margin: 0 0 0 10px;
        }
      }

      &.swiper-pagination-bullet-active {
        cursor: default;
        background: #3ceac1;
      }
    }
  }
}
