@import "../../../styles/variables";
@import "../../../styles/mixins";

.desktopAside {
  width: 285px;
  padding: 45px 35px;
  background-color: #f2f6fb;

  .logo {
    width: 212px;
    height: 41px;
    margin-bottom: 80px;
  }

  .tabs {
    width: 220px;

    .btn {
      padding-left: 18px;
      padding-right: 18px;
      text-align: left;
    }
  }
}
