@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.root {
  position: relative;
  max-width: 954px;
  margin: 0 auto;
  padding: 0 50px 105px;

  @include layout-flip {
    padding: 0 24px 26px;
  }

  .title {
    margin: 0 0 50px;
    text-align: center;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.1;
    color: #000000;

    @include layout-flip {
      margin: 0 0 25px;
      font-size: 33px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    margin: 0 0 50px;

    @include layout-flip {
      display: block;
      margin: 0 0 25px;
    }
  }

  .search {
    position: relative;
    width: 100%;
    margin: 0 30px 0 0;

    @include layout-flip {
      margin: 0 0 10px;
    }

    :global .search {
      margin: 0;

      @include layout-flip {
        margin: 0 auto 10px;
        max-width: 500px;
      }

      @media (max-width: 568px) {
        max-width: none;
      }
    }
  }

  .button {
    display: flex;
    max-width: 250px;
    width: 100%;

    @include layout-flip {
      margin: 0 auto;
      max-width: 500px;
      padding: 18px 47px;
    }

    @media (max-width: 568px) {
      max-width: none;
    }
  }

  .desc {
    display: flex;
    align-items: center;
    max-width: 596px;
    margin: 0 auto;

    @include layout-flip {
      justify-content: center;
    }
  }

  .image {
    margin: 0 30px 0 0;

    @include layout-flip {
      height: 40px;
      margin: 0 14px 0 0;
    }
  }

  .text {
    font-weight: 400;
    font-size: 25px;
    line-height: 1.1;
    color: #68768b;

    @include layout-flip {
      font-size: 13px;
    }

    b {
      font-weight: 700;
    }
  }
}
