@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.chatStatus {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 32px 0 0;
  padding: 16px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: $gray-700;
  border: solid 1px #D4E1F2;
  background: #FFFFFF;

  @include layout-flip {
    margin: 0 auto;
  }

  p {
    margin: 0;
    text-transform: capitalize;
  }

  .circle {
    display: inline-block;
    width: 11px;
    height: 11px;
    margin: 2px 8px 0 10px;
    border-radius: 11px;
    flex-shrink: 0;
  }

  .circle.green {
    background: $primary;
  }

  .circle.yellow {
    background: #ffda79;
  }

  .circle.red {
    background: #fe4949;
  }

  .circle.grey {
    background: #6c6c6c;
  }
}