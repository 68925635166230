@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

.emailSuccess {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 497px;
  padding: 54px;
  background-image: url("../../../../../assets/images/Paper airplane.png");
  background-repeat: no-repeat;
  background-position: 50% 54px;
  overflow: hidden;

  @include layout-flip {
    max-width: none !important;
    height: 512px;
    padding: 45px 30px;
    border-radius: 30px 30px 0 0;

    &::before {
      content: "";
      position: absolute;
      left: 44%;
      top: 0;
      width: 12%;
      height: 2px;
      margin: 16px 0 0 0;
      background-color: #a7b3ce;
      border-radius: 20px;
    }
  }

  svg,
  img {
    width: 88px;
    height: 88px;
    margin: 0 auto 16px;

    @include layout-flip {
      width: 68px;
      height: 68px;
    }
  }

  h1 {
    margin: 0 0 8px;
    text-align: center;
    font-weight: 400;
    font-size: 30px;
    line-height: 1.2;
    color: #1f342f;

    @include layout-flip {
      font-size: 23px;
      line-height: 34px;
      letter-spacing: 0.25px;
    }
  }

  small {
    margin: 0 0 40px;
    text-align: center;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.1;
    letter-spacing: -0.02em;
    color: #68768b;

    @include layout-flip {
      margin-left: 30px;
      margin-right: 30px;
      letter-spacing: -0.01em;
    }
  }

  button {
    width: 385px;

    @include layout-flip {
      width: 87%;
    }
  }
}
