@import "../../../common/styles/_variables.scss";
@import "../../../common/styles/mixins.scss";

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-modal;
  padding: 0 8px;
  overflow: auto;
  outline: none;
  background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity) !important;

  @include media-breakpoint-up(sm) {
    padding: 0 $grid-gutter;
  }

  &__dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - $modal-dialog-margin * 2);
    margin: $modal-dialog-margin auto;
  }

  &__content {
    position: relative;
    background-color: $modal-content-bg;
    border-radius: $modal-content-border-radius;
    width: 100%;
  }
}