@import "../../../../common/styles/variables";

.radiobutton {
  @media (max-width: 576px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.form + div {
  button[type="submit"] {
    max-width: 100%;
  }
}

.search :global .search {
  margin: 0;
  padding: 17px 16px;
}
