@import "../../../../../../common/styles/variables";
@import "../../../../../../common/styles/mixins";

.resultBlock {
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: 8px;
  padding: 35px 15px;

  @include media-breakpoint-up(md) {
    padding: 64px 32px 48px;
  }

  &__scoreBlock {
    position: relative;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 60px;
    }
  }

  &__scoreBlockValues {
    @include sizeElement(240px);
    @include flexCenter;

    position: absolute;
    top: 180px;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    text-align: center;
    padding: 15px;

    @include layout-flip {
      @include sizeElement(150px);
    }
  }

  &__scoreBlockNumber {
    @include layout-flip {
      font-size: 32px !important;
    }
  }

  &__scoreBlockName {
    @include layout-flip {
      font-size: 14px !important;
    }
  }

  &__noteBlock {
    position: relative;
    background-color: $white;
    border: 1px solid $gray-200;
    border-radius: 12px;
    padding: 35px 15px 20px;

    @include media-breakpoint-up(sm) {
      padding: 30px 20px 30px 74px;
    }
  }

  &__noteBlockIcon {
    @include sizeElement(40px);
    @include inlineFlexCenter;

    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 5;
    border: 2px solid $gray-200;
    background-color: $white;
    border-radius: 50%;
    font-size: 24px;
    color: $gray-700;

    @include media-breakpoint-up(sm) {
      top: 20px;
      right: auto;
      left: 20px;
    }
  }

  &__noteBlockImg {
    flex-shrink: 0;
    width: 200px;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
      width: 140px;
      margin: 0;
    }

    @include media-breakpoint-up(md) {
      width: 180px;
    }
  }
}