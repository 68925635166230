@import "../../styles/variables";
@import "../../styles/mixins";

.container {
  width: 100%;
  height: 2px;
  background: $gray-200;

  .accent {
    height: 2px;
    background: $primary;
  }
}