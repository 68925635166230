@import "../../styles/variables";
@import "../../styles/mixins";

$strong-color: #26d5b6;

.label {
  display: block;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 19px;
  color: #1f342f;
}

.password {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc($btn-height - 10px);
  padding: $input-padding;
  background-color: $input-background-color;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;;
  transition: $input-transition;

  @include media-breakpoint-up(md) {
    height: $input-height;
  }

  &:hover,
  &:focus,
  &.active {
    border-color: $input-hover-border-color;
  }

  &.invalid {
    border-color: red;
  }

  &__input {
    @include placeholder {
      color: $input-placeholder-color;
      transition: opacity 0.3s;
      font-size: $input-font-size;
    };

    width: calc(100% - 30px);
    font-size: 20px;
    line-height: $input-line-height;
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none;
    color: $input-color;
    transition: $input-transition;

    &:focus {
      @include placeholder {
        opacity: 0;
      };
    }
  }

  &__visible {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__tip {
    margin: 5px 0 0;
    font-size: 13px;
    line-height: 15px;
    color: #68768b;
    margin-bottom: 8px;
    text-align: right;

    span {
      display: block;
    }
  }

  &__strength {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__status {
    display: flex;
    flex-grow: 1;

    &.--weak .true {
      background: $error;
    }

    &.--fine .true {
      background: $warning;
    }

    &.--strong .true {
      background: $strong-color;
    }
  }

  &__status-line {
    width: 25%;
    margin-right: 8px;
    height: 3px;
    background: #d4e1f2;
    border-radius: 5px;
  }

  &__strength_text {
    font-size: 13px;
    line-height: 15px;
    margin-left: 5px;
    white-space: nowrap;
    flex-basis: 105px;
    text-align: right;

    &.--weak {
      color: $error;
    }

    &.--fine {
      color: $warning;
    }

    &.--strong {
      color: $strong-color;
    }
  }
}

.error_msg {
  margin: 4px 0 0;
  font-size: 13px;
}

@media all and (max-width: 480px) {
  .label {
    margin-bottom: 10px;
  }
}
