@import "../../../../../../common/styles/mixins";

.editContactInner {
  max-width: 804px;
}

.search :global .search {
  margin: 0;
  padding: 17px 16px;
}

.editContactRow {
  @include layout-flip {
    margin: 0 0 16px;
    padding: 0;

    & + .editContactRow {
      margin: 0 0 32px;
    }
  }
}

.editContactCheckbox {
  margin: 0 0 24px;

  &:last-of-type {
    margin: 0 0 48px;
  }
}

.editContactButton {
  max-width: 386px;
  width: 100%;
}
