@import "../../common/styles/mixins";

.signInput {
  margin-bottom: 16px;

  @include layout-flip {
    margin-bottom: 13px;
  }
}

.signCheckbox.signCheckboxWrapper label {
  display: flex;
  align-items: flex-start;

  span {
    text-align: justify;
  }

  @include layout-flip {
    span {
      //max-width: 290px;
    }

    &:before {
      margin: -5px 8px 0 0;
    }
  }
}

.signAlready {
  @include layout-flip {
    text-align: left;
    font-size: 14px;
  }
}
