@import "../../../../../common/styles/mixins";
@import "../../../../../common/styles/variables";

.root {
  display: flex;
}

.container {
  margin-bottom: 40px;
  max-width: 800px;
  flex-basis: 800px;

  @include layout-flip {
    margin-bottom: 16px;
  }
}

.options {
  margin-top: 0;
}
