@import "common/styles/variables";
@import "common/styles/mixins";

.mainButton {
  display: inline-flex;
  height: 58px;
  cursor: pointer;
  padding: 18px 56px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #FE4949;
  border: none;
  color: #EFF8FE;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 24px;
}