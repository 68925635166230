@import "../../../../../common/styles/mixins";

.infoCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;

  & > p:first-of-type {
    grid-column: 1 / -1;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 24px;
  }

  @include xl {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(xl) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include layout-flip {
    grid-template-columns: 1fr;
    column-gap: 0;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.title {
  font-family: $headings-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 43px;
  color: $secondary;
  margin: 7px 0 0 0;

  @include layout-flip {
    display: none;
  }
}

.search {
  flex: 1;
  max-width: 345px;

  :global .search {
    justify-content: flex-start;
    padding: 22px 24px;
    border-radius: 6px;

    .search__icon {
      margin: -5px 16px 0 0;
    }

    .search__input {
      &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: -0.01em;
        color: #68768b;
      }
    }
  }

  @include layout-flip {
    width: 100%;

    :global .search {
      padding: 17px 16px;

      .search__icon {
        margin: -2px 10px 0 0;
      }

      svg {
        path {
          fill: #68768b;
        }
      }

      .search__input {
        &::placeholder {
          font-size: 16px;
        }
      }
    }
  }
}
