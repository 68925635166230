@import "../../../common/styles/variables.scss";
@import "../../../common/styles/mixins";

.addNewCard {
  max-width: 386px;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    margin: 0 0 48px;

    button {
      margin: 0 16px 0 0;
    }

    h1 {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      line-height: 43px;
      color: $secondary;
    }
  }

  form {
    .row {
      margin: 0 0 24px;
    }

    .twoItemsRow {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
      margin: 0 0 40px;
    }
  }
}
