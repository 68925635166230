@import "../../styles/variables";
@import "../../styles/mixins";

body {
  .react-select {
    &.size-sm {
      .react-select__control {
        height: $input-sm-height;
        padding: $input-sm-padding;
      }
    }

    &.placeholder-as-label {
      .react-select__placeholder {
        color: $input-color;
      }
    }

    &__container {
      z-index: 10;
    }

    &__control {
      display: flex;
      cursor: pointer;
      border: $input-border-width solid $input-border-color;
      border-radius: $input-border-radius;
      font-size: $input-font-size;
      line-height: $input-line-height;
      color: $input-color;
      padding: $input-padding;
      height: calc($btn-height - 10px);
      min-height: auto;
      box-shadow: none;
      transition: $input-transition;

      @include media-breakpoint-up(md) {
        height: $input-height;
      }

      &:hover,
      &:focus {
        border-color: $input-hover-border-color;
      }

      &--is-focused {
        border-color: $input-hover-border-color;

        .react-select__indicator {
          color: $input-hover-border-color;

          &:hover {
            color: $input-hover-border-color;
          }
        }
      }
    }

    &__value-container {
      padding: 0;
      margin: 0;
    }

    &__single-value {
      margin: 0;
    }

    &__input-container {
      margin: 0;
      padding: 0;
    }

    &__placeholder {
      color: $input-placeholder-color;
      margin: 0;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      @include sizeElement(24px);
      @include flexCenter;

      color: $secondary;
      transition: color 0.3s;
      padding: 0;
      font-size: 24px;

      &:after {
        content: '\e90a';
        font-family: icomoon;
        display: inline-block;
      }

      &:hover {
        color: $secondary;
      }

      svg {
        display: none;
      }
    }

    // dropdown
    &__menu {
      box-shadow: 0px 4px 30px 0px rgba($secondary, 0.12);
      border: 1px solid $gray-200;
      border-radius: 4px;
      margin: 4px 0;
    }

    &__menu-list {
      @include customScrollbar(2px, transparent, $primary);
    }

    &__option {
      cursor: pointer;
      transition: background-color 0.3s;
      padding-left: 14px;
      padding-top: 12px;
      padding-bottom: 12px;

      &--is-focused {
        background-color: $mint;
      }

      &--is-selected {
        background-color: transparent;
        font-weight: 700;
        color: inherit;

        &.react-select__option--is-focused {
          background-color: $mint;
        }
      }
    }

    &.timepicker-select {
      width: 80px;
    }
  }
}

.high-index {
  z-index: 11;
}