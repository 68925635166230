@import "../../../../common/styles/variables.scss";
@import "../../../../common/styles/mixins";

.listOfCalls {
  @include listReset;

  @include min-layout-flip {
    max-width: 760px;
  }

  &__item {
    background-color: $white;
    border: 1px solid $gray-200;
    padding: 20px;

    @include media-breakpoint-up(lg) {
      padding: 16px 42px;
    }

    &:first-child {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    &:last-child {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    + .listOfCalls__item {
      margin-top: -1px;
    }
  }
}