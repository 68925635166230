@import "../../../../../../common/styles/mixins";

.root {
  .form {
    max-width: 806px;

    @include layout-flip {
      margin: 10px 0 0;
    }
  }

  .lastRow {
    max-width: 387px;
  }

  .button {
    margin: 8px 0 0;
    max-width: 387px;
    width: 100%;
  }
}
