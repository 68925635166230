@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

.popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;

    .confirmScreen {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    @include layout-flip {
        display: block;
        padding: 40px 15px;
    }

    .container {
        gap: 15px;
        width: fit-content;
    }

    .character {
        border: 1px solid #D4E1F2;
        border-radius: 6px;
        width: 42px;
        height: 50px;
        font-size: 16px;
        line-height: 45px;
        flex-basis: auto;
        flex-grow: unset;
    }

    .inactive {
        background-color: inherit;
    }

    .selected {
        border: none;
        outline: 2px solid #3ceac1;
    }

    .error {
        outline: 2px solid red;
    }

    .errorText {
        font-size: 16px;
        line-height: 19px;
        color: #FE4949;
    }

    .resendLink {
        margin: 50px 0 0;
    }

    .withError {
        margin: 0;
    }

    span {
        cursor: pointer;
        color: #26d4b6;
        font-weight: 500;
    }

    .disabled {
        pointer-events: none;
        margin: 20px 0 0;

        span {
            color: #68768B;
            opacity: 0.5;
        }
    }
}

.popupContainer {
    max-width: 387px;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
        max-width: 327px;
    }
}

.popupCloseButton {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
    background: none;
    border: none;
    cursor: pointer;

    @include layout-flip {
        top: 15px;
        right: 15px;
        margin: 0;
        padding: 0;
    }
}

.popupTitle {
    margin: 0 0 15px;
    padding: 0;
    text-align: center;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 30px;
    line-height: 1.3;
    color: $secondary;

    @include media-breakpoint-down(sm) {
        margin: 0 0 20px;
    }
}

.popupSubtitle {
    display: block;
    margin: 0 0 16px;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    color: $secondary;
    text-align: center;
}

.popupInput {
    width: 100%;
    margin: 0 0 32px;

    @include media-breakpoint-down(sm) {
        margin: 0 0 17px;
    }
}

.popupButton {
    max-width: 100%;
    width: 100%;
    margin: 0 0 0;

    @include media-breakpoint-down(sm) {
        margin: 0;
    }
}
