@import "../../styles/variables";
@import "../../styles/mixins";

.root {
  display: flex;
  height: 100%;
  min-height: 100vh;
  width: 100%;

  @include layout-flip {
    flex-direction: column;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fbfcff;

    @include layout-flip {
      margin: 0 0 30px;
      padding: 16px;
      background: none;
    }

    main {
      position: relative;
      z-index: 3;
      width: 100%;
      padding: 56px 40px 64px;

      @include layout-flip {
        padding: 0;
      }
    }
  }

  .footer {
    @include layout-flip {
      margin: auto 0 0;
    }
  }
}
