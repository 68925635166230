@import "../../styles/mixins";

.root {
  height: auto;
  padding: 20px 24px 18px;
  border-radius: 30px 30px 0 0;
  overflow: hidden;

  .mobileLine {
    width: 15%;
    height: 2px;
    margin: 0 auto 8px;
    background: #a7b3ce;
  }

  .title {
    margin: 0 0 30px;
    padding: 0;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: -0.01em;
    color: #1f342f;
  }

  .row {
    margin: 0 0 30px;
    padding: 0;
    border: 0;

    &:last-child {
      margin: 0;
    }
  }

  .label {
    display: block;
    margin: 0 0 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.18;
    color: #1f342f;
  }

  .field {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
    padding: 0 0 0 20px;

    &:last-child {
      margin: 0;
    }

    svg {
      display: none;
      margin: 0 10px 0 0;
    }

    &.active {
      padding: 0;

      button {
        font-weight: 600;
        color: #1f342f;
      }

      svg {
        display: inline-block;
      }
    }
  }

  .button {
    @include clean-btn;

    font-weight: 400;
    font-size: 18px;
    line-height: 1.1;
    color: #68768b;
  }
}
