@import "../../../../../../common/styles/mixins";

.root {
  @include layout-flip {
    margin: 9px 0 0;
  }

  .mobileRow {
    @include layout-flip {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .header {
    margin: 0 0 40px;
  }

  .addButton {
    margin: 0 0 32px;
    padding: 0;
  }

  .button {
    max-width: 386px;
    width: 100%;
  }

  .item {
    max-width: 818px;
    margin: 0 0 16px;

    &:last-of-type {
      margin: 0 0 32px;

      @include layout-flip {
        margin: 0 0 16px;
      }
    }

    @include layout-flip {
      &:first-of-type {
        margin: 0 0 32px;
      }
    }
  }

  .cardButton {
    svg {
      path {
        &:not(:last-child) {
          stroke: #000000;
        }
      }
    }
  }
}
