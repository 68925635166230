@import "../../styles/variables";

.root {
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: $gray-700;

  cursor: pointer;

  svg {
    transform: rotate(90deg);
    margin: 0 10px 1px 0;

    path {
      stroke: $gray-700;
      fill: $gray-700;
    }
  }
}
