@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?f65uki') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?f65uki') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?f65uki##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-video-end-call {
  &:before {
    content: $icomoon-video-end-call; 
  }
}
.icomoon-microphone-off {
  &:before {
    content: $icomoon-microphone-off; 
  }
}
.icomoon-microphone {
  &:before {
    content: $icomoon-microphone; 
  }
}
.icomoon-camera-off {
  &:before {
    content: $icomoon-camera-off; 
  }
}
.icomoon-camera {
  &:before {
    content: $icomoon-camera; 
  }
}
.icomoon-remove {
  &:before {
    content: $icomoon-remove; 
  }
}
.icomoon-arrow-small-thin-down {
  &:before {
    content: $icomoon-arrow-small-thin-down; 
  }
}
.icomoon-arrow-small-thin-up {
  &:before {
    content: $icomoon-arrow-small-thin-up; 
  }
}
.icomoon-arrow-small-thin-left {
  &:before {
    content: $icomoon-arrow-small-thin-left; 
  }
}
.icomoon-arrow-small-thin-right {
  &:before {
    content: $icomoon-arrow-small-thin-right; 
  }
}
.icomoon-call {
  &:before {
    content: $icomoon-call; 
  }
}
.icomoon-calendar {
  &:before {
    content: $icomoon-calendar; 
  }
}
.icomoon-decline {
  &:before {
    content: $icomoon-decline; 
  }
}
.icomoon-approve {
  &:before {
    content: $icomoon-approve; 
  }
}
.icomoon-arrow-small-left {
  &:before {
    content: $icomoon-arrow-small-left; 
  }
}
.icomoon-arrow-small-right {
  &:before {
    content: $icomoon-arrow-small-right; 
  }
}
.icomoon-arrow-small-top {
  &:before {
    content: $icomoon-arrow-small-top; 
  }
}
.icomoon-arrow-small-bottom {
  &:before {
    content: $icomoon-arrow-small-bottom; 
  }
}
.icomoon-lamp {
  &:before {
    content: $icomoon-lamp; 
  }
}
.icomoon-checkmark {
  &:before {
    content: $icomoon-checkmark; 
  }
}

