@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.field {
  margin: 0 0 16px;

  @include layout-flip {
    margin: 0 0 13px;
  }

  .label {
    display: block;
    margin: 0 0 16px;
    font-size: 16px;
    line-height: 1.1;
    color: $secondary;

    @include layout-flip {
      margin: 0 0 10px;
    }
  }

  .inputDropdown {
    transition: border-color 0.3s;
  }

  .inputContainer {
    & > input.inputField {
      @include placeholder {
        color: $input-placeholder-color;
        transition: opacity 0.3s;
      };

      display: block;
      width: 100%;
      height: calc($btn-height - 10px);
      border: $input-border-width solid $input-border-color;
      transition: $input-transition;
      font-size: $input-font-size;
      line-height: $input-line-height;
      color: $input-color;
      border-radius: $input-border-radius;
      letter-spacing: 0;
      background-color: $input-background-color;
      border: $input-border-width solid $input-border-color;

      @include media-breakpoint-up(md) {
        height: $input-height;
      }

      &:focus {
        @include placeholder {
          opacity: 0;
        };
      }

      &:hover,
      &:focus {
        border-color: $input-hover-border-color;

        & + .inputDropdown {
          border-left-color: $input-hover-border-color;
          border-top-color: $input-hover-border-color;
          border-bottom-color: $input-hover-border-color;
        }
      }
    }

    &.inputContainerError {
      .inputField {
        border-color: $error;
      }

      .inputDropdown {
        border-top-color: $error;
        border-bottom-color: $error;
        border-left-color: $error;
      }
    }

    :global .country-list {
      border: 1px solid $gray-200;
      border-radius: 5px;
    }
  }

  .errorMessage {
    color: $error;
    margin: 4px 0 0;
    font-size: 13px;
  }
}
