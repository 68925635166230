@import "~common/styles/variables";
@import "~common/styles/mixins";

.container {
  border: 1px $error solid;
  border-radius: 8px;
  background-color: rgba($color: $error, $alpha: 0.3);
  padding: 8px 16px;
  color: $error;
  margin: 0 auto 30px;
  width: fit-content;

  &.text {
    width: auto;
    border: 0;
    margin: 10px 0 20px;
    padding: 0;
    background: none;
  }
}
