@import "../../styles/variables";
@import "../../styles/mixins";

.inputTime {
  position: relative;

  .buttons {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
      margin: 0;
      display: block;
      @include clean-btn;
      background: none;
      border: none;
      padding: 5px;
    }
  }
}
