@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.wrapper {
  @include layout-flip {
    align-items: center;
  }

  .changePlanSuccessModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;

    @include layout-flip {
      padding: 40px;
    }

    button {
      @include clean-btn;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    img {
      width: 88px;
      height: 88px;
      margin: 0 0 30px;

      @include layout-flip {
        margin: 0 0 20px;
      }
    }

    h1 {
      margin: 0 0 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 46px;
      line-height: 58px;
      color: $secondary;

      @include layout-flip {
        margin: 0 0 10px;
        font-size: 28px;
        line-height: 35px;
      }
    }

    p {
      margin: 0 0 30px;
      text-align: center;
      font-family: $headings-font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #828282;

      @include layout-flip {
        margin: 0 0 20px;
        font-size: 16px;
        line-height: 20px;
      }

      span {
        display: block;
      }
    }

    .goToDashboard {
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-decoration: underline;
      color: $accent-color;
      cursor: pointer;
    }
  }
}
