@import "../../../../common/styles/variables";

.input {
  width: 100%;
  margin-bottom: 40px;
}

div.actions {
  @media (max-width: 600px) {
    position: fixed;
  }
}
