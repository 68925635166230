.like {
  position: relative;
  width: 44px;
  height: 44px;
  margin: 0;
  padding: 0;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border: 1px solid #d4e1f2;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: 0.25s all ease-out 0s;

  &:hover {
    background: #22c7a0;
    border-color: #22c7a0;
  }

  &.liked {
    background: #3ceac1;
    border-color: #3ceac1;
    outline: none;

    &:hover {
        background: #22c7a0;
        border-color: #22c7a0;
      }
  }


  &:disabled {
    opacity: 0.8;
    pointer-events: none;
  }
}
