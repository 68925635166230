@import "../../styles/variables";

.range {
  &__track {
    margin-top: 69px;
    margin-bottom: 30px;
    width: 100%;
    height: 2px;
    background: #d4e1f2;

    @media (max-width: 900px) {
      margin-left: 24px;
      margin-right: 24px;
      width: calc(100% - 48px);
    }
  }
  &__thumb {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #fff;
    border: 7px solid #3ceac1;
    box-sizing: border-box;
    box-shadow: 0 15px 30px rgba(15, 186, 185, 0.2);
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease-in-out 0s;
    transform: translate(-13px, -12px);

    &:hover,
    &:focus {
      border-color: #22c7a0;
    }

    &:before {
      content: attr(aria-valuenow) " years";
      position: absolute;
      padding: 10px;
      top: -54px;
      transform: translateX(calc(-50%));
      left: 50%;
      right: 50%;
      display: table;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      white-space: nowrap;
      color: #26d5b6;
      background: #defaf6;
      border-radius: 8px;
    }

    &:after {
      content: "";
      position: absolute;
      top: -19px;
      transform: translateX(calc(-50%)) rotate(45deg);
      left: 50%;
      right: 50%;
      display: table;
      width: 8px;
      height: 8px;
      background: #defaf6;
    }
  }

  &__row {
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #68768b;
  }
}
