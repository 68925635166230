html {
  height: 100%;
  -webkit-text-size-adjust: 100%;
}

#root {
  main {
    height: 100%;
  }
}

body {
  min-width: 320px;
  height: 100%;
  background-color: $body-bg;
  font-size: $body-font-size;
  line-height: $body-line-height;
  letter-spacing: $body-letter-spacing;
  font-family: $body-font-family;
  font-weight: $body-font-weight;
  color: $body-color;
}

.container-normal {
  max-width: $container-normal-width;
  width: 100%;
  margin: 0 auto;
  padding: 0 $grid-gutter;
}