@import '../../styles/variables';

.showmore {
  &__container {
    position: relative;
    overflow: hidden;

    &.two-columns {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-column-gap: 64px;
      justify-content: center;
    }
  }
  &.expanded {
    .showmore__container {
      overflow: initial;
    }
    .showmore__btn {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  &__btn {
    margin: 40px auto 0;
    display: table;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #26D5B6;
    cursor: pointer;
    svg {
      transition: all .2s ease;
      margin-left: 8px;
    }
  }
}