@import "../../../../common/styles/variables";
@import "../../../../common/styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background-image: url("../../../../assets/images/backgrounds/plans.png");
  background-repeat: no-repeat;
  background-size: cover;

  @include layout-flip {
    background: none;
  }
}

.subscriptionPlansLayout {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 750px;
  margin: 0 0 60px;
  padding: 0 0 36px;

  @include layout-flip {
    justify-content: flex-start;
    min-height: auto;
    background: none;
    margin: 0;
    padding: 0;
  }
}

.footer {
  margin: auto 0 0;

  @media (min-width: 900px) {
    background-image: url("../../../../assets/images/backgrounds/footer.png");
  }

  @media (min-width: 1536px) {
    padding: 64px 0 32px;
    background-image: url("../../../../assets/images/backgrounds/footer-background-4k.png");
    background-position: bottom;
    background-size: auto;
  }
}
