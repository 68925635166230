@import "../../../../../../common/styles/mixins";

.editClientFocusCards {
  margin: 0 0 37px;

  @include layout-flip {
    margin: 0 0 24px;
  }

  @media (max-width: 600px) {
    & > div {
      margin: 0;

      & > div {
        justify-content: space-between;
        column-gap: 0;
      }
    }
  }

  & > div > div {
    @media (min-width: 1450px) {
      justify-content: flex-start;
      max-width: 100%;
    }

    & > div {
      @media (min-width: 1450px) {
        margin: 0 16px 0 0;
      }

      margin: 0 15px 15px 0;
    }
  }
}

.editClientFocusRow {
  max-width: 384px;

  & > div {
    min-height: auto;
    margin: 0 0 16px;

    @media (min-width: 600px) {
      white-space: nowrap;
    }
  }
}

.editClientFocusEthnicity {
  margin: 0;
  padding: 0;

  & > div {
    margin: 0 0 37px;

    @include layout-flip {
      margin: 0 0 24px;
    }
  }
}

.editClientFocusEthnicityList {
  max-width: 900px;

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  & > div {
    margin: 0 0 16px;
  }
}

.editClientFocusButton {
  width: 1000%;
  max-width: 384px;
}
