.row {
  position: relative;
  max-width: 387px;

  .stateWrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
  }
}

.radio {
  border: 0;
  padding: 0;

  &:not(:last-child) {
    margin-right: 16px;

    @media all and (max-width: 1024px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  :global .radiobutton__inner {
    width: 100%;
    padding: 16px;
    background: #feffff;
    border: 1px solid #d4e1f2;
    border-radius: 4px;
    transition: all 0.3s ease-in-out 0s;

    &:hover,
    &:focus {
      border: 1px solid #4adccc;
    }
  }

  input:checked + div {
    background: #defaf6;
    border: 1px solid #3ceac1;
  }
}

.error {
  margin: 5px 0 0;
  padding: 0;
  color: red;
}
