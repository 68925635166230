.form {
  margin-left: 410px;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
}

.skip {
  color: #26D4B6; // todo: should be reusable variable
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
}

