@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.patientsInformation {
  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background: white;
    border: 1px solid $gray-200;
    border-radius: 10px;

    @media (max-width: 1440px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @include layout-flip {
      padding: 0;
      background: none;
      border: none;
    }

    .fields {
      @media (max-width: 1440px) {
        margin: 0 0 40px;
      }

      @include layout-flip {
        margin: 0;
      }

      .field {
        margin: 0 0 48px;

        @include layout-flip {
          margin: 0 0 32px;
        }

        &:last-of-type {
          margin: 0;
        }

        .title {
          margin: 0 0 10px 0;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: $secondary;
        }

        p.value {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 20px;
          color: $gray-700;
        }

        div.value {
          display: flex;
          flex-wrap: wrap;
          margin: 20px 0 -24px 0;

          div {
            width: 150px;
            height: 164px;
            margin: 0 24px 24px 0;

            label {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
            }
          }
        }

        .ageCard {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 150px;
          height: 180px;
          padding: 40px 10px;
          border: 1px solid $gray-200;
          border-radius: 6px;

          svg {
            width: 42px;
            margin: 0 0 6px;
          }

          h3 {
            margin: 0 0 6px;
            text-align: center;
            font-size: 15px;
            line-height: 18px;
            font-weight: 400;
            color: $secondary;
          }

          p {
            margin: 0;
            font-size: 15px;
            line-height: 18px;
            color: $gray-700;
          }
        }
      }
    }
  }
}