@import "../../../../../common/styles/variables";
@import "../../../../../common/styles/mixins";

.form {
	max-width: 800px;
}

.animalAvatar {
	@include responsiveRatio(1, 1);

	position: relative;
	max-width: 120px;

	@include media-breakpoint-up(sm) {
		max-width: 152px;
	}

	&__img {
		@include absoluteCover;

		object-fit: cover;
		border-radius: 15px;
	}

	&__editIcon {
		@include flexCenter;
		@include sizeElement(36px);

		position: absolute;
		right: 0;
		bottom: 0;
		background-color: $white;
		padding: 0;
		border: none !important;
		border-radius: 100%;
		transform: translate(35%, 35%);
		box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);




		width: 48px !important;
		height: 36px !important;
		padding: 0 !important;
		border: none !important;
		border-radius: 100% !important;
		padding: initial !important;
		min-width: 60px !important;



		@include media-breakpoint-up(lg) {
			@include sizeElement(48px);
		}

		svg {
			margin: 0;
		}
	}

	&__removeAvatar {
		@include clean-btn;

		position: absolute;
		top: calc(100% + 3px);
		left: 0;
		width: auto;
		font-family: $headings-font-family;
		font-weight: 600;
		font-size: 12px;
		line-height: 1;
		color: $accent-color;
		border-bottom: 1px solid $accent-color;
		transition: border-color 0.3s;

		@include media-breakpoint-up(sm) {
			font-size: 14px;
		}

		&:hover,
		&:focus {
			border-color: transparent;
		}
	}

	&__hiddenInput {
		@include visuallyHidden;
	}
}