// Move styles to module

@import "../../../../../common/styles/mixins";

.root {
  .card {
    background: #ffffff;
    border: 3px solid #3ceac1;
    border-radius: 6px;
  }

  .cell {
    display: flex;
    align-items: center;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.1;
    letter-spacing: -0.01em;
    color: #1f342f;

    @include layout-flip {
      flex: 0 0 100% !important;
      max-width: 100%;
      width: 100%;
    }
  }

  .cardButton {
    max-width: 100px;
    width: 100%;
    padding: 10px 30px;

    @include layout-flip {
      max-width: 100%;
    }
  }

  .cellOld {
    @include min-layout-flip {
      margin: 0 0 0 auto;
    }
  }

  .oldPrice {
    position: relative;
    display: flex;
    align-items: center;
    font-family: $headings-font-family;
    font-size: 20px;
    line-height: 1.1;
    color: #a1aab7;

    span {
      margin: 0 6px 0 0;
      font-size: 10px;
      letter-spacing: -0.01em;
    }

    .cents {
      align-self: self-end;
      margin: 0;
      line-height: 1.3;
      font-size: 14px;
      font-weight: 400;
    }

    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-height: 35px;
      margin: auto;
    }
  }

  .newPrice {
    display: flex;
    align-items: center;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.1;
    color: #3ceac1;

    span {
      margin: 0 6px -2px 0;
      font-size: 15px;
      color: #68768b;
    }

    .cents {
      align-self: self-end;
      margin: 0;
      line-height: 1.3;
      font-size: 14px;
      font-weight: 400;
      color: #3ceac1;

      @include layout-flip {
        line-height: 1.35;
      }
    }
  }

  .photosList {
    display: flex;
    margin: 0 16px 40px;
    padding: 0;
    list-style: none;
    overflow: auto;

    @include media-breakpoint-down(lg) {
      margin: 0 -8px 0;
    }
  }

  .photosItem {
    position: relative;
    flex: 0 0 auto;
    max-width: 96px;
    width: 100%;
    height: 96px;
    margin: 0 16px;

    @include media-breakpoint-down(lg) {
      max-width: 76px;
      height: 76px;
      margin: 0 8px 0;
    }

    img {
      display: block;
      width: 100%;
      height: 96px;
      object-fit: cover;
      background: $gray-200;
      border-radius: 20px;

      @include media-breakpoint-down(lg) {
        height: 76px;
      }
    }
  }

  .icon {
    @include clean-btn;
    position: absolute;
    right: 6px;
    bottom: 6px;
    z-index: 5;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background: #3ceac1;
    border-radius: 4px;

    @media (min-width: 600px) {
      display: none;
    }
  }
}
