@import "common/styles/variables.scss";
@import "common/styles/mixins.scss";

.patientDetailsBlock {
  padding: 40px 20px 32px;
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: 16px;

  @include media-breakpoint-up(xl) {
    padding: 40px;
  }

  + .testsTakenBlock {
    margin-top: 12px;

    @include media-breakpoint-up(sm) {
      margin-top: 24px;
    }
  }

  &__avatar {
    @include sizeElement(156px);

    margin: 0 auto 30px;

    @include media-breakpoint-up(sm) {
      margin-left: 0;
      margin-right: 0;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }

    a {
      display: flex;
    }
  }

  &__btnDivider {
    width: 100%;
    height: 1px;
    background-color: $gray-200;
    margin: 8px 0;

    @include media-breakpoint-up(sm) {
      width: 1px;
      height: 32px;
      margin: 0 8px 0 20px;
    }
  }

  &__group {
    &:not(:last-child) {
      position: relative;
      padding-bottom: 30px;
      margin-bottom: 30px;

      @include media-breakpoint-up(xs) {
        padding-bottom: 40px;
        margin-bottom: 40px;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -20px;
        right: -20px;
        height: 1px;
        background-color: $gray-200;
        display: block;

        @include media-breakpoint-up(sm) {
          left: 0;
          right: 0;
        }
      }
    }
  }
}

.testsTakenBlock {
  padding: 28px 20px 32px;
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: 16px;

  @include media-breakpoint-up(xl) {
    padding: 40px;
  }
}

.testsTakenItem {
  display: block;
  padding: 10px 10px 10px 64px;
  background-color: $white;
  border: 1px solid $primary;
  border-radius: 10px;
  width: 100%;
  margin-top: 8px;
  background-image: url('../../../assets/images/tests-taken-item-icon.svg');
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: left 12px center;
  cursor: pointer;
  transition: background-color 0.3s;

  @include media-breakpoint-up(sm) {
    margin-top: 20px;
  }

  @include media-breakpoint-up(md) {
    padding: 20px 24px 20px 84px;
    background-size: 40px;
    background-position: left 24px center;
  }

  &:hover {
    background-color: rgba($primary, 0.1);
  }
}

.timepickerDots {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-up(sm) {
    padding: 0 2px;
  }

  &:before,
  &:after {
    @include sizeElement(2px);

    content: '';
    display: inline-block;
    background-color: $gray-700;
    margin: 2px 0;
  }
}

.testDetailBlock {
  background-color: $white;
  border: 1px solid $gray-200;
  border-radius: 16px;
  padding: 24px 20px 32px;

  @include media-breakpoint-up(lg) {
    padding: 20px 40px 40px;
  }

  &__totalBlock {
    display: inline-block;
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 12px 16px;

    @include media-breakpoint-up(lg) {
      padding: 12px 20px;
    }
  }

  &__list {
    @include listReset;

    counter-reset: numbering;
  }

  &__listItem {
    position: relative;
    padding: 16px 0 16px 32px;
    counter-increment: numbering;

    @include media-breakpoint-up(xs) {
      padding: 24px 20px 24px 40px;
    }

    &:first-child {
      padding-top: 0;

      &:before {
        top: -1px;

        @include media-breakpoint-up(xs) {
          top: -4px;
        }
      }
    }

    &:last-child {
      padding-bottom: 0;

      .testDetailBlock__listItemResult {
        bottom: 0;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-200;
    }

    &:before {
      @include sizeElement(24px);
      @include flexCenter;

      position: absolute;
      top: 16px;
      left: 0;
      content: counter(numbering);
      border-radius: 50%;
      border: 1px solid $primary;
      background-color: $mint;
      color: $secondary;
      font-size: 14px;
      font-weight: 600;

      @include media-breakpoint-up(xs) {
        @include sizeElement(28px);

        top: 21px;
      }
    }
  }
}