@import "../../../common/styles/variables.scss";
@import "../../../common/styles/mixins.scss";

.root {
  background: #fbfcff;
  padding: 0 0 46px;

  @media (max-width: 992px) {
    padding: 32px 0 26px;
  }

  header {
    margin: 0 0 48px;
    padding: 0 52px 0 36px;
  }

  .logo :global .logo {
    margin: 0 auto 0 0;
  }

  .wrapper {
    max-width: 1554px;
    padding: 0 36px;

    @media (max-width: 1200px) {
      padding: 0 23px;
    }
  }

  .title {
    margin: 0;
    padding: 0;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 34px;
    line-height: 1.18;
    color: #1d3b34;

    @include layout-flip {
      font-weight: 400;
      font-size: 23px;
      letter-spacing: 0.25px;
    }
  }

  .date {
    display: block;
    margin: 0 0 20px 27px;
    line-height: 1;
    font-weight: 500;
    font-size: 20px;
    color: #1d3b34;

    @include layout-flip {
      font-size: 14px;
    }
  }

  .text {
    margin: 0 0 20px;
    padding: 0;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 25px;
    line-height: 1.18;
    color: #68768b;

    @include layout-flip {
      font-size: 20px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
  }

  .goBackButton {
    @include clean-btn;
    display: inline-block;
    margin: 0 16px 0 0;

    .angle {
      width: 11px;
      height: 11px;
      transform: translateX(3px) rotate(45deg);
      border: 2px solid $secondary;
      border-color: transparent transparent $secondary $secondary;

      @include layout-flip {
        border-color: transparent transparent $gray-700 $gray-700;
      }
    }
  }

  .btn {
    @include layout-flip {
      max-width: 100%;
      width: 100%;
      margin: 70px 0 0;
    }
  }

  .testDetailBlock {
    background-color: $white;
    border: 1px solid $gray-200;
    border-radius: 16px;
    padding: 24px 20px 32px;

    @include media-breakpoint-up(lg) {
      padding: 20px 40px 40px;
    }

    &__totalBlock {
      display: inline-block;
      border: 1px solid $primary;
      border-radius: 10px;
      padding: 12px 16px;

      @include media-breakpoint-up(lg) {
        padding: 12px 20px;
      }
    }

    &__list {
      @include listReset;

      counter-reset: numbering;
    }

    &__listItem {
      position: relative;
      padding: 16px 0 16px 32px;
      counter-increment: numbering;

      @include media-breakpoint-up(xs) {
        padding: 24px 20px 24px 40px;
      }

      &:first-child {
        padding-top: 0;

        &:before {
          top: -1px;

          @include media-breakpoint-up(xs) {
            top: -4px;
          }
        }
      }

      &:last-child {
        padding-bottom: 0;

        .testDetailBlock__listItemResult {
          bottom: 0;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid $gray-200;
      }

      &:before {
        @include sizeElement(24px);
        @include flexCenter;

        position: absolute;
        top: 16px;
        left: 0;
        content: counter(numbering);
        border-radius: 50%;
        border: 1px solid $primary;
        background-color: $mint;
        color: $secondary;
        font-size: 14px;
        font-weight: 600;

        @include media-breakpoint-up(xs) {
          @include sizeElement(28px);

          top: 21px;
        }
      }
    }
  }
}
