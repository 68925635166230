@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins";

.notifications {
  .backdrop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 760px;
    width: 100%;
    margin: 0 0 32px;
    padding: 40px;
    background: white;
    border: 1px solid $gray-200;
    border-radius: 10px;

    @include layout-flip {
      flex-direction: column;
      padding: 0 0 32px;
      background: none;
      border: none;
    }

    .field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 50px;

      &:last-of-type {
        margin: 0;
      }

      .label {
        max-width: 400px;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.01em;

        @include layout-flip {
          max-width: 255px;
          font-size: 15px;
          line-height: 18px;
        }

        p {
          margin: 0;
          padding: 0;
          color: $secondary;
        }

        small {
          color: $gray-700;
        }
      }
    }
  }
}
