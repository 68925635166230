@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

.emailSuccess {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 50px;
  overflow: hidden;

  @include layout-flip {
    display: flex;
    padding: 40px 15px;
  }

  img {
    width: 88px;
    height: 88px;
    margin-bottom: 30px;

    @include layout-flip {
      margin-bottom: 20px;
    }
  }

  h1 {
    text-align: center;
    margin: 0 0 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 1.2;
    color: #1f342f;

    @include layout-flip {
      margin: 0 0 20px;
      font-size: 28px;
    }
  }

  small {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $gray-700;
    @include layout-flip {
      font-size: 15px;
      line-height: 18px;
    }
  }
}

.popupCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  background: none;
  border: none;
  cursor: pointer;

  @include layout-flip {
    top: 15px;
    right: 15px;
    margin: 0;
    padding: 0;
  }
}
