@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.root {
  .tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .arrow {
    flex-shrink: 0;
    margin: 0 0 0 12px;
    transform: scale(1.4);
  }

  &.active {
    .arrow {
      transform: scale(1.4) rotate(180deg);
    }
  }
}
