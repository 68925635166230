@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.wrapper {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.listing {
  position: relative;
  max-width: 1234px;
  width: 100%;
  margin: 0 auto;
  z-index: 3;

  @include xl {
    padding: 0 24px;
  }
}

.listingHeader {
  font-family: $headings-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 63px;
  color: #1f342f;
  margin: 40px 0 16px;

  @media (max-width: 992px) {
    margin: 16px 0 0;
    font-size: 23px;
    line-height: 34px;
    letter-spacing: 0.25px;
  }
}

.listingResults {
  font-size: 20px;
  line-height: 24px;
  color: #68768b;
  margin-bottom: 40px;

  @media (max-width: 992px) {
    margin: 0 0 16px;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }
}

.listingFilter {
  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    padding: 16px 24px 60px;
    overflow: auto;
    background: #fff;
  }
}

.listingFilterRow {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    flex-wrap: nowrap;
  }
}

.bottom {
  margin: 24px 0 42px;
  overflow: auto;

  @media (max-width: 992px) {
    margin: 16px 0 20px;
  }
}

.listingFilterSelect {
  max-width: 256px;
  width: auto;
  margin-right: 16px;
  margin-bottom: 16px;

  @media (max-width: 992px) {
    max-width: 100%;
    margin: 0;

    label {
      display: none;
    }
  }
}

.listingFilterClear {
  background: none;
  font-size: 17px;
  line-height: 20px;
  text-decoration: underline;
  color: #26d5b6;
  outline: none;
  border: none;
}

.listingFilterTag {
  margin-right: 24px;
  margin-bottom: 16px;
  white-space: nowrap;

  @media (max-width: 992px) {
    padding: 9px 8px;
    margin-right: 8px;

    span {
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: 0.01em;
    }
  }
}

.noAvailable {
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    width: 40px;
    height: 40px;
    margin: 0 16px 0 0;
  }
}
