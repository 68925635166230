.text-block,
.remove-last-child-margin {
  > * {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &.row {
    > * {
      &.col,
      &[class^="col-"] {
        &:last-child {
          > * {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.text-xs {
  font-size: $font-size-xs !important;
}

.text-sm {
  font-size: $font-size-sm !important;
  line-height: 1.6;
}

.text-lg {
  font-size: $font-size-lg !important;
  line-height: 1.6;
}

.text-xl {
  font-size: $font-size-xl !important;
}

.fw-medium {
  font-weight: 500 !important;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 {

}

h2, .h2 {

}

h3, .h3 {

}

h4, .h4 {
  font-weight: $font-weight-medium;
}

h5, .h5 {
  font-weight: $font-weight-medium;
}

h6, .h6 {
  font-weight: $font-weight-medium;
}

.default-typography {
  p {
    margin-top: 0;
    margin-bottom: 15px;
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-top: 0;
    margin-bottom: 15px;
  }

  h1, .h1 {
    @include font-size(50px);
  }

  h2, .h2 {
    @include font-size(40px);
  }

  h3, .h3 {
    @include font-size(32px);

    margin-bottom: 25px;
  }

  h4, .h4 {
    @include font-size(24px);
  }

  h5, .h5 {
    @include font-size(20px);
  }

  h6, .h6 {
    @include font-size(16px);

    line-height: 1.4;
    margin-bottom: 10px;
  }
}

.text-gray {
  color: $gray-700 !important;
}

.text-teal-dark {
  color: $teal-dark !important;
}

.text-red {
  color: $red !important;
}

.text-red-muted {
  color: $red-muted !important;
}

strong {
  font-weight: $font-weight-bold;
}

.title {
  font-family: $headings-font-family;
}

.list-with-line-divider {
  @include listReset;

  font-size: 14px;
  color: $gray-700;

  &__item {
    display: inline-block;
    position: relative;
    padding-right: 20px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
      width: 1px;
      height: 10px;
      background-color: $gray-300;
    }

    &:last-child {
      padding-right: 0;

      &:after {
        content: none;
      }
    }
  }
}