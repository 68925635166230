@import "common/styles/variables";
@import "common/styles/mixins";


.timeCall {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
}

@media only screen and (max-width: 1025px) {
    .timeCall {
        margin-bottom: 0;
    }
}

.timeCallIcon {
    color: red;
}

.timeCallTime {
    color: #294C65;
    font-size: 1.25rem;
    margin-bottom: 4px
}